import React, { useEffect, useCallback, useState } from "react";
import { Header } from '../Header';
import { useHistory } from "react-router-dom";
import { Footer } from '../Footer';
import { Utente } from './Utente';
import { DataTable, dmy_date_formatter, } from '../components/DataTable';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized } from '../UtilsInthem'
import MyAxios, {check_response} from "../MyAxios";
import { Loading, getOptionList } from "../components/MostComponents";

export const Utenti = (props) => {
    const { userInfo } = useGlobalHook('userStore');
    const { t } = useTranslation();
    const history = useHistory()
    const [rows, setRows] = useState([])
    const [row, setRow] = useState(null)
    const [options_aree, setOptions_aree] = useState([])
    const [options_centri, setOptions_centri] = useState([])
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    // props.mode==="utente"
    const userId = props.match.params.userId
    //console.log("Utenti userId",userId)

    function showRoles(params: ValueGetterParams) {
      const v = []
      // getValue() solo se la colonna fa parte delle colonne definite, altrimenti bisogna usare params.row
      const ur = params.row.user_roles
      for (let i in ur) {
        if(v.indexOf(ur[i].role) === -1)
            v.push(ur[i].role)
      }
      return v
    }
    const columns = [
      { field: 'bottoni', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
          const onClickSettings = () => {
            const id = params.id
            history.push("/utente/"+id)
          }
          return (
            <div>
            <Tooltip title={t("Gestione utente")}><IconButton className="gold" onClick={onClickSettings}><SettingsIcon /></IconButton></Tooltip>
            </div>
          )
        }
      },
      // { field: 'id', headerName: 'ID', width: 300 },
      { field: 'surname', headerName: 'Cognome', width: 180 },
      { field: 'name', headerName: 'Nome', width: 180 },
      { field: 'email', headerName: 'E-mail', width: 180 },
      { field: 'roles', headerName: 'Ruoli', width: 300, valueGetter: showRoles },
      { field: 'insert_time', type: 'dateTime', headerName: 'Inserito il', width: 130, valueFormatter: dmy_date_formatter },
    ]

    useEffect(() => {
        const formData = new FormData()
        formData.append('role', 'UserAdmin')
        console.log("/admin/get_user_role", [...formData.entries()])
        MyAxios.post("/admin/get_user_role", formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
          const data = check_response(response);
          if(!data.success) {
            console.error(data.error)
            appAlert(data.error)
            return
          }
          setRows(data.user_role.users)
          if(props.mode==="utente") {
            const oc = getOptionList(data.user_role.centers,'id','id')
            setOptions_centri(oc)
            const oa = getOptionList(data.user_role.areas,'id','id')
            setOptions_aree(oa)
          }
          if(userId) {
            for (let i in data.user_role.users) {
                if(data.user_role.users[i].id === userId) {
                    setRow(data.user_role.users[i])
                    break
                }
            }
          }
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,userId,props.mode])

    const nuovo = (el) => {
        history.push("/utente/__new__")
    }

    const redirect = authorized("UserAdmin",userInfo)
    if(redirect)
        return redirect

    if(props.mode==="utente") {
        if((userId !== "__new__" && !row) || !options_centri.length || !options_aree.length)
            return <Loading />
        return (
            <Utente row={row} userId={userId} options_centri={options_centri} options_aree={options_aree} />
        )
    }

    return (
  <div className="app-container">
    <Header title={t("Gestione utenti")} />
    <div className="content-container">
    <DataTable columns={columns} rows={rows} height={600} density={"compact"} toolbar={true} tbButton={t("Nuovo utente")} tbButtonOnClick={nuovo} disableSelectionOnClick={true} />
    </div>
    <Footer />
  </div>
    );
};
