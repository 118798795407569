import React from "react";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useTranslation } from 'react-i18next';
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useHistory } from "react-router-dom";
import { MostButton, } from "../components/MostComponents";
import { hasRole, authorized } from '../UtilsInthem'
import Grid from "@material-ui/core/Grid";

export const UHome = () => {
    const { userInfo } = useGlobalHook('userStore');
    const { t } = useTranslation();
    const history = useHistory();
    const globalAdminRole = hasRole("GlobalAdmin",userInfo)
    const userAdminRole = hasRole("UserAdmin",userInfo)
    const dataManagerRole = hasRole("DataManager",userInfo)
    const centerAdminRole = hasRole("CenterAdmin",userInfo)

    const redirect = authorized("user",userInfo)
    if(redirect)
        return redirect

    return (
  <div className="app-container">
    <Header />
    <div className="content-container">
    <Container component="main" maxWidth="md">
        <h1>{t("Benvenuto")} {userInfo.name} {userInfo.surname}</h1>
        <Grid container spacing={1} alignItems="flex-start" className="blackColor">
        { globalAdminRole ?
            <React.Fragment>
            <Grid item xs={6}>
                {t("Global Administrator")}
            </Grid>
            <Grid item xs={6}>
                    <MostButton className="b_loginok" onClick={() => history.push("/ga_studiclinici")} label={t("Gestione studi clinici")} id="ga_studiclinici" />
                    <MostButton className="b_loginok" onClick={() => history.push("/ga_filtri")} label={t("Gestione filtri")} id="filtri1" />
                    <MostButton className="b_loginok" onClick={() => history.push("/comunicazioni")} label={t("Comunicazioni")} id="comunicazioni" />
                    <MostButton className="b_loginok" onClick={() => history.push("/globalconfig")} label={t("Configurazione globale")} id="globalconfig" />
                    <MostButton className="b_loginok" onClick={() => history.push("/log")} label={t("Log")} id="log" />

            </Grid>
            </React.Fragment>
        : null
        }
        { userAdminRole ?
            <React.Fragment>
            <Grid item xs={6}>
                {t("User Administrator")}
                {userAdminRole.areaid ?
                    <div>Area {userAdminRole.areaid}</div>
                : null}
                {userAdminRole.center ?
                    <div>Centro {userAdminRole.center}</div>
                : null}
            </Grid>
            <Grid item xs={6}>
                    <MostButton className="b_loginok" onClick={() => history.push("/centri")} label={t("Gestione centri")} id="centri" />
                    <MostButton className="b_loginok" onClick={() => history.push("/utenti")} label={t("Gestione utenti")} id="utenti" />
            </Grid>
            </React.Fragment>
        : null
        }
        {dataManagerRole ?
            <React.Fragment>
            <Grid item xs={6}>
                {t("Data Manager")}
                {dataManagerRole.areaid ? 
                        <div>Area {dataManagerRole.areaid}</div>
                : null}
                {dataManagerRole.center ? 
                        <div>Area {dataManagerRole.center}</div>
                : null}
            </Grid>
            <Grid item xs={6}>
{/*
vedi 2.3.12 capitolato
*/}
                    <MostButton className="b_loginok" onClick={() => history.push("/dm_filtri")} label={t("Gestione filtri")} id="filtri2" />
                    <MostButton className="b_loginok" onClick={() => history.push("/dm_studiclinici")} label={t("Studi Clinici")} id="dm_studiclinici" />
                    <MostButton disabled className="b_loginok" label={t("Scambi documenti e note")} />

                    <MostButton disabled className="b_loginok" label={t("Analisi Estrazioni Report")} />
            </Grid>
            </React.Fragment>
        : null
        }
        { centerAdminRole ?
            <React.Fragment>
            <Grid item xs={6}>
                {t("Center Administrator")} <br />
                Centro {centerAdminRole.center}<br />
                Area {centerAdminRole.areaid}
            </Grid>
            <Grid item xs={6}>
                    <MostButton className="b_loginok" onClick={() => history.push("/ca_filtri")} label={t("Gestione filtri")} id="filtri3" />
                    <MostButton className="b_loginok" onClick={() => history.push("/ca_studiclinici")} label={t("Aggiornamento Studi Clinici")} id="ca_studiclinici" />
{/*
vedi 2.3.8 capitolato
*/}
                    <MostButton disabled className="b_loginok" label={t("Analisi Estrazioni Report")} />

{/*
Due web service REST, riservati a e-CRF e CenterAdmin devono consentire di scaricare la tabella dei Tipi di DiagnosiSpecifica e dei Tipi di Chelanti.
Una pagina di cortesia per i CenterAdmin, deve agevolare la chiamata manuale dei web service
/getDiagnosis
/getICTproducts
*/}
                    <MostButton disabled className="b_loginok" label={t("Download Diagnosi e Tipi chelanti")} />

{/*
La funzionalità è indirizzata soprattutto ai piccoli centri, con pochi pazienti e non dotati di sistemi e-CRF evoluti o comunque dotati di e-CRF non interfacciati a DB-INTHEM.
Oltre alla possibilità di sincronizzazione tramite web service e trasferimento file descritta nel Cap. 2.3.6, una apposita interfaccia grafica, riservata ai CenterAdmin, deve consentire di:    
• Creare un nuovo paziente, inserendo come minimo: nome, cognome, identificativo univoco all'interno del centro, ed email o numero di cellulare.
• Per i pazienti che (dopo aver ricevuto link di invito personalizzato) abbiano rilasciato i consensi al trattamento dei dati personali e/o medici, compilazione, aggiornamento e visualizzazione dei rispettivi dati previsti nel Dataset di Appendice B. I campi marcati "E" nella colonna Dataset dovranno essere editabili, quelli marcati "R/O" dovranno essere visualizzati in modalità Read/Only. La pagina, visto l'elevato numero di campi dovrà essere organizzata in sezioni logiche gerarchiche.
*/}
                    <MostButton className="b_loginok" label={t("Mini e-CRF")} 
onClick={() => history.push("/ecrf")} id="ecrf" />

                    <MostButton className="b_loginok" label={t("Sincronizzazione pazienti e dati")} onClick={() => history.push("/pazienti-dati")} id="pazienti-dati" />

                    <MostButton disabled className="b_loginok" label={t("Cambio password e-CRF web services")} />
            </Grid>
            </React.Fragment>
        : null
        }
        </Grid>
    </Container>
    </div>
    <Footer />
  </div>
    );
};
