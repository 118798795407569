import React, { useEffect, useCallback, useState } from "react";
import { Header } from '../Header';
import Container from "@material-ui/core/Container";
import { Footer } from '../Footer';
import { Studio } from './Studio';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipLongText, DataTable, dmy_date_formatter } from '../components/DataTable';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { getCenterAdmin, authorized, isCenterAdmin, roleString, } from '../UtilsInthem'
import { ymd } from '../Utils'
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import MyAxios, {check_response} from "../MyAxios";
import { Loading, } from "../components/MostComponents";
import { useHistory } from "react-router-dom";

export const Studi = (props) => {
    const { userInfo } = useGlobalHook('userStore');
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [rows, setRows] = useState([])
    const [row, setRow] = useState(null)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    // props.mode==="ga_studio"
    // props.mode==="dm_studio"
    // props.mode==="ca_studio"
    const studioId = props.match.params.studioId

    const goToDetail = (id) => {
        if(props.mode==="ga_studiclinici")
            history.push("/ga_studio/"+id)
        else if(props.mode==="dm_studiclinici")
            history.push("/dm_studio/"+id)
        else
            history.push("/ca_studio/"+id)
    }

    const columns = [

      { field: 'bottoni', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
          const onClickSettings = () => {
            goToDetail(params.id)
          }
          // params.row : record completo
          // params.value : campo corrente
          let icon
          let title
          if (params.row.readonly) {
            icon = <SearchIcon />
            title = t("Dettaglio studio")
          } else {
            icon = <SettingsIcon />
            title = t("Gestione studio")
          }
          return (
            <Tooltip title={title}><IconButton className="gold" onClick={onClickSettings}>{icon}</IconButton></Tooltip>
          )
        }
      },
      { field: 'ops', headerName: 'Operazione richiesta', width: 220, renderCell: (params: GridCellParams) => {
        const onClickSettings = () => {
            goToDetail(params.id)
        }
        return <span className="important pointer" onClick={onClickSettings}>{params.value ? t(params.value) : ""}</span>
      }},
      { field: 'id', headerName: 'Codice', width: 120 },
      { field: 'title', headerName: 'Titolo', width: 180, renderCell: (params: GridCellParams) => (
            <TooltipLongText text={params.value} />
      )},
      //{ field: 'description', headerName: 'Descrizione', width: 250 },
      { field: 'main_center', headerName: 'Centro', width: 120 },
      { field: 'status_detail', headerName: 'Stato', width: 200, valueFormatter: (params: ValueFormatterParams) => params.value.status_trans },
      { field: 'end_enrollment_date', headerName: 'Fine Arr.', width: 130, valueFormatter: dmy_date_formatter },
      { field: 'clinical_study_type_detail', headerName: 'Tipo', width: 150, valueFormatter: (params: ValueFormatterParams) => params.value.name },
      { field: 'enrolled_centers', headerName: 'Centri aderenti', width: 200, valueFormatter: (params: ValueFormatterParams) => {
        let ret = ""
        const f = params.value
        for (let i in f)
            if (f[i].ethics_committee_approval)
                ret += f[i].center_id + " "
        return ret
      }},
      { field: 'inthem_query_code_detail', flex: 1, headerName: 'Query', minWidth: 250, renderCell: (params: GridCellParams) => (
            <TooltipLongText text={params.value.name+" - "+params.value.description} />
      )},
      //{ field: 'sf36_code', headerName: 'SF36', width: 100 },
      //{ field: 'video_url', headerName: 'Video', width: 100 },
    ]

    useEffect(() => {
        if(studioId === "__new__")
            return
        // aggiungere informazioni 
        //      operazione da compiere
        // e riordinare studi
        //      prima quelli dove ha da fare qualcosa (prima comitat.etico)
        // per center admin 
        // - se studio suo e EthicsCommitteeWaiting -> inserimento comitato
        // - se studio non suo e (AlreadySentToCenters,ToBeSentToPatients,Active) e non ha ancora messo sua approvazione comitato e before data di fine arruolamento -> inserimento comitato
        // - se ha approvazione comitato e NOT (Terminated, Exported) -> api per pazienti ?? 
        // per data manager
        // - se studio attivo -> export
        // tutti gli altri in read only

          function centerRow(l,center) {
            for (let i in l) {
                if(l[i].center_id === center)
                    return l[i]
            }
            return null
          }

          function todayBefore(d) {
            if(!d)
                return true
            return ymd(new Date()) <= d
          }

          function addInfoAndReorder(l) {
            // ordinamento: inserire nella lista in base a importanza operazione
            const r1 = [] // inserimento dati bloccanti
            const r2 = [] // operativita'
            const r3 = [] // read only
            const center = getCenterAdmin(userInfo)
            for (let i in l) {
                if(props.mode.startsWith("ga")) {
                    // GA
                    if (l[i].status !== "EthicsCommitteeWaiting" && l[i].end_enrollment_date === null) {
                        l[i].ops = "Inserimento_data_fine_arruolamento"
                        r1.push(l[i])
                    } else
                        r2.push(l[i])
                } else if(props.mode.startsWith("ca")) {
                    // CA
                    let opInserted = false
                    let centerEnrolled = false
                    if (isCenterAdmin(l[i].main_center,userInfo)) {
                        // studio del centro
                        centerEnrolled = true
                        if (l[i].status === "EthicsCommitteeWaiting") {
                            l[i].ops = "Inserimento_valutazione_comitato_etico"
                            r1.push(l[i])
                            opInserted = true
                        }
                    } else {
                        const cr = centerRow(l[i].enrolled_centers,center)
                        if (cr === null || cr.ethics_committee_approval === null) {
                            if (["AlreadySentToCenters","ToBeSentToPatients","Active"].includes(l[i].status) && todayBefore(l[i].end_enrollment_date)) {
                                l[i].ops = "Inserimento_valutazione_comitato_etico"
                                r1.push(l[i])
                                opInserted = true
                            }
                        } else {
                            if (cr.ethics_committee_approval) {
                                centerEnrolled = true
                            }
                        }
                    }
                    if(!opInserted && centerEnrolled) {
                        if (!["Terminated","Exported"].includes(l[i].status)) {
                            l[i].ops = "Controllo_pazienti"
                            r2.push(l[i])
                            opInserted = true
                        }
                    }
                    if(!opInserted) {
                        l[i].readonly = 1
                        r3.push(l[i])
                    }
                } else if(props.mode.startsWith("dm")) {
                    // DM
                    if (l[i].status === "Active") {
                        l[i].ops = "Esportazione"
                        r1.push(l[i])
                    } else {
                        l[i].readonly = 1
                        r2.push(l[i])
                    }
                }
            }
            return r1.concat(r2).concat(r3)
          }

        const jdata = {
            action: 'LIST'
        }
        MyAxios.post("/clinical_study", jdata)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            console.error(data.error)
            appAlert(data.error)
            return
          }
          setRows(addInfoAndReorder(data.rows))
          if(studioId) {
            for (let i in data.rows) {
                if(data.rows[i].id === studioId) {
                    setRow(data.rows[i])
                    break
                }
            }
          }
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,studioId,props.mode,i18n.language,userInfo,t])

    const nuovo = (el) => {
        history.push("/ga_studio/__new__")
    }

    const redirect = authorized(
        props.mode.startsWith("ga") ?  "GlobalAdmin" : ( props.mode.startsWith("dm") ? "DataManager" : "CenterAdmin" ),
        userInfo)
    if(redirect)
        return redirect

    if(props.mode.endsWith("studio")) {
        if(studioId !== "__new__" && !row)
            return <Loading />
        return (
            <Studio mode={props.mode} row={row} studioId={studioId} />
        )
    }
    let title
    if(props.mode==="ga_studiclinici" || props.mode==="dm_studiclinici")
        title = t("Studi clinici")
    else
        title = t("Aggiornamento studi clinici")
    const subtitle = roleString(props.mode,t)

    return (
  <div className="app-container">
    <Header title={title} subtitle={subtitle}/>
    <div className="content-container">
        <DataTable columns={columns} rows={rows} height={600} density={"compact"} toolbar={props.mode==="ga_studiclinici"?true:false} tbButton={t("Nuovo studio")} tbButtonOnClick={nuovo} disableSelectionOnClick={true} />
        <hr />
    <Container component="main" maxWidth="md">
        <div className="blackColor asinistra">
{props.mode === "ga_studiclinici" ?
<div>
vedi 2.3.9 capitolato
<br />
Il GlobalAdmin può infine creare degli oggetti chiamati "Studi Clinici" riconoscibili attraverso un codice univoco, un titolo e una descrizione associati ad un “Tipo Studio Clinico” a scelta da un elenco configurabile. Il Tipo Studio Clinico classifica lo studio in base al fatto che preveda o meno un trasferimento dati verso enti esterni e la relativa natura (Enti UE, Enti Extra UE, Case Farmaceutiche, DataBase Pubblici) oppure preveda fasi cliniche di ricerca da parte dei Centri.
<br />
Sfruttando un Filtro-pazienti costruito ad hoc o recuperato per nome (v. Cap. 2.3.7) il Global Admin può associare lo studio clinico ad un set (non visualizzabile e anonimo) di Pazienti che rispettano il Filtro e che (in caso di previsto trasferimento a enti esterni) abbiano espresso consensi compatibili. <big><b>Il set non cambierà successivamente modificando il filtro o aggiornando i dati dei Pazienti</b></big>.
<br />
Il Paziente, per un determinato Studio Clinico, sarà associato ad uno fra n possibili Stati. Ad es. negli Studi Clinici che si concretizzano in una semplice esportazione dati verso enti esterni i Pazienti attraverseranno gli stati: [AttesaConsenso, ConsensoNO, ConsensoSI, Esportato]. Gli Studi Clinici che prevedono fasi cliniche e che coinvolgono i centri avranno degli Stati aggiuntivi che considerano l'attività dei Centri.
<br />
Gli aggiornamenti di stato dei Pazienti per gli StudiClinici di questo ultimo tipo saranno aggiornabili anche dai CenterAdmin (ciascuno per i pazienti di propria competenza) attraverso specifici web service chiamati da form interattivo (o direttamente dagli e-CRF se il software fosse abbastanza evoluto da gestire anche questi aspetti).
<br />
I GlobalAdmin, possono esportare i dati dei pazienti di un dato StudioClinico precisandone il codice ed eventualmente lo stato dei pazienti di interesse e selezionando i campi da esportare con le stesse modalità illustrate nel Cap. 2.3.7.
</div>
:
<div>
vedi 2.3.9 capitolato
<br />
( Il Paziente, per un determinato Studio Clinico, sarà associato ad uno fra n possibili Stati. Ad es. negli Studi Clinici che si concretizzano in una semplice esportazione dati verso enti esterni i Pazienti attraverseranno gli stati: [AttesaConsenso, ConsensoNO, ConsensoSI, Esportato]. Gli Studi Clinici che prevedono fasi cliniche e che coinvolgono i centri avranno degli Stati aggiuntivi che considerano l'attività dei Centri. )
<br />
Gli aggiornamenti di stato dei Pazienti per gli StudiClinici di questo ultimo tipo saranno aggiornabili anche dai CenterAdmin (ciascuno per i pazienti di propria competenza) attraverso specifici web service chiamati da form interattivo (o direttamente dagli e-CRF se il software fosse abbastanza evoluto da gestire anche questi aspetti).
<br />
WebService
parametri chiamata
valore di ritorno
<br />
/getStudies
centerCode
centerPwd
format
File Studies (S) (1)
<br />
/getStudyPatients
centerCode
centerPwd
StudyCode
format
File StudyPatients (SP) (2)
<br />
/putStudyPatients
centerCode
centerPwd
format
File StudyPatients(SP) (2)
<br />
    (1) Studies (elenco Studi Clinici con almeno un paziente del centro coinvolto) con i campi marcati → e nella colonna S dell'Appendice B.
</div>
}
        </div>
    </Container>
    </div>
    <Footer />
  </div>
    );
};
