import React from "react";
import { Header } from '../Header';
import Container from "@material-ui/core/Container";
import { Footer } from '../Footer';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized } from '../UtilsInthem'

export const Comunicazioni = () => {
    const { userInfo } = useGlobalHook('userStore');
    const { t } = useTranslation();

    const redirect = authorized("GlobalAdmin",userInfo)
    if(redirect)
        return redirect

    return (
  <div className="app-container">
    <Header title={t("Comunicazioni")} />
    <div className="content-container margintop30">
    <Container component="main" maxWidth="md">
        <div className="blackColor asinistra">
Il GlobalAdmin può caricare periodicamente nella applicazione una NewsLetter generale sotto forma di file PDF o di link a un video messaggio ospitato su piattaforma esterna (ad es. YouTube, Vimeo o altre), accompagnata da un breve messaggio testuale (con testo di default, eventualmente modificabile). L'inserimento fa scattare in automatico, in orario opportuno, un batch di invio email e SMS (a seconda delle rispettive preferenze a tutti i Pazienti che hanno espresso consenso a ricevere questo tipo di messaggi) con il link di accesso e fruizione.
<br />
xxxxxxxxxxxxx
Nel caso in cui una variazione nelle leggi o nei regolamenti sulla privacy oppure una estensione dei dati trattati da DB-INTHEM richiedano un ulteriore consenso, il GlobalAdmin deve poter aggiungere una voce alla tabella dei tipi consensi da richiedere e creare una nuova comunicazione (eventualmente accompagnata da un video messaggio di spiegazione ed invito) che, tramite l'applicazione verrà invierà a tutti i Pazienti sotto forma di SMS/email con link diretto per fornire il nuovo consenso desiderato (i consensi già forniti rimangono comunque operativi e intoccati).
<br />
Per ogni Studio Clinico il GlobalAdmin può creare una o più Comunicazioni, ciascuna composta da un breve messaggio testuale eventualmente accompagnato da un videomessaggio (su piattaforma esterna). Il messaggio è inviato a quei pazienti elegibili per lo studio clinico che non avevano dato il loro consenso al trattamento dei dati, il messaggio sarà informativo sul tipo di studio e chiederà al paziente di cambiare il suo consenso al trattamento dei dati in caso questo accetti a partecipare. Il messaggio deve contenere un riferimento al centro presso cui gli è stato proposto di essere inserito su DB-INTHEM.
        </div>
    </Container>
    </div>
    <Footer />
  </div>
    );
};
