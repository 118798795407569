import React, { useState, useCallback } from "react";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { useTranslation } from 'react-i18next';
import { DataTable } from '../components/DataTable';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { options_formatofile, options_formatofile_download, } from '../UtilsInthem'
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { MostButton2, MostSubmitButton, MostButton, MostAutocomplete, } from "../components/MostComponents";
import MyAxios, { check_and_download, check_response, } from "../MyAxios";
import { addIdToRows, prettyJson, } from '../Utils'
import { getLang, } from '../i18n'
import { useForm } from "react-hook-form";
import { FileUpload } from "../components/FileUpload";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";

    const spinnerCss = css`
        display: block;
        margin: 0 auto;
    `;

export const StudioGestionePazienti = (props) => {
    console.log("StudioGestionePazienti",props)
    const { control, handleSubmit, errors, } = useForm();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const handleFormatACChange = (e,el) => {
        props.setFormatAC(el)
    }
    const [uploadInfo, setUploadInfo] = useState(null);
    const [uploadTarget, setUploadTarget] = useState(null);
    const [disabledButs, setDisabledButs] = useState(false)
    const [result, setResult] = useState()
    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useState("recupera")
    const [rows, setRows] = useState([])
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    const columns = [
        // field: 'center_code', 
        { field: 'center_id', headerName: 'Center_id', width: 200 },
        // field: 'study_code', 
        { field: 'name', headerName: 'Nome', width: 200 },
        { field: 'surname', headerName: 'Cognome', width: 200 },
        { field: 'approved', headerName: 'Inserito', width: 200 },
        { field: 'study_patient_status', headerName: 'Stato', width: 200 },
    ]

    const onSubmitUpload = (vals) => {
        if (!uploadInfo) {
            appAlert("File non scelto")
            return
        }
        console.log("uploadInfo",uploadInfo,"vals",vals,"mode",mode)
        setDisabledButs(true)
        setLoading(true)
        const formData = new FormData()
            formData.append('FileStudyPatients', uploadInfo[0])
            formData.append('format', vals.format.value)
            formData.append('centerCode', props.centro)
        const api = "/putStudyPatients"
        console.log(api, [...formData.entries()])
        MyAxios.post(api, formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
            setDisabledButs(false)
            setLoading(false)
            const res = check_response(response)
            if (res.success) {
                console.log(res)
                appAlert("Caricamento terminato<br><br>Record importati: "+res.report.ok+"<br>Record non importati: "+res.report.ko);
                setResult("Record importati: "+res.report.ok+"<br>Record non importati: "+res.report.ko+"<br /><br />Dettaglio importazione:<br /><pre>"+prettyJson(res.report.detail)+"</pre>")
            } else {
                console.error("Error: "+res.error);
                appAlert(res.error);
            }
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
            setLoading(false)
        })
        .then(function () {
            // se cambia file uploadato, fallisce nuovo upload
            // con errore non significativo (Network Error)
            // mentre l'errore vero ERR_UPLOAD_FILE_CHANGED
            // viene restituito dopo in modo non catchabile
            // Resettiamo file dopo ogni upload
            if(uploadTarget)
              uploadTarget.value = null
            setUploadInfo(null)
        })
    }

    const recuperaPazienti = () => {
        if(props.formatAC === null) {
            appAlert("Formato, campo obbligatorio")
            return
        }
        props.setShowdatagrid(false)
        setDisabledButs(true)
        let format = props.formatAC.value
        if (format === "video")
            format = 'JSON'
        const formData = new FormData()
        formData.append('centerCode', props.centro)
        formData.append('format', format)
        formData.append('StudyCode', props.studioId)
        // xxx
        formData.append('lang', getLang(i18n.language))
        const url = "/getStudyPatients"
        console.log(url, [...formData.entries()])
        MyAxios.post(url, formData, {
            responseType: format !== 'JSON' ? 'arraybuffer' : '',
        }).then((response) => {
            setDisabledButs(false)
            const fileName = 'studio_'+props.studioId+'_pazienti.'+format.toLowerCase()
            const res = check_and_download(response,format,props.formatAC.value,fileName,1)
            if(res.success === false) {
                console.error("Error: "+res.error)
                appAlert(res.error)
                return
            }
            if (props.formatAC.value === "video") {
                // reset
                setRows([])
                props.setShowdatagrid(true)
                setRows(addIdToRows(res.rows))
            }
        })
        .catch(function (error) {
            setDisabledButs(false)
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }

    return (
      <div>
        <Toolbar className="rowreverse">
            { mode==="recupera" ?
                <Button variant="contained" color="primary" onClick={() => setMode("aggiorna")} className="marginr10" > Aggiorna stato pazienti </Button>
            :
                <Button variant="contained" color="primary" onClick={() => setMode("recupera")} > Recupera pazienti </Button>
            }
        </Toolbar>
        { mode==="recupera" ?
            <div>
              <Container component="main" maxWidth="sm">
                <h2> Recupera pazienti inseriti nello studio </h2>
                <div className="blackColor">
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                            <MostAutocomplete name="formatAC" options={options_formatofile_download} label={t("Formato file")+" *"} defaultValue={null} value={props.formatAC} onChange={handleFormatACChange} />
                        </Grid>
                        <Grid item xs={8}>
                            <MostButton onClick={recuperaPazienti} disabled={disabledButs}  label={t("Recupera")} />
                        </Grid>
                        <Grid item xs={4}>
                            <MostButton2 fullWidth className="margin2416" onClick={() => history.push(props.backUrl)} disabled={disabledButs}  label={t("Indietro")} />
                        </Grid>
                    </Grid>
                </div>
              </Container>
                { props.showdatagrid ?
                    <DataTable columns={columns} rows={rows} height={600} density={"compact"} />
                : null }
            </div>
        :
            <div>
              <Container component="main" maxWidth="sm">
                <h2> Aggiorna stato pazienti </h2>
                <form onSubmit={handleSubmit(onSubmitUpload)} noValidate>
                <Grid container spacing={1} alignItems="center" className="blackColor">
                  { mode !== "getConsents" ?
                    <Grid item xs={12}>
                        File da caricare: 
                        <div className="fileupload">
                            <FileUpload
                                setUploadInfo={setUploadInfo}
                                setUploadTarget={setUploadTarget}
                            />
                        </div>
                    </Grid>
                  : null }
                    <Grid item xs={12}>
                        <MostAutocomplete control={control} name="format" options={mode === "getConsents" ? options_formatofile_download : options_formatofile} label={t("Formato file")+" *"} required={true} rules={{ required: true }}  defaultValue={null} errors={errors} />
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <Grid item xs={8}>
                        <MostSubmitButton id="submit-button" disabled={disabledButs} className="b_loginok" label={t("Carica")} />
                    </Grid>
                    <Grid item xs={4}>
                        <MostButton2 fullWidth disabled={disabledButs} className="b_loginok" onClick={() => history.push(props.backUrl)} label={t("Indietro")} />
                    </Grid>
                    <Grid item xs={8} className="tcenter">
                    { loading ? (
                        <b>{t("Operazione in corso")}</b>
                    ) : null }
                    <PropagateLoader color={window.__most.palette.primary.darkColor} css={spinnerCss} loading={loading} />
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                </Grid>
                </form>
              </Container>
              <div className="blackColor margin20all">
                <div dangerouslySetInnerHTML={{__html: result}} />
              </div>
            </div>
        }
      </div>
    )
}
