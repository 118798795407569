import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import { Footer } from './Footer';
import logo from './foranemia1.jpg';

export function Home() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => { 
    i18n.changeLanguage(lng); 
  };

  return (
    <div id="HomeDiv">
      <div className="Home-header">
          <div id="languageDiv">
            <button onClick={() => changeLanguage('en')}><img src="https://flagcdn.com/h24/gb.png" height="24" width="48" alt="English" title="English" /></button>
            <button onClick={() => changeLanguage('it')}><img src="https://flagcdn.com/h24/it.png" height="24" width="48" alt="Italiano" title="Italiano" /></button>
          </div>
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={5} md={6} lg={8}>
            <div id="title">
              <h1>DB-INTHEM</h1>
              <span className="red">D</span>ata<span className="red">B</span>ase for <span className="red">I</span>talian <span className="red">N</span>etwork of <span className="red">T</span>halassemia and <span className="red">HEM</span>oglobinopathies
              <p>
              <br />
              <Link to="/login">{t('Accesso Utenti')}</Link>
              <br />
              <br />
              <Link to="/loginp">{t('Accesso Pazienti')}</Link>
              <br />
              <br />
              </p>
            </div>
            </Grid>
            <Grid item xs={12} sm={7} md={6} lg={4} className="tcenter">
                <img src={logo} className="xxxxApp-logo" alt="logo foranemia" />
            </Grid>
        </Grid>
      </div>
        <Footer />
    </div>
  );
}
