
    // input: date
    // output: stringa yyyy-mm-dd
export const ymd = (d) => {
    if(!d)
        return d;
    const ret = 
     d.getFullYear() + "-" 
     + ("0" + (d.getMonth()+1)).slice(-2) + "-"
     + ("0" + d.getDate()).slice(-2)
     return ret
}

    // input: date
    // output: stringa
        // se mode == 2: dd/mm/yyyy
        // se mode == 1: dd/mm/yyyy HH:MM:SS
        // se mode == 0: HH:MM:SS
export const dmy_hms = (d,mode) => {
    var ret = ""
    if (mode)
        ret = ("0" + d.getDate()).slice(-2) + "/"
         + ("0" + (d.getMonth()+1)).slice(-2) + "/"
         + d.getFullYear() + " "
    if (mode === 2)
        return ret
    return ret + ("0" + d.getHours()).slice(-2) + ":"
         + ("0" + d.getMinutes()).slice(-2) + ":"
         + ("0" + d.getSeconds()).slice(-2)
}

    // input: stringa data in formato ISO
    // output: date (se stringa nulla, data nulla)
    // (inizializzazione datePicker)
export const string2date = (s) => {
    if(!s)
        return null
    return new Date(s)
}

    // input: stringa data in formato ISO
    // output: stringa
        // se mode == 2: dd/mm/yyyy
        // se mode == 1: dd/mm/yyyy HH:MM:SS
        // se mode == 0: HH:MM:SS
export const string_dmy_hms = (s,mode) => {
    if(!s)
        return ""
    return dmy_hms(new Date(s),mode)
}

    // input: stringa  yyyy-mm-dd
    // output: stringa  dd/mm/yyyy 
export const dmy = (s) => {
    if(!s)
        return ""
    return s.substring(8)+"/"+s.substring(5,7)+"/"+s.substring(0,4)
}

    // output: stringa ora attuale
        // se mode == 4: yyyymmddHHMMSSmilli
        // se mode == 3: yyyymmddHHMM
        // se mode == 2: dd/mm/yyyy
        // se mode == 1: dd/mm/yyyy HH:MM:SS
        // se mode == 0: HH:MM:SS
export const now = (mode) => {
    if (mode === 3) {
        const d = new Date()
        return d.getFullYear() +
            ("0" + (d.getMonth()+1)).slice(-2) +
            ("0" + d.getDate()).slice(-2) +
            ("0" + d.getHours()).slice(-2) + 
            ("0" + d.getMinutes()).slice(-2)
    }
    if (mode === 4) {
        const d = new Date()
        return d.getFullYear() +
            ("0" + (d.getMonth()+1)).slice(-2) +
            ("0" + d.getDate()).slice(-2) +
            ("0" + d.getHours()).slice(-2) + 
            ("0" + d.getMinutes()).slice(-2) +
            ("0" + d.getSeconds()).slice(-2) +
            ("00" + d.getMilliseconds()).slice(-3)
    }
    return dmy_hms(new Date(),mode)
}

export const downloadArrayBuffer = (ab,fileName,fileType) => {
    var blob = new Blob([ab], {type: fileType})
    var link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = (fileName && fileName !== "") ? fileName : "download"
    link.click()
}

// Material-UI: The data grid component requires all rows to have a unique id property.
export const addIdToRows = (rows) => {
    return dataGridMakeIdUnique(rows,1)
}
// aggiunge o rimpiazza campo ID (deve essere univoco)
export const dataGridMakeIdUnique = (rows,missingId) => {
    const ret=[]
    for (let i in rows) {
        const r = rows[i]
        if(missingId)
            r.id = i
        else {
            r['REAL_ID'] = r.id
            r.id = i
        }
        ret.push(r)
    }
    return ret
}

export const prettyJson = (obj) => {
    let pretty = JSON.stringify(obj,null,2)
    //pretty = pretty.replace(/[\n]/g, '<br>')
    return pretty
}

// nota: esistono cookie che non vengono visti da js e che quindi non possono essere cancellati da js (es. cookie di sessione)
export const delete_cookie = ( name, path, domain ) => {
    //domain = location.host
    document.cookie = name + "=" +
      ((path) ? ";path="+path:"")+
      ((domain)?";domain="+domain:"") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
}
export const set_cookie = ( name, value ) => {
    document.cookie = name+"="+value+"; path=/"
}
export const get_cookie = ( cname ) => {
    const name = cname + "="
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ""
}

export const filesizeFmt = (b) => {
    function rou(n) {
        n = Math.round(n * 10)
        return n / 10.0
    }
    if(!b)
        return ""
    let k = b / 1024.0
    if (k < 1)
        return b+" B"
    let m = k / 1024.0
    if (m < 1)
        return rou(k) +" KB"
    return rou(m)+" MB"
}

export const round = (x,n) => {
    const y = Math.pow(10,n)
    return Math.round(y * x) / y
}

export const scrollToElement = (id) => {
    const el = document.getElementById(id)
    if (el)
        el.scrollIntoView()
}

export function emptyCache(){
    if('caches' in window){
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
    }
}

export const notNullString = (s) => {
    if(s)
        return s
    return ""
}
