import React, { useState, useEffect, }  from 'react';
import { useTranslation } from "react-i18next";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';
import { useLocation } from "react-router-dom";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { isPatient } from '../UtilsInthem'
import { getLang } from "../i18n";
import { app_server_url } from "../MyAxios";

// Messo fuori dal componente principale, si evita il rerender (e quindi il defocus) a ogni keystroke: https://stackoverflow.com/a/56760741

export default function HelpDialog(props) {
  const { userInfo } = useGlobalHook('userStore')
  const [open, setOpen] = React.useState(false);
  const [size, setSize] = useState(null)
  const { t, i18n } = useTranslation();
  let location = useLocation().pathname.substr(1);
  let slash = location.indexOf("/");
  if(slash !== -1)
    location = location.substr(0,slash);

  useEffect(() => {
        const setIframeHeight1 = () => {
            try {
              // dipende da dimensione dialog: con sm 552 x 415
              let ih = 415 - 44
              let iw = 552 
              // per firefox
              ih -= 2;
              iw -= 2;
              // menu react
              ih -= 64;
              setSize([iw,ih])
              let iframe = document.getElementById("helpIframe");
              if (iframe) {
                  iframe.style.height = '' + ih + 'px';
                  iframe.style.width = '' + iw + 'px';
              }
            } catch (e) {
              console.error('setIframeHeight1', e);
            }
        }
        setIframeHeight1();
    }, [])

  function PaperComponent(props) {
      return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
          <Paper {...props} />
        </Draggable>
      );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let lang = getLang(i18n.language);
  let fullManualUrl = app_server_url + '/Docs/' + lang + '/USER_MANUAL/USER_MANUAL.html' ;
  let manualUrl = app_server_url + '/HelpOnLine/' + lang  + '/USER_MANUAL/' ;
  if(isPatient(userInfo) || location === "loginp")
    manualUrl += 'PATIENT/PATIENT.html'
  else
    manualUrl += 'by_path/' + location + '.html'
  // manualUrl += '#' + location
  const fullManual = () => {
    window.open(fullManualUrl,'wt3_manual')
  }


  let iw = 800
  let ih = 600
  if(size) {
    iw = size[0]
    ih = size[1]
  }

  // console.log("props: " + JSON.stringify(props));
  //console.log("FormDialog luogo ",luogo);

  return (
  <React.Fragment>
   <Tooltip title={t("Aiuto")}>
    <IconButton
        id="help_button"
        onClick={handleClickOpen}
        color="inherit"
    >
        <HelpIcon />
    </IconButton>
   </Tooltip>
   <Dialog
        id="helpDialog"
        open={open}
        scroll="paper"
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Manuale utente ( {location} )
        </DialogTitle>
        <DialogContent>
          <div>
            <iframe style={{ border: 0 }} id="helpIframe" title="help iframe" width={iw} height={ih} src={manualUrl} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button id="full_manual" onClick={fullManual} variant="outlined" color="secondary">
            {t("Manuale completo")}
          </Button>
          <Button id="help_close" onClick={handleClose} variant="outlined" color="secondary">
            {t("Chiudi")}
          </Button>
        </DialogActions>
  </Dialog>

  </React.Fragment>
  );
}
