import React, { useState, useEffect, useCallback } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import Grid from "@material-ui/core/Grid";
import { MostButton2, getOptionList, getOptionListElement, Loading, MostSubmitButton, MostAutocomplete, MostTextField, } from "../components/MostComponents";
import MyAxios, {check_response} from "../MyAxios";
import { getLang } from "../i18n";

export const Centro = (props) => {
  console.log("Centro",props.row)
  const history = useHistory();
  const [disabledButs, setDisabledButs] = useState(false)
  const [collapsed, setCollapsed] = React.useState(false);
  const { control, register, handleSubmit, errors, } = useForm();
  const { t, i18n } = useTranslation();
  const [geotreeid_defValue, setGeotreeid_defValue] = useState(null)
  const [ecrf_defValue, setEcrf_defValue] = useState(null)
  const [options_ecrf, setOptions_ecrf] = useState([])
  const [formReady, setFormReady] = useState(false)
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
  }, [setContent,setAlert1])

  useEffect(() => {
    const formData = new FormData()
    formData.append('table_name', 'svc_ecrf')
    formData.append('format', 'JSON')
    formData.append('inline', true)
    // xxxx lang
    formData.append('lang', getLang(i18n.language))
    console.log("/admin/GetConfTable", [...formData.entries()])
    MyAxios.post("/admin/GetConfTable", formData, {
        headers: { "Content-Type": "multipart/form-data;" }
    }).then((response) => {
      const data = check_response(response);
      if(!data.success) {
        console.error(data.error)
        appAlert(data.error)
        return
      }
      const oe = getOptionList(data.rows,'id','description')
      setOptions_ecrf(oe)
      // calcoliamo anche default per le select
      if(props.row) {
        setEcrf_defValue( getOptionListElement(oe,props.row.ecrf) )
        setGeotreeid_defValue( getOptionListElement(props.options_geotreeid,props.row.geotreeid) )
      }
      setFormReady(true)
    })
    .catch(function (error) {
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    })
  }, [appAlert,props.row,props.options_geotreeid,i18n.language])

  const handleChangeSwitch  = () => {
    setCollapsed((prev) => !prev);
  };

  const centroOnSubmit = (vals) => {
    console.log("centroOnSubmit", vals)
    if(vals.password !== vals.password2) {
        appAlert("Le password non coincidono")
        return
    }
    setDisabledButs(true)
    const formData = new FormData()
    formData.append('id', vals.id)
    formData.append('name', vals.name)
    formData.append('geotreeid', vals.geotreeid.value)
    formData.append('address', vals.address)
    formData.append('city', vals.city)
    if(vals.ecrf)
        formData.append('ecrf', vals.ecrf.value)
    if(vals.password !== "")
        formData.append('password', vals.password)
    if (props.centerId === "__new__")
        formData.append('action', 'ADD')
    else {
        formData.append('action', 'CHANGE')
    }
    console.log("/admin/center_admin", [...formData.entries()])
    MyAxios.post("/admin/center_admin", formData, {
        headers: { "Content-Type": "multipart/form-data;" }
    }).then((response) => {
        const res = check_response(response)
        if (res.success) {
            history.push('/centri');
        } else {
            console.error("Error: "+res.error);
            appAlert(res.error);
            setDisabledButs(false)
        }
    })
    .catch(function (error) {
        // handle error
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
    })
  }

  const PasswordPassword2 = (props) => {
      return (
      <React.Fragment>
        <Grid item xs={12}>
    {/* type password: browser inserisce password a caso! 
    https://stackoverflow.com/questions/48304062/material-ui-textfield-disable-browser-autocomplete
    https://stackoverflow.com/questions/41217019/how-to-prevent-a-browser-from-storing-passwords
    */}
        <MostTextField name="password" type="password" autoComplete="new-password" label={t("ecrf password")} register={props.register} />
        </Grid>
        <Grid item xs={12}>
        <MostTextField name="password2" type="password" autoComplete="new-password" label={t("ecrf password confirm")} register={props.register} />
        </Grid>
      </React.Fragment>
      )
  }

  let title
  if (props.centerId === "__new__") {
    title = t("Nuovo centro")
  } else {
    if (!formReady) {
        // dobbiamo costruire form solo dopo che abbiamo tutti i dati
        // altrimenti vengono presi defaultValue nulli
        // che non vengono piu' modificati da successivi richiami con defaultValue significativi
        return <Loading />
    }
    title = t("Gestione centro")+" "+props.row.name
  }

  return (
  <div className="app-container">
  <Header title={title} backMode="/centri" />
  <div className="content-container">
  <Container component="main" maxWidth="md">
    { props.centerId !== "__new__" ? 
        <Toolbar className="rowreverse">
            <Button disabled variant="contained" color="primary" className="marginr10" > Disabilita </Button>
            <Button disabled variant="contained" color="primary" className="marginr10" > Cancella </Button>
        </Toolbar>
    : null }
    <div>
      <form onSubmit={handleSubmit(centroOnSubmit)} noValidate>
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <MostTextField name="id" required={true} label={t("Codice")} InputProps={props.row ? { readOnly: true } : null} register={register({ required: true })} defaultValue={props.row ? props.row.id : null} errors={errors} inputProps={{ maxLength: 80 }} />
            </Grid>
            <Grid item xs={12}>
              <MostTextField name="name" required={true} label={t("Nome")} register={register({ required: true })} defaultValue={props.row ? props.row.name : null} errors={errors} inputProps={{ maxLength: 80 }} />
            </Grid>
            <Grid item xs={12}>
              <MostAutocomplete control={control} name="geotreeid" options={props.options_geotreeid} label={t("Area")+" *"} defaultValue={geotreeid_defValue} required={true} rules={{ required: true }} errors={errors} />
            </Grid>
            <Grid item xs={12}>
              <MostTextField name="address" label={t("Indirizzo")} register={register} defaultValue={props.row ? props.row.address : null} inputProps={{ maxLength: 80 }} />
            </Grid>
            <Grid item xs={12}>
              <MostTextField name="city" label={t("Città")} register={register} defaultValue={props.row ? props.row.city : null} inputProps={{ maxLength: 80 }} />
            </Grid>
            <Grid item xs={12}>
              <MostAutocomplete control={control} name="ecrf" options={options_ecrf} label={t("ecrf")} defaultValue={ecrf_defValue} />
            </Grid>
            {props.row && props.row.password ?
              <Grid item xs={12}>
                <FormControlLabel
                    control={<Switch color="primary" checked={collapsed} onChange={handleChangeSwitch} />}
                    label={t("Modifica password e-CRF")}
                />
                <Collapse in={collapsed}>
                    <PasswordPassword2 register={register} />
                </Collapse>
              </Grid>
            :
              <PasswordPassword2 register={register} />
            }
            <Grid item xs={8}>
                <MostSubmitButton disabled={disabledButs} label={props.centerId === "__new__" ? t("Inserisci") : t("Modifica")} />
            </Grid>
            <Grid item xs={4}>
                <MostButton2 fullWidth disabled={disabledButs} className="margin2416" onClick={() => history.push("/centri")} label={t("Indietro")} />
            </Grid>
        </Grid>
      </form>
    </div>
  </Container>
  </div>
  <Footer />
  </div>
  )
}
