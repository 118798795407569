import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import Tooltip from '@material-ui/core/Tooltip';
import { selectValue2obj, MostSelect, MostButton, MostSubmitButton, MostTextField, Check } from "../components/MostComponents";
import useStyles from "../components/useStyles";
import { Header } from "../Header";
import { Footer } from "../Footer";
import MyAxios, { check_response } from "../MyAxios";

export const Identity = (props) => {
  const history = useHistory();
  //const { control, register, handleSubmit } = useForm();
  const { control, register, handleSubmit, errors } = useForm();
  const classes = useStyles();
  //const [documento, setDocumento] = useState("");
  const { t } = useTranslation(["translation", "identity"]);
  const { userInfo, setUserInfo } = useGlobalHook('userStore');
  const [disabledButs, setDisabledButs] = useState(false)

  const { setAlert1, setContent } = useGlobalHook('alertStore');
  function appAlert(text) {
      setContent(text);
      setAlert1(true);
  }
  const identitaOptions = [
    {label: t("utente anonimo"), value: "anonimo"},
    {label: t("username"), value: "username"},
    {label: t("identità completa"), value: "completa"},
  ]

/*
  const selectFile = (event) => {
    console.log("selectFile");
    console.log(event.target.files[0]);
    setDocumento(event.target.files[0]);
  };
*/

  const onSubmit = (vals) => {
    console.log("onSubmit: " + JSON.stringify(vals));
    /*
    if(documento === "") {
      appAlert(t("Documento non caricato"));
      return;
    }
    */
    //alert("onSubmit: " + JSON.stringify(vals));
    let formData = new FormData();
    for ( var key in vals ) {
        if(vals[key] !== "") {
            let val
            if(key==="identita")
                val = vals[key].value
            else
                val = vals[key]
            formData.append(key, val);
            console.log("key: " + key + " val: " + val);
        }
    }
    //formData.append('documento', documento);

    setDisabledButs(true)
    console.log("newidentity", [...formData.entries()])
    MyAxios.post("newidentity", formData , {
        headers: { "Content-Type": "multipart/form-data;" },
        },
      )
      .then((response) => {
        response = check_response(response);
        // alert(JSON.stringify(response));
        //console.log(response);
        if (response.success) {
          setUserInfo(response.user);
          if(props.mode==="identityM") {
            if(response.sendmail)
                appAlert("E' stata inviata una nuova email all'indirizzo specificato");
            else
                appAlert("Dati aggiornati");
          } else {
            if(userInfo.bcaddress_in_bc)
                history.push("/uhome");
            else
                history.push("/enrollBC");
          }
        } else {
          console.error(response);
          appAlert(response.error);
          setDisabledButs(false)
        }
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
      })
  };

  const onSubmitEnroll = () => {
    history.push("/enrollBC");
  };

  console.log("userInfo", userInfo);
  let title
  let buttonLabel = t("identity:Inserisci")
  if(props.mode==="identityM") {
      title = t("I tuoi dati")
      if (!userInfo.email_verificata)
         buttonLabel = t("identity:Inserisci i dati e invia una nuova mail di conferma")
  } else {
      title = t("identity:NewIdentity")
  }
  return (
    <div>
      <Header />
      <h1>{title}</h1>
      <Container component="main" maxWidth="md">
        <div className={classes.root}>
          {props.mode!=="identityM" && !userInfo.bcaddress_in_bc ? (
            <MostButton disabled={disabledButs} onClick={onSubmitEnroll} label={t("identity:Enroll")} />
          ) : null }
          <form onSubmit={handleSubmit(onSubmit)} noValidate>

            <MostTextField name="cognome" defaultValue={userInfo.cognome} label={t("identity:cognome")} register={register} />
            <MostTextField name="nome" defaultValue={userInfo.nome} label={t("identity:nome")} register={register} />
            {/*
            <MostTextField name="residenza_via" label={t("identity:residenza_via")} register={register} />
            <MostTextField name="residenza_citta" label={t("identity:residenza_citta")} register={register} />
            <MostTextField name="residenza_stato" label={t("identity:residenza_stato")} register={register} />
            <MostTextField name="domicilio_via" label={t("identity:domicilio_via")} register={register} />
            <MostTextField name="domicilio_citta" label={t("identity:domicilio_citta")} register={register} />
            <MostTextField name="domicilio_stato" label={t("identity:domicilio_stato")} register={register} />
            <MostTextField name="cittadinanza" label={t("identity:cittadinanza")} register={register} />
            */}
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={11}>
                    <MostTextField name="email" defaultValue={userInfo.email} required={true} label={t("identity:email")} register={register({ required: true })} errors={errors} />
                </Grid>
                <Grid item xs={1}>
                    {/* senza span tooltip non funziona */}
                    <Tooltip title={userInfo.email_verificata != null ? t("Indirizzo verificato") : t("Indirizzo non verificato")}>
                        <span> <Check good={userInfo.email_verificata != null} /> </span>
                    </Tooltip>
                </Grid>
            </Grid>
            <MostTextField name="telefono" defaultValue={userInfo.telefono} label={t("identity:telefono")} register={register} />
            <MostTextField name="cellulare" defaultValue={userInfo.cellulare} label={t("identity:cellulare")} register={register} />
            {/*
            <MostTextField name="tipodocumento" label={t("identity:tipodocumento")} register={register} />
            <label className="btn btn-default top-margin-10">
              t("Documento")
              <input type="file" onChange={selectFile} className="MuiFormControl-marginNormal" />
            </label>
            */}
            <Grid item xs={12}>
                <MostSelect name="identita" defaultValue={selectValue2obj(userInfo.identita,identitaOptions)} options={identitaOptions} control={control} rules={{ required: true }} placeholder={t("identity:Identità")+" *"} errors={errors} />
            </Grid>
            <MostSubmitButton disabled={disabledButs} label={buttonLabel} />
          </form>
        </div>
      </Container>
      <Footer />
    </div>
  );
};
