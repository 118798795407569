import React, { useEffect, useState, useCallback, } from "react";
//import { useHistory } from "react-router-dom";
import { Header } from '../Header';
import Container from "@material-ui/core/Container";
import { Footer } from '../Footer';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { ymd, string2date, notNullString, } from '../Utils'
import { authorized, getCenterAdmin } from '../UtilsInthem'
import { EcrfPazienteDiv } from './EcrfPazienteDiv'
import { EcrfNuovoPaziente } from './EcrfNuovoPaziente'
import MyAxios, {check_response} from "../MyAxios";
import { useForm } from "react-hook-form";
import { getOptionBooleanListElement, MostSubmitButton, getOptionListElement, options_boolean, getOptionList, MostTextField, MostDatePicker, Loading, MostAutocomplete, } from "../components/MostComponents";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// xxx disabilitare modifiche per centri WT%

const complications = [
    "CARDIOMIOPATHIES",
    "CHRONIC_KIDNEY_DISEASE",
    "LIVER_DISEASE",
    "THROMBOSIS",
    "CONGESTIVE_HEART_FAILURE",
    "SKELETAL_PROBLEMS",
    "EYE_PROBLEMS",
    "DENTAL_PROBLEMS",
    "SPLENIC_SEQUESTRATION",
    "ACUTE_CHEST_SYNDROME",
    "STROKE",
    "VOC",
    "OSTEONECROSIS",
    "LEG_ULCERS",
    "PRIAPISM",
    "HCC",
    "HYPOTHYROIDISM",
    "HYPOPARATHYROIDISM",
    "DIABETES",
    "HYPOGANADISM",
    "HYPOCORTISOLISM",
    "OSTEOPOROSIS",
    "NEPHROLITHIASIS",
    "PULMONARY_HYPERTENSION",
    "CHOLELITHIASIS",
    "HEART_FAILURE",
    "ARRHYTHMIA",
    "LIVER_CIRRHOSIS",
    "HBV",
    "HCV",
    "SPLENOMEGALY",
    "SPLENECTOMY",
    "CHOLECISTECTOMY",
]

const autocompletes = [
    ["SEX","options_SEX"],
    ["STATUS","options_STATUS"],
    ["BLOOD_GROUP","options_BLOOD_GROUP"],
    ["FENOTYPE","options_FENOTYPE"],
    ["DIAGNOSIS","options_DIAGNOSIS"],
    ["DIAGNOSIS_2","options_DIAGNOSIS"],
    ["DIAGNOSIS_3","options_DIAGNOSIS"],
    ["GENOTYPE","options_GENOTYPE"],
    ["CARDIOMIOPATHIES","options_bool"],
    ["CHRONIC_KIDNEY_DISEASE","options_bool"],
    ["LIVER_DISEASE","options_bool"],
    ["THROMBOSIS","options_bool"],
    ["CONGESTIVE_HEART_FAILURE","options_bool"],
    ["SKELETAL_PROBLEMS","options_bool"],
    ["EYE_PROBLEMS","options_bool"],
    ["DENTAL_PROBLEMS","options_bool"],
    ["SPLENIC_SEQUESTRATION","options_bool"],
    ["ACUTE_CHEST_SYNDROME","options_bool"],
    ["STROKE","options_bool"],
    ["VOC","options_bool"],
    ["OSTEONECROSIS","options_bool"],
    ["LEG_ULCERS","options_bool"],
    ["PRIAPISM","options_bool"],
    ["HCC","options_bool"],
    ["HYPOTHYROIDISM","options_bool"],
    ["HYPOPARATHYROIDISM","options_bool"],
    ["DIABETES","options_bool"],
    ["HYPOGANADISM","options_bool"],
    ["HYPOCORTISOLISM","options_bool"],
    ["OSTEOPOROSIS","options_bool"],
    ["NEPHROLITHIASIS","options_bool"],
    ["PULMONARY_HYPERTENSION","options_bool"],
    ["CHOLELITHIASIS","options_bool"],
    ["HEART_FAILURE","options_bool"],
    ["ARRHYTHMIA","options_bool"],
    ["LIVER_CIRRHOSIS","options_bool"],
    ["HBV","options_bool"],
    ["HCV","options_bool"],
    ["SPLENOMEGALY","options_bool"],
    ["SPLENECTOMY","options_bool"],
    ["CHOLECISTECTOMY","options_bool"],
    ["BMT","options_bool"],
    ["TT","options_TT"],
    ["TT_TYPE","options_TT_TYPE"],
    ["TT_ONGOING","options_bool"],
    ["IMMUNIZATION","options_IMMUNIZATION"],
    ["ICT_TYPE","options_bool"],
    ["ICT_ONGOING","options_bool"],
    ["ICT_IP","options_bool"],
    ["ICT_MODALITY","options_ICT_MODALITY"],
    ["ICT_OTHER_PRODUCT","options_ICT_OTHER_PRODUCT"],
    ["HU_TERAPY_TYPE","options_HU_TERAPY_TYPE"],
    ["HU_TERAPY_ONGOING","options_bool"],
    ["LUS_TERAPY_TYPE","options_LUS_TERAPY_TYPE"],
    ["LUS_TERAPY_ONGOING","options_bool"],
    ["GT","options_bool"],
    ["GT_OUTCOME","options_GT_OUTCOME"],
    ["COVID_STATUS","options_bool"],
    ["COVID_SYMPTOM","options_COVID_SYMPTOM"],
    ["COVID_DETECTION","options_COVID_DETECTION"],
    ["COVID_SOURCE","options_COVID_SOURCE"],
    ["COVID_HOSPITALIZATION","options_COVID_HOSPITALIZATION"],
    ["COVID_OUTCOME","options_COVID_OUTCOME"],
]

export const Ecrf = () => {
    const { userInfo } = useGlobalHook('userStore');
    const centro = getCenterAdmin(userInfo)
    const { t } = useTranslation();
    const [disabledButs, setDisabledButs] = useState(false)
    const [def, setDef] = useState({})
    const [orig_values, setOrig_values] = useState({})
    const [loading, setLoading] = React.useState(true)
    const [paziente, setPaziente] = useState(null)
    const [mode, setMode] = useState(null)
    const { register, control, handleSubmit, } = useForm();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const [options_patient, setOptions_patient] = useState([])
    const options_bool = options_boolean(t)
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])
    const [CENTER_START, setCenter_start] = useState(null) 
    const [DEAD_DATE, setDead_date] = useState(null) 
    const [DOB, setDob] = useState(null) 
    const [DOD, setDod] = useState(null) 
    const [ICT_START, setIct_start] = useState(null) 
    const [HU_TERAPY_START, setHu_terapy_start] = useState(null) 
    const [LUS_TERAPY_START, setLus_terapy_start] = useState(null) 
    const [GT_START, setGt_start] = useState(null) 
    const [DEXA_DATE, setDexa_date] = useState(null) 
    const [SPLENECTOMY_DATE, setSplenectomy_date] = useState(null) 
    const [CHOLECISTECTOMY_DATE, setCholecistectomy_date] = useState(null) 
    const [BMT_DATE, setBmt_date] = useState(null) 
    const [TT_START, setTt_start] = useState(null) 
    // volendo si puo' usare uno stato solo, un oggetto con dentro
    // tutte le options. Questo permette di modificare uno stato solo invece di 21
    // pero' bisogna cambiare il codice un po' dappertutto...
    const [options_COVID_DETECTION, setOptions_COVID_DETECTION] = useState([])
    const [options_COVID_HOSPITALIZATION, setOptions_COVID_HOSPITALIZATION] = useState([]) 
    const [options_COVID_OUTCOME, setOptions_COVID_OUTCOME] = useState([]) 
    const [options_COVID_SOURCE, setOptions_COVID_SOURCE] = useState([]) 
    const [options_COVID_SYMPTOM, setOptions_COVID_SYMPTOM] = useState([]) 
    const [options_DIAGNOSIS, setOptions_DIAGNOSIS] = useState([]) 
    const [options_FENOTYPE, setOptions_FENOTYPE] = useState([]) 
    const [options_GENOTYPE, setOptions_GENOTYPE] = useState([]) 
    const [options_GT_OUTCOME, setOptions_GT_OUTCOME] = useState([]) 
    const [options_ICT_MODALITY, setOptions_ICT_MODALITY] = useState([]) 
    const [options_ICT_OTHER_PRODUCT, setOptions_ICT_OTHER_PRODUCT] = useState([]) 
    const [options_IMMUNIZATION, setOptions_IMMUNIZATION] = useState([]) 
    const [options_SEX, setOptions_SEX] = useState([]) 
    const [options_STATUS, setOptions_STATUS] = useState([]) 
    const [options_TT_TYPE, setOptions_TT_TYPE] = useState([]) 
    const [options_BLOOD_GROUP, setOptions_BLOOD_GROUP] = useState([]) 
    const [options_TT, setOptions_TT] = useState([]) 
    const [options_HU_TERAPY_TYPE, setOptions_HU_TERAPY_TYPE] = useState([]) 
    const [options_LUS_TERAPY_TYPE, setOptions_LUS_TERAPY_TYPE] = useState([]) 
    const [options_ICD9, setOptions_ICD9] = useState([]) 
    const getPatientOptionList = useCallback((list) => {
        const ret = []
        for (let i in list) {
            ret.push({value: list[i].CENTER_ID, label: list[i].CENTER_ID+" "+ notNullString(list[i].SURNAME) + " " + notNullString(list[i].NAME), row: list[i]}) }
        return ret
    }, [])

    useEffect(() => {
        const jdata = {
            action: 'LIST_PATIENTS',
            all_fields: false,
        }
        MyAxios.post("/e_crf", jdata,
        ).then((response) => {
            const data = check_response(response);
            if(!data.success) {
                setLoading(false)
                console.error(data.error)
                appAlert(data.error)
                return
            }
            let ol
            ol = getPatientOptionList(data.rows)
            setOptions_patient(ol)
            ol = getOptionList(data.covid_detection,'id','description')
            setOptions_COVID_DETECTION(ol)
            ol = getOptionList(data.covid_hospitalization,'id','description')
            setOptions_COVID_HOSPITALIZATION(ol)
            ol = getOptionList(data.covid_outcome,'id','description')
            setOptions_COVID_OUTCOME(ol)
            ol = getOptionList(data.covid_source,'id','description')
            setOptions_COVID_SOURCE(ol)
            ol = getOptionList(data.covid_symptom,'id','description')
            setOptions_COVID_SYMPTOM(ol)
            ol = getOptionList(data.diagnosis,'code','description')
            setOptions_DIAGNOSIS(ol)
            ol = getOptionList(data.fenotype)
            setOptions_FENOTYPE(ol)
            ol = getOptionList(data.genotype,'id','description')
            setOptions_GENOTYPE(ol)
            ol = getOptionList(data.gt_outcome)
            setOptions_GT_OUTCOME(ol)
            ol = getOptionList(data.ict_modality,'id','description')
            setOptions_ICT_MODALITY(ol)
            ol = getOptionList(data.ict_other_product,'code','code+active_principle')
            setOptions_ICT_OTHER_PRODUCT(ol)
            ol = getOptionList(data.immunization)
            setOptions_IMMUNIZATION(ol)
            ol = getOptionList(data.sex,'id','description')
            setOptions_SEX(ol)
            ol = getOptionList(data.status,'id','description')
            setOptions_STATUS(ol)
            ol = getOptionList(data.tt_type,'id','description')
            setOptions_TT_TYPE(ol)
            ol = getOptionList(data.blood_group,'id','description')
            setOptions_BLOOD_GROUP(ol)
            ol = getOptionList(data.tt,'id','description')
            setOptions_TT(ol)
            ol = getOptionList(data.hu_terapy_type,'id','description')
            setOptions_HU_TERAPY_TYPE(ol)
            ol = getOptionList(data.lus_terapy_type,'id','description')
            setOptions_LUS_TERAPY_TYPE(ol)
            ol = getOptionList(data.icd9,'code','description')
            setOptions_ICD9(ol)
            setLoading(false)
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setLoading(false)
        })
    }, [appAlert, getPatientOptionList, ])

    function getListFromName(nome) {
        if(nome==="options_bool")
            return options_bool
        if(nome==="options_SEX")
            return options_SEX
        if(nome==="options_STATUS")
            return options_STATUS
        if(nome==="options_BLOOD_GROUP")
            return options_BLOOD_GROUP
        if(nome==="options_FENOTYPE")
            return options_FENOTYPE
        if(nome==="options_DIAGNOSIS")
            return options_DIAGNOSIS
        if(nome==="options_GENOTYPE")
            return options_GENOTYPE
        if(nome==="options_TT")
            return options_TT
        if(nome==="options_TT_TYPE")
            return options_TT_TYPE
        if(nome==="options_IMMUNIZATION")
            return options_IMMUNIZATION
        if(nome==="options_ICT_MODALITY")
            return options_ICT_MODALITY
        if(nome==="options_ICT_OTHER_PRODUCT")
            return options_ICT_OTHER_PRODUCT
        if(nome==="options_HU_TERAPY_TYPE")
            return options_HU_TERAPY_TYPE
        if(nome==="options_LUS_TERAPY_TYPE")
            return options_LUS_TERAPY_TYPE
        if(nome==="options_GT_OUTCOME")
            return options_GT_OUTCOME
        if(nome==="options_COVID_SYMPTOM")
            return options_COVID_SYMPTOM
        if(nome==="options_COVID_DETECTION")
            return options_COVID_DETECTION
        if(nome==="options_COVID_SOURCE")
            return options_COVID_SOURCE
        if(nome==="options_COVID_HOSPITALIZATION")
            return options_COVID_HOSPITALIZATION
        if(nome==="options_COVID_OUTCOME")
            return options_COVID_OUTCOME
        return []
    }

    function reloadPatients() {
        setLoading(true)
        const jdata = {
            action: 'LIST_PATIENTS',
            all_fields: false,
            rows_only: true,
        }
        MyAxios.post("/e_crf", jdata,
        ).then((response) => {
            setLoading(false)
            const data = check_response(response);
            if(!data.success) {
                console.error(data.error)
                appAlert(data.error)
                return
            }
            const ol = getPatientOptionList(data.rows)
            setOptions_patient(ol)
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setLoading(false)
        })
    }

    const apriPaziente = (el) => {
        setLoading(true)
        setPaziente(el)
        const jdata = {
            action: 'GET_PATIENT_DATA',
            CENTER_ID: el.value,
        }
        MyAxios.post("/e_crf", jdata,
        ).then((response) => {
            const data = check_response(response);
            if(!data.success) {
                console.error(data.error)
                appAlert(data.error)
                setLoading(false)
                return
            }
            // dati originali del paziente come arrivano dal db, insieme dati paziente e dati medici
            const orig_values = {...data.patient, ...data.medical_data}
            setOrig_values(orig_values)

            const merged = {...data.patient, ...data.medical_data}
            for (let i in autocompletes) {
                const campo = autocompletes[i][0]
                const options = autocompletes[i][1]
                if(options === "options_bool") {
                    merged[campo] = getOptionBooleanListElement(options_bool,merged[campo])
                } else {
                    merged[campo] = getOptionListElement(getListFromName(options),merged[campo])
                }
            }
            // i dati medici possono non arrivare o perche' non e' stato dato il consenso
            // o perche' non e' ancora stata fatta la sincronizzazione
            if (merged.HOSPEDAL_CAUSES !== undefined) {
                // HOSPEDAL_CAUSES
                // console.log("merged.HOSPEDAL_CAUSES",merged.HOSPEDAL_CAUSES)
                const hc = []
                if(merged.HOSPEDAL_CAUSES !== null && merged.HOSPEDAL_CAUSES !== "") {
                    const v = JSON.parse(merged.HOSPEDAL_CAUSES)
                    for (let i in v) {
                        hc.push(getOptionListElement(options_ICD9,v[i]))
                    }
                }
                merged.HOSPEDAL_CAUSES = hc
                //console.log("merged.HOSPEDAL_CAUSES",merged.HOSPEDAL_CAUSES)
            }
            setDef(merged)
            setCenter_start(string2date(merged.CENTER_START))
            setDead_date(string2date(merged.DEAD_DATE))
            setDob(string2date(merged.DOB))
            setDod(string2date(merged.DOD))
            setIct_start(string2date(merged.ICT_START))
            setHu_terapy_start(string2date(merged.HU_TERAPY_START))
            setLus_terapy_start(string2date(merged.LUS_TERAPY_START))
            setGt_start(string2date(merged.GT_START))
            setDexa_date(string2date(merged.DEXA_DATE))
            setSplenectomy_date(string2date(merged.SPLENECTOMY_DATE))
            setCholecistectomy_date(string2date(merged.CHOLECISTECTOMY_DATE))
            setBmt_date(string2date(merged.BMT_DATE))
            setTt_start(string2date(merged.TT_START))
            setLoading(false)
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setLoading(false)
        })
    }

    const chiudiPaziente = () => {
        setPaziente(null)
    }

    const nuovoPaziente = () => {
        setPaziente(null)
        setMode("nuovoPaziente")
    }

    function badDate(d,s) {
        if(isNaN(d)) {
            appAlert(s+": "+t("Data non valida"))
            return true
        }
        return false
    }

    function equals(v,orig_v) {
        if(v === orig_v)
            return true
        if((v===null||v==="") && (orig_v===null||orig_v===undefined||orig_v===""))
            return true
        // v stringa, orig_v puo' essere boolean o numero
        if(v === ""+orig_v)
            return true
        return false
    }

    function countComplications(submitted_vals) {
        let n = 0
        let all_null = true
        for (let i in complications) {
            if(submitted_vals[complications[i]]) {
                all_null = false
                if(submitted_vals[complications[i]].value === "true")
                    n++
            }
        }
        if(all_null)
            return null
        return n
    }

    const onSubmit = (vals) => {
        console.log("onSubmit", vals)
        if (!orig_values.C_DB_P && !orig_values.C_DB_M)
            return
        // SEX DOB obbligatori se orig_values.C_DB_P
        if (orig_values.C_DB_P) {
            if (!DOB || !vals.SEX) {
                appAlert("I campi DOB e SEX sono obbligatori")
                return
            }
        }
        const submitted_vals = { ...vals }
        // liste
        for (let i in autocompletes) {
            const campo = autocompletes[i][0]
            if(vals[campo])
                vals[campo] = vals[campo].value
        }
        // date 
        if(badDate(DEAD_DATE,"DEAD_DATE") 
        || badDate(DOD,"DOD")
        || badDate(SPLENECTOMY_DATE,"SPLENECTOMY_DATE")
        || badDate(CHOLECISTECTOMY_DATE,"CHOLECISTECTOMY_DATE")
        || badDate(BMT_DATE,"BMT_DATE")
        || badDate(TT_START,"TT_START")
        || badDate(ICT_START,"ICT_START")
        || badDate(HU_TERAPY_START,"HU_TERAPY_START")
        || badDate(LUS_TERAPY_START,"LUS_TERAPY_START")
        || badDate(GT_START,"GT_START")
        || badDate(DEXA_DATE,"DEXA_DATE")
        || badDate(CENTER_START,"CENTER_START")
        || badDate(DOB,"DOB"))
            return
        vals.DEAD_DATE = DEAD_DATE ? ymd(DEAD_DATE) : null
        vals.DOD = DOD ? ymd(DOD) : null
        vals.SPLENECTOMY_DATE = SPLENECTOMY_DATE ? ymd(SPLENECTOMY_DATE) : null
        vals.CHOLECISTECTOMY_DATE = CHOLECISTECTOMY_DATE ? ymd(CHOLECISTECTOMY_DATE) : null
        vals.BMT_DATE = BMT_DATE ? ymd(BMT_DATE) : null
        vals.TT_START = TT_START ? ymd(TT_START) : null
        vals.ICT_START = ICT_START ? ymd(ICT_START) : null
        vals.HU_TERAPY_START = HU_TERAPY_START ? ymd(HU_TERAPY_START) : null
        vals.LUS_TERAPY_START = LUS_TERAPY_START ? ymd(LUS_TERAPY_START) : null
        vals.GT_START = GT_START ? ymd(GT_START) : null
        vals.DEXA_DATE = DEXA_DATE ? ymd(DEXA_DATE) : null
        // patient (nota: DEAD_DATE e DEAD_CAUSE vanno in dati medici)
        const patient = {}
        patient.CENTER_START = CENTER_START ? ymd(CENTER_START) : null
        patient.DOB = DOB ? ymd(DOB) : null

        if (vals.HOSPEDAL_CAUSES) {
            // HOSPEDAL_CAUSES
            //console.log("vals.HOSPEDAL_CAUSES",vals.HOSPEDAL_CAUSES)
            if(vals.HOSPEDAL_CAUSES.length===0)
                vals.HOSPEDAL_CAUSES = null
            else {
                let s = ''
                for (let i in vals.HOSPEDAL_CAUSES) {
                    if(s!=='')
                        s+=','
                    s+='"'+vals.HOSPEDAL_CAUSES[i].value+'"'
                }
                vals.HOSPEDAL_CAUSES = '['+s+']'
            }
            //console.log("vals.HOSPEDAL_CAUSES",vals.HOSPEDAL_CAUSES)
        }

        // spostiamo sex da vals (che diventano dati medici) in dati del paziente
        patient.SEX = vals.SEX
        delete vals.SEX

        // spediamo solo campi modificati
        for (let key in patient) {
            if (equals(patient[key],orig_values[key]))
                delete patient[key]
            else
                console.log(key,orig_values[key],patient[key])
        }
        for (let key in vals) {
            if (equals(vals[key],orig_values[key]))
                delete vals[key]
            else
                console.log(key,orig_values[key],vals[key])
        }
        //console.log("patient",patient)
        //console.log("vals",vals)
        if(Object.keys(patient).length === 0 && Object.keys(vals).length === 0) {
            appAlert("Nessun campo modificato")
            return
        }
        const n = countComplications(submitted_vals)
        if (n !== orig_values.COMPLICATIONS_NUM)
            vals.COMPLICATIONS_NUM = n

        setDisabledButs(true)
        const jdata = {
            action: 'PUT_PATIENT_DATA',
            CENTER_ID: paziente.value,
        }
        if (orig_values.C_DB_P && Object.keys(patient).length !== 0)
            jdata.PATIENT = patient
        if (orig_values.C_DB_M && Object.keys(vals).length !== 0)
            jdata.MEDICAL_DATA = vals
        MyAxios.post("/e_crf", jdata,
        ).then((response) => {
            setDisabledButs(false)
            const data = check_response(response);
            if(!data.success) {
                console.error(data.error)
                appAlert(data.error)
                return
            }
            appAlert("Dati salvati correttamente")
        })
        .catch(function (error) {
            setDisabledButs(false)
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }

  const redirect = authorized("CenterAdmin",userInfo)
  if(redirect)
    return redirect

  if (mode === "nuovoPaziente")
    return <EcrfNuovoPaziente setMode={setMode} reloadPatients={reloadPatients} />

  let patientEnabled = false
  if(orig_values && orig_values.C_DB_P)
    patientEnabled = true
  let medicalEnabled = false
  if(orig_values && orig_values.C_DB_M)
    medicalEnabled = true

  let dis = false
  if(disabledButs || (!patientEnabled && !medicalEnabled))
    dis = true

  //console.log("Ecrf")

  return (
    <div className="app-container">
        <Header title={"e-CRF "+centro} />
        <div className="content-container contentWithTabAndToolbar">
        <EcrfPazienteDiv options_patient={options_patient} apriPaziente={apriPaziente} paziente={paziente} setPaziente={setPaziente} chiudiPaziente={chiudiPaziente} nuovoPaziente={nuovoPaziente} />
        { loading ?
            <Loading />
        : paziente ?
            <Container component="main" maxWidth="sm">
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="formToolbarInTab">
                    <MostSubmitButton id="modifica" fullWidth={false} disabled={dis} label={t("Modifica")} />
                </div>
          <MostTextField name="SURNAME" disabled label="SURNAME" defaultValue={def.SURNAME} />
          <MostTextField name="NAME" disabled label="NAME" defaultValue={def.NAME} />
          <MostTextField name="CENTER_ID" disabled label="CENTER_ID" defaultValue={def.CENTER_ID} />

{ !patientEnabled ?
    <div className="blackColor">
        <br />
        <b>NOTA: Il paziente non ha concesso il consenso per il trattamento dei dati anagrafici</b>
        <br /> <br />
    </div>
: null }
{ patientEnabled || medicalEnabled ?
<Accordion defaultExpanded={patientEnabled && (!orig_values.SEX||!orig_values.DOB) ? true : false}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    Personal Data
    </AccordionSummary>
    <AccordionDetails>
        <div className="width100">
          {/* veri dati anagrafici */}
          { patientEnabled ?
            <React.Fragment>
              <MostDatePicker label="DOB *" value={DOB} onChange={(date) => setDob(date)} disableFuture={true} openTo="date" />
              <MostAutocomplete control={control} name="SEX" options={options_SEX} label="SEX *" defaultValue={def.SEX} />
              <MostDatePicker label="CENTER_START" value={CENTER_START} onChange={(date) => setCenter_start(date)} disableFuture={true} openTo="date" />
            </React.Fragment>
          : null }
          {/* trattati come dati medici */}
          { medicalEnabled ?
            <React.Fragment>
              <MostDatePicker label="DEAD_DATE" value={DEAD_DATE} onChange={(date) => setDead_date(date)} disableFuture={true} openTo="date" />
              <MostTextField name="DEAD_CAUSE" label="DEAD_CAUSE" register={register} defaultValue={def.DEAD_CAUSE} />
            </React.Fragment>
          : null }

        </div>
    </AccordionDetails>
</Accordion>
: null }

{ medicalEnabled ?
<React.Fragment>
            {/*
            ==============================
            INIZIO codice generato da python
            --------------------------------
            # funziona anche da server
            #bashin inthem_be
                // Controllare ultima versione AppendiceB-R2.*.xlsx 
                python parse_appendixB.py AppendiceB-R2.11.xlsx e_crf > /tmp/ecrf
            --
            corrette options DIAGNOSIS_*
            xxx SET OF HOSPEDAL_CAUSES, SetOf xxx
            <MostAutocomplete control={control} multiple name="HOSPEDAL_CAUSES" options={options_ICD9} label="HOSPEDAL_CAUSES" defaultValue={def.HOSPEDAL_CAUSES} />
            ==============================
            */}

<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    Misc
    </AccordionSummary>
    <AccordionDetails>
        <div className="width100">


          <MostTextField name="WEIGHT" label="WEIGHT" register={register} inputProps={{type:'number'}} defaultValue={def.WEIGHT} />
          <MostTextField name="OTHER_THERAPIES" label="OTHER_THERAPIES" register={register} multiline={true} rows={5} defaultValue={def.OTHER_THERAPIES} />
          <MostAutocomplete control={control} name="STATUS" options={options_STATUS} label="STATUS" defaultValue={def.STATUS} />
          <MostAutocomplete control={control} name="BLOOD_GROUP" options={options_BLOOD_GROUP} label="BLOOD_GROUP" defaultValue={def.BLOOD_GROUP} />
          <MostAutocomplete control={control} name="FENOTYPE" options={options_FENOTYPE} label="FENOTYPE" defaultValue={def.FENOTYPE} />

        </div>
    </AccordionDetails>
</Accordion>
        

<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    Diagnosis
    </AccordionSummary>
    <AccordionDetails>
        <div className="width100">


          <MostDatePicker label="DOD" value={DOD} onChange={(date) => setDod(date)} disableFuture={true} openTo="date" />
          <MostAutocomplete control={control} name="DIAGNOSIS" options={options_DIAGNOSIS} label="DIAGNOSIS" defaultValue={def.DIAGNOSIS} />
          <MostAutocomplete control={control} name="DIAGNOSIS_2" options={options_DIAGNOSIS} label="DIAGNOSIS_2" defaultValue={def.DIAGNOSIS_2} />
          <MostAutocomplete control={control} name="DIAGNOSIS_3" options={options_DIAGNOSIS} label="DIAGNOSIS_3" defaultValue={def.DIAGNOSIS_3} />
          <MostAutocomplete control={control} name="GENOTYPE" options={options_GENOTYPE} label="GENOTYPE" defaultValue={def.GENOTYPE} />

        </div>
    </AccordionDetails>
</Accordion>
        

<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    Complications
    </AccordionSummary>
    <AccordionDetails>
        <div className="width100">


          <MostTextField name="COMPLICATIONS_NUM" disabled label="COMPLICATIONS_NUM" defaultValue={def.COMPLICATIONS_NUM} />
          <MostAutocomplete control={control} name="CARDIOMIOPATHIES" options={options_bool} label="CARDIOMIOPATHIES" defaultValue={def.CARDIOMIOPATHIES} />
          <MostAutocomplete control={control} name="CHRONIC_KIDNEY_DISEASE" options={options_bool} label="CHRONIC_KIDNEY_DISEASE" defaultValue={def.CHRONIC_KIDNEY_DISEASE} />
          <MostAutocomplete control={control} name="LIVER_DISEASE" options={options_bool} label="LIVER_DISEASE" defaultValue={def.LIVER_DISEASE} />
          <MostAutocomplete control={control} name="THROMBOSIS" options={options_bool} label="THROMBOSIS" defaultValue={def.THROMBOSIS} />
          <MostAutocomplete control={control} name="CONGESTIVE_HEART_FAILURE" options={options_bool} label="CONGESTIVE_HEART_FAILURE" defaultValue={def.CONGESTIVE_HEART_FAILURE} />
          <MostAutocomplete control={control} name="SKELETAL_PROBLEMS" options={options_bool} label="SKELETAL_PROBLEMS" defaultValue={def.SKELETAL_PROBLEMS} />
          <MostAutocomplete control={control} name="EYE_PROBLEMS" options={options_bool} label="EYE_PROBLEMS" defaultValue={def.EYE_PROBLEMS} />
          <MostAutocomplete control={control} name="DENTAL_PROBLEMS" options={options_bool} label="DENTAL_PROBLEMS" defaultValue={def.DENTAL_PROBLEMS} />
          <MostAutocomplete control={control} name="SPLENIC_SEQUESTRATION" options={options_bool} label="SPLENIC_SEQUESTRATION" defaultValue={def.SPLENIC_SEQUESTRATION} />
          <MostAutocomplete control={control} name="ACUTE_CHEST_SYNDROME" options={options_bool} label="ACUTE_CHEST_SYNDROME" defaultValue={def.ACUTE_CHEST_SYNDROME} />
          <MostAutocomplete control={control} name="STROKE" options={options_bool} label="STROKE" defaultValue={def.STROKE} />
          <MostAutocomplete control={control} name="VOC" options={options_bool} label="VOC" defaultValue={def.VOC} />
          <MostAutocomplete control={control} name="OSTEONECROSIS" options={options_bool} label="OSTEONECROSIS" defaultValue={def.OSTEONECROSIS} />
          <MostAutocomplete control={control} name="LEG_ULCERS" options={options_bool} label="LEG_ULCERS" defaultValue={def.LEG_ULCERS} />
          <MostAutocomplete control={control} name="PRIAPISM" options={options_bool} label="PRIAPISM" defaultValue={def.PRIAPISM} />
          <MostAutocomplete control={control} name="HCC" options={options_bool} label="HCC" defaultValue={def.HCC} />
          <MostAutocomplete control={control} name="HYPOTHYROIDISM" options={options_bool} label="HYPOTHYROIDISM" defaultValue={def.HYPOTHYROIDISM} />
          <MostAutocomplete control={control} name="HYPOPARATHYROIDISM" options={options_bool} label="HYPOPARATHYROIDISM" defaultValue={def.HYPOPARATHYROIDISM} />
          <MostAutocomplete control={control} name="DIABETES" options={options_bool} label="DIABETES" defaultValue={def.DIABETES} />
          <MostAutocomplete control={control} name="HYPOGANADISM" options={options_bool} label="HYPOGANADISM" defaultValue={def.HYPOGANADISM} />
          <MostAutocomplete control={control} name="HYPOCORTISOLISM" options={options_bool} label="HYPOCORTISOLISM" defaultValue={def.HYPOCORTISOLISM} />
          <MostAutocomplete control={control} name="OSTEOPOROSIS" options={options_bool} label="OSTEOPOROSIS" defaultValue={def.OSTEOPOROSIS} />
          <MostAutocomplete control={control} name="NEPHROLITHIASIS" options={options_bool} label="NEPHROLITHIASIS" defaultValue={def.NEPHROLITHIASIS} />
          <MostAutocomplete control={control} name="PULMONARY_HYPERTENSION" options={options_bool} label="PULMONARY_HYPERTENSION" defaultValue={def.PULMONARY_HYPERTENSION} />
          <MostAutocomplete control={control} name="CHOLELITHIASIS" options={options_bool} label="CHOLELITHIASIS" defaultValue={def.CHOLELITHIASIS} />
          <MostAutocomplete control={control} name="HEART_FAILURE" options={options_bool} label="HEART_FAILURE" defaultValue={def.HEART_FAILURE} />
          <MostAutocomplete control={control} name="ARRHYTHMIA" options={options_bool} label="ARRHYTHMIA" defaultValue={def.ARRHYTHMIA} />
          <MostAutocomplete control={control} name="LIVER_CIRRHOSIS" options={options_bool} label="LIVER_CIRRHOSIS" defaultValue={def.LIVER_CIRRHOSIS} />
          <MostAutocomplete control={control} name="HBV" options={options_bool} label="HBV" defaultValue={def.HBV} />
          <MostAutocomplete control={control} name="HCV" options={options_bool} label="HCV" defaultValue={def.HCV} />
          <MostAutocomplete control={control} name="SPLENOMEGALY" options={options_bool} label="SPLENOMEGALY" defaultValue={def.SPLENOMEGALY} />
          <MostAutocomplete control={control} name="SPLENECTOMY" options={options_bool} label="SPLENECTOMY" defaultValue={def.SPLENECTOMY} />
          <MostDatePicker label="SPLENECTOMY_DATE" value={SPLENECTOMY_DATE} onChange={(date) => setSplenectomy_date(date)} disableFuture={true} openTo="date" />
          <MostAutocomplete control={control} name="CHOLECISTECTOMY" options={options_bool} label="CHOLECISTECTOMY" defaultValue={def.CHOLECISTECTOMY} />
          <MostDatePicker label="CHOLECISTECTOMY_DATE" value={CHOLECISTECTOMY_DATE} onChange={(date) => setCholecistectomy_date(date)} disableFuture={true} openTo="date" />

        </div>
    </AccordionDetails>
</Accordion>
        

<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    Surgery
    </AccordionSummary>
    <AccordionDetails>
        <div className="width100">


          <MostAutocomplete control={control} name="BMT" options={options_bool} label="BMT" defaultValue={def.BMT} />
          <MostDatePicker label="BMT_DATE" value={BMT_DATE} onChange={(date) => setBmt_date(date)} disableFuture={true} openTo="date" />
          <MostTextField name="BMT_NOTE" label="BMT_NOTE" register={register} multiline={true} rows={5} defaultValue={def.BMT_NOTE} />

        </div>
    </AccordionDetails>
</Accordion>
        

<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    Hospitalization
    </AccordionSummary>
    <AccordionDetails>
        <div className="width100">


          <MostTextField name="HOSPEDALIZATIONS" label="HOSPEDALIZATIONS" register={register} inputProps={{type:'number'}} defaultValue={def.HOSPEDALIZATIONS} />
          <MostTextField name="HOSPEDAL_DAYS" label="HOSPEDAL_DAYS" register={register} inputProps={{type:'number'}} defaultValue={def.HOSPEDAL_DAYS} />
          <MostAutocomplete control={control} multiple name="HOSPEDAL_CAUSES" options={options_ICD9} label="HOSPEDAL_CAUSES" defaultValue={def.HOSPEDAL_CAUSES} />
        </div>
    </AccordionDetails>
</Accordion>
        

<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    Transfusion
    </AccordionSummary>
    <AccordionDetails>
        <div className="width100">


          <MostAutocomplete control={control} name="TT" options={options_TT} label="TT" defaultValue={def.TT} />
          <MostTextField name="TT_QTY" label="TT_QTY" register={register} inputProps={{type:'number'}} defaultValue={def.TT_QTY} />
          <MostAutocomplete control={control} name="TT_TYPE" options={options_TT_TYPE} label="TT_TYPE" defaultValue={def.TT_TYPE} />
          <MostDatePicker label="TT_START" value={TT_START} onChange={(date) => setTt_start(date)} disableFuture={true} openTo="date" />
          <MostAutocomplete control={control} name="TT_ONGOING" options={options_bool} label="TT_ONGOING" defaultValue={def.TT_ONGOING} />
          <MostAutocomplete control={control} name="IMMUNIZATION" options={options_IMMUNIZATION} label="IMMUNIZATION" defaultValue={def.IMMUNIZATION} />

        </div>
    </AccordionDetails>
</Accordion>
        

<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    Chelation
    </AccordionSummary>
    <AccordionDetails>
        <div className="width100">


          <MostAutocomplete control={control} name="ICT_TYPE" options={options_bool} label="ICT_TYPE" defaultValue={def.ICT_TYPE} />
          <MostDatePicker label="ICT_START" value={ICT_START} onChange={(date) => setIct_start(date)} disableFuture={true} openTo="date" />
          <MostAutocomplete control={control} name="ICT_ONGOING" options={options_bool} label="ICT_ONGOING" defaultValue={def.ICT_ONGOING} />
          <MostAutocomplete control={control} name="ICT_IP" options={options_bool} label="ICT_IP" defaultValue={def.ICT_IP} />
          <MostAutocomplete control={control} name="ICT_MODALITY" options={options_ICT_MODALITY} label="ICT_MODALITY" defaultValue={def.ICT_MODALITY} />
          <MostTextField name="ICT_DFO_DOSAGE" label="ICT_DFO_DOSAGE" register={register} inputProps={{type:'number'}} defaultValue={def.ICT_DFO_DOSAGE} />
          <MostTextField name="ICT_DFO_PRESCRIPTION" label="ICT_DFO_PRESCRIPTION" register={register} inputProps={{type:'number'}} defaultValue={def.ICT_DFO_PRESCRIPTION} />
          <MostTextField name="ICT_DFO_COMPLIANCE" label="ICT_DFO_COMPLIANCE" register={register} inputProps={{type:'number'}} defaultValue={def.ICT_DFO_COMPLIANCE} />
          <MostTextField name="ICT_DFP_DOSAGE" label="ICT_DFP_DOSAGE" register={register} inputProps={{type:'number'}} defaultValue={def.ICT_DFP_DOSAGE} />
          <MostTextField name="ICT_DFP_PRESCRIPTION" label="ICT_DFP_PRESCRIPTION" register={register} inputProps={{type:'number'}} defaultValue={def.ICT_DFP_PRESCRIPTION} />
          <MostTextField name="ICT_DFP_COMPLIANCE" label="ICT_DFP_COMPLIANCE" register={register} inputProps={{type:'number'}} defaultValue={def.ICT_DFP_COMPLIANCE} />
          <MostTextField name="ICT_DFX_DOSAGE" label="ICT_DFX_DOSAGE" register={register} inputProps={{type:'number'}} defaultValue={def.ICT_DFX_DOSAGE} />
          <MostTextField name="ICT_DFX_PRESCRIPTION" label="ICT_DFX_PRESCRIPTION" register={register} inputProps={{type:'number'}} defaultValue={def.ICT_DFX_PRESCRIPTION} />
          <MostTextField name="ICT_DFX_COMPLIANCE" label="ICT_DFX_COMPLIANCE" register={register} inputProps={{type:'number'}} defaultValue={def.ICT_DFX_COMPLIANCE} />
          <MostAutocomplete control={control} name="ICT_OTHER_PRODUCT" options={options_ICT_OTHER_PRODUCT} label="ICT_OTHER_PRODUCT" defaultValue={def.ICT_OTHER_PRODUCT} />
          <MostTextField name="ICT_OTHER_DOSAGE" label="ICT_OTHER_DOSAGE" register={register} inputProps={{type:'number'}} defaultValue={def.ICT_OTHER_DOSAGE} />
          <MostTextField name="ICT_OTHER_PRESCRIPTION" label="ICT_OTHER_PRESCRIPTION" register={register} inputProps={{type:'number'}} defaultValue={def.ICT_OTHER_PRESCRIPTION} />
          <MostTextField name="ICT_OTHER_COMPLIANCE" label="ICT_OTHER_COMPLIANCE" register={register} inputProps={{type:'number'}} defaultValue={def.ICT_OTHER_COMPLIANCE} />

        </div>
    </AccordionDetails>
</Accordion>
        

<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    SpecialTreatment
    </AccordionSummary>
    <AccordionDetails>
        <div className="width100">


          <MostAutocomplete control={control} name="HU_TERAPY_TYPE" options={options_HU_TERAPY_TYPE} label="HU_TERAPY_TYPE" defaultValue={def.HU_TERAPY_TYPE} />
          <MostDatePicker label="HU_TERAPY_START" value={HU_TERAPY_START} onChange={(date) => setHu_terapy_start(date)} disableFuture={true} openTo="date" />
          <MostAutocomplete control={control} name="HU_TERAPY_ONGOING" options={options_bool} label="HU_TERAPY_ONGOING" defaultValue={def.HU_TERAPY_ONGOING} />
          <MostTextField name="HU_TERAPY_DOSAGE" label="HU_TERAPY_DOSAGE" register={register} inputProps={{type:'number'}} defaultValue={def.HU_TERAPY_DOSAGE} />
          <MostAutocomplete control={control} name="LUS_TERAPY_TYPE" options={options_LUS_TERAPY_TYPE} label="LUS_TERAPY_TYPE" defaultValue={def.LUS_TERAPY_TYPE} />
          <MostDatePicker label="LUS_TERAPY_START" value={LUS_TERAPY_START} onChange={(date) => setLus_terapy_start(date)} disableFuture={true} openTo="date" />
          <MostAutocomplete control={control} name="LUS_TERAPY_ONGOING" options={options_bool} label="LUS_TERAPY_ONGOING" defaultValue={def.LUS_TERAPY_ONGOING} />
          <MostTextField name="LUS_TERAPY_DOSAGE" label="LUS_TERAPY_DOSAGE" register={register} inputProps={{type:'number'}} defaultValue={def.LUS_TERAPY_DOSAGE} />

        </div>
    </AccordionDetails>
</Accordion>
        

<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    Gene
    </AccordionSummary>
    <AccordionDetails>
        <div className="width100">


          <MostAutocomplete control={control} name="GT" options={options_bool} label="GT" defaultValue={def.GT} />
          <MostDatePicker label="GT_START" value={GT_START} onChange={(date) => setGt_start(date)} disableFuture={true} openTo="date" />
          <MostTextField name="GT_PROTOCOL" label="GT_PROTOCOL" register={register} multiline={true} rows={5} defaultValue={def.GT_PROTOCOL} />
          <MostAutocomplete control={control} name="GT_OUTCOME" options={options_GT_OUTCOME} label="GT_OUTCOME" defaultValue={def.GT_OUTCOME} />
          <MostTextField name="GT_CONDITIONING" label="GT_CONDITIONING" register={register} multiline={true} rows={5} defaultValue={def.GT_CONDITIONING} />
          <MostTextField name="GT_NOTE" label="GT_NOTE" register={register} multiline={true} rows={5} defaultValue={def.GT_NOTE} />

        </div>
    </AccordionDetails>
</Accordion>
        

<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    Laboratory
    </AccordionSummary>
    <AccordionDetails>
        <div className="width100">


          <MostTextField name="HB_LAST" label="HB_LAST" register={register} inputProps={{type:'number'}} defaultValue={def.HB_LAST} />
          <MostTextField name="HB_MEAN" label="HB_MEAN" register={register} inputProps={{type:'number'}} defaultValue={def.HB_MEAN} />
          <MostTextField name="HB_MEDIAN" label="HB_MEDIAN" register={register} inputProps={{type:'number'}} defaultValue={def.HB_MEDIAN} />
          <MostTextField name="HB_MAX" label="HB_MAX" register={register} inputProps={{type:'number'}} defaultValue={def.HB_MAX} />
          <MostTextField name="HB_MIN" label="HB_MIN" register={register} inputProps={{type:'number'}} defaultValue={def.HB_MIN} />
          <MostTextField name="FERRITIN_LAST" label="FERRITIN_LAST" register={register} inputProps={{type:'number'}} defaultValue={def.FERRITIN_LAST} />
          <MostTextField name="FERRITIN_MEAN" label="FERRITIN_MEAN" register={register} inputProps={{type:'number'}} defaultValue={def.FERRITIN_MEAN} />
          <MostTextField name="FERRITIN_MEDIAN" label="FERRITIN_MEDIAN" register={register} inputProps={{type:'number'}} defaultValue={def.FERRITIN_MEDIAN} />
          <MostTextField name="FERRITIN_MAX" label="FERRITIN_MAX" register={register} inputProps={{type:'number'}} defaultValue={def.FERRITIN_MAX} />
          <MostTextField name="FERRITIN_MIN" label="FERRITIN_MIN" register={register} inputProps={{type:'number'}} defaultValue={def.FERRITIN_MIN} />
          <MostTextField name="ALT_LAST" label="ALT_LAST" register={register} inputProps={{type:'number'}} defaultValue={def.ALT_LAST} />
          <MostTextField name="ALT_MEAN" label="ALT_MEAN" register={register} inputProps={{type:'number'}} defaultValue={def.ALT_MEAN} />
          <MostTextField name="ALT_MEDIAN" label="ALT_MEDIAN" register={register} inputProps={{type:'number'}} defaultValue={def.ALT_MEDIAN} />
          <MostTextField name="ALT_MAX" label="ALT_MAX" register={register} inputProps={{type:'number'}} defaultValue={def.ALT_MAX} />
          <MostTextField name="ALT_MIN" label="ALT_MIN" register={register} inputProps={{type:'number'}} defaultValue={def.ALT_MIN} />
          <MostTextField name="AST_LAST" label="AST_LAST" register={register} inputProps={{type:'number'}} defaultValue={def.AST_LAST} />
          <MostTextField name="AST_MEAN" label="AST_MEAN" register={register} inputProps={{type:'number'}} defaultValue={def.AST_MEAN} />
          <MostTextField name="AST_MEDIAN" label="AST_MEDIAN" register={register} inputProps={{type:'number'}} defaultValue={def.AST_MEDIAN} />
          <MostTextField name="AST_MAX" label="AST_MAX" register={register} inputProps={{type:'number'}} defaultValue={def.AST_MAX} />
          <MostTextField name="AST_MIN" label="AST_MIN" register={register} inputProps={{type:'number'}} defaultValue={def.AST_MIN} />
          <MostTextField name="GGT_LAST" label="GGT_LAST" register={register} inputProps={{type:'number'}} defaultValue={def.GGT_LAST} />
          <MostTextField name="GGT_MEAN" label="GGT_MEAN" register={register} inputProps={{type:'number'}} defaultValue={def.GGT_MEAN} />
          <MostTextField name="GGT_MEDIAN" label="GGT_MEDIAN" register={register} inputProps={{type:'number'}} defaultValue={def.GGT_MEDIAN} />
          <MostTextField name="GGT_MAX" label="GGT_MAX" register={register} inputProps={{type:'number'}} defaultValue={def.GGT_MAX} />
          <MostTextField name="GGT_MIN" label="GGT_MIN" register={register} inputProps={{type:'number'}} defaultValue={def.GGT_MIN} />
          <MostTextField name="BILIRUBIN_LAST" label="BILIRUBIN_LAST" register={register} inputProps={{type:'number'}} defaultValue={def.BILIRUBIN_LAST} />
          <MostTextField name="BILIRUBIN_MEAN" label="BILIRUBIN_MEAN" register={register} inputProps={{type:'number'}} defaultValue={def.BILIRUBIN_MEAN} />
          <MostTextField name="BILIRUBIN_MEDIAN" label="BILIRUBIN_MEDIAN" register={register} inputProps={{type:'number'}} defaultValue={def.BILIRUBIN_MEDIAN} />
          <MostTextField name="BILIRUBIN_MIN" label="BILIRUBIN_MIN" register={register} inputProps={{type:'number'}} defaultValue={def.BILIRUBIN_MIN} />
          <MostTextField name="BILIRUBIN_MAX" label="BILIRUBIN_MAX" register={register} inputProps={{type:'number'}} defaultValue={def.BILIRUBIN_MAX} />
          <MostTextField name="CREATININE_LAST" label="CREATININE_LAST" register={register} inputProps={{type:'number'}} defaultValue={def.CREATININE_LAST} />
          <MostTextField name="CREATININE_MEAN" label="CREATININE_MEAN" register={register} inputProps={{type:'number'}} defaultValue={def.CREATININE_MEAN} />
          <MostTextField name="CREATININE_MEDIAN" label="CREATININE_MEDIAN" register={register} inputProps={{type:'number'}} defaultValue={def.CREATININE_MEDIAN} />
          <MostTextField name="CREATININE_MAX" label="CREATININE_MAX" register={register} inputProps={{type:'number'}} defaultValue={def.CREATININE_MAX} />
          <MostTextField name="CREATININE_MIN" label="CREATININE_MIN" register={register} inputProps={{type:'number'}} defaultValue={def.CREATININE_MIN} />
          <MostTextField name="EGFR_LAST" label="EGFR_LAST" register={register} inputProps={{type:'number'}} defaultValue={def.EGFR_LAST} />
          <MostTextField name="EGFR_MEAN" label="EGFR_MEAN" register={register} inputProps={{type:'number'}} defaultValue={def.EGFR_MEAN} />
          <MostTextField name="EGFR_MEDIAN" label="EGFR_MEDIAN" register={register} inputProps={{type:'number'}} defaultValue={def.EGFR_MEDIAN} />
          <MostTextField name="EGFR_MAX" label="EGFR_MAX" register={register} inputProps={{type:'number'}} defaultValue={def.EGFR_MAX} />
          <MostTextField name="EGFR_MIN" label="EGFR_MIN" register={register} inputProps={{type:'number'}} defaultValue={def.EGFR_MIN} />
          <MostTextField name="APP_LAST" label="APP_LAST" register={register} inputProps={{type:'number'}} defaultValue={def.APP_LAST} />
          <MostTextField name="APP_MEAN" label="APP_MEAN" register={register} inputProps={{type:'number'}} defaultValue={def.APP_MEAN} />
          <MostTextField name="APP_MEDIAN" label="APP_MEDIAN" register={register} inputProps={{type:'number'}} defaultValue={def.APP_MEDIAN} />
          <MostTextField name="APP_MAX" label="APP_MAX" register={register} inputProps={{type:'number'}} defaultValue={def.APP_MAX} />
          <MostTextField name="APP_MIN" label="APP_MIN" register={register} inputProps={{type:'number'}} defaultValue={def.APP_MIN} />

        </div>
    </AccordionDetails>
</Accordion>
        

<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    Instrumental
    </AccordionSummary>
    <AccordionDetails>
        <div className="width100">


          <MostTextField name="LIC_LAST" label="LIC_LAST" register={register} inputProps={{type:'number'}} defaultValue={def.LIC_LAST} />
          <MostTextField name="T2_HEART_LAST" label="T2_HEART_LAST" register={register} inputProps={{type:'number'}} defaultValue={def.T2_HEART_LAST} />
          <MostTextField name="T2_LIVER_LAST" label="T2_LIVER_LAST" register={register} inputProps={{type:'number'}} defaultValue={def.T2_LIVER_LAST} />
          <MostDatePicker label="DEXA_DATE" value={DEXA_DATE} onChange={(date) => setDexa_date(date)} disableFuture={true} openTo="date" />
          <MostTextField name="DEXA_T_LOMBAR_SPINE" label="DEXA_T_LOMBAR_SPINE" register={register} inputProps={{type:'number'}} defaultValue={def.DEXA_T_LOMBAR_SPINE} />
          <MostTextField name="DEXA_Z_LOMBAR_SPINE" label="DEXA_Z_LOMBAR_SPINE" register={register} inputProps={{type:'number'}} defaultValue={def.DEXA_Z_LOMBAR_SPINE} />
          <MostTextField name="DEXA_BMD_LOMBAR_SPINE" label="DEXA_BMD_LOMBAR_SPINE" register={register} inputProps={{type:'number'}} defaultValue={def.DEXA_BMD_LOMBAR_SPINE} />
          <MostTextField name="DEXA_T_FEMORAL_NECK" label="DEXA_T_FEMORAL_NECK" register={register} inputProps={{type:'number'}} defaultValue={def.DEXA_T_FEMORAL_NECK} />
          <MostTextField name="DEXA_Z_FEMORAL_NECK" label="DEXA_Z_FEMORAL_NECK" register={register} inputProps={{type:'number'}} defaultValue={def.DEXA_Z_FEMORAL_NECK} />
          <MostTextField name="DEXA_BMD_FEMORAL_NECK" label="DEXA_BMD_FEMORAL_NECK" register={register} inputProps={{type:'number'}} defaultValue={def.DEXA_BMD_FEMORAL_NECK} />
          <MostTextField name="DEXA_T_TOTAL_HIP" label="DEXA_T_TOTAL_HIP" register={register} inputProps={{type:'number'}} defaultValue={def.DEXA_T_TOTAL_HIP} />
          <MostTextField name="DEXA_Z_TOTAL_HIP" label="DEXA_Z_TOTAL_HIP" register={register} inputProps={{type:'number'}} defaultValue={def.DEXA_Z_TOTAL_HIP} />
          <MostTextField name="DEXA_BMD_TOTAL_HIP" label="DEXA_BMD_TOTAL_HIP" register={register} inputProps={{type:'number'}} defaultValue={def.DEXA_BMD_TOTAL_HIP} />
          <MostTextField name="FIBR_STIFFNESS" label="FIBR_STIFFNESS" register={register} inputProps={{type:'number'}} defaultValue={def.FIBR_STIFFNESS} />

        </div>
    </AccordionDetails>
</Accordion>
        

<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    Covid
    </AccordionSummary>
    <AccordionDetails>
        <div className="width100">


          <MostAutocomplete control={control} name="COVID_STATUS" options={options_bool} label="COVID_STATUS" defaultValue={def.COVID_STATUS} />
          <MostAutocomplete control={control} name="COVID_SYMPTOM" options={options_COVID_SYMPTOM} label="COVID_SYMPTOM" defaultValue={def.COVID_SYMPTOM} />
          <MostAutocomplete control={control} name="COVID_DETECTION" options={options_COVID_DETECTION} label="COVID_DETECTION" defaultValue={def.COVID_DETECTION} />
          <MostAutocomplete control={control} name="COVID_SOURCE" options={options_COVID_SOURCE} label="COVID_SOURCE" defaultValue={def.COVID_SOURCE} />
          <MostAutocomplete control={control} name="COVID_HOSPITALIZATION" options={options_COVID_HOSPITALIZATION} label="COVID_HOSPITALIZATION" defaultValue={def.COVID_HOSPITALIZATION} />
          <MostTextField name="COVID_HOSP_DAYS" label="COVID_HOSP_DAYS" register={register} inputProps={{type:'number'}} defaultValue={def.COVID_HOSP_DAYS} />
          <MostTextField name="COVID_DISASE_DAYS" label="COVID_DISASE_DAYS" register={register} inputProps={{type:'number'}} defaultValue={def.COVID_DISASE_DAYS} />
          <MostTextField name="COVID_TERAPY" label="COVID_TERAPY" register={register} multiline={true} rows={5} defaultValue={def.COVID_TERAPY} />
          <MostAutocomplete control={control} name="COVID_OUTCOME" options={options_COVID_OUTCOME} label="COVID_OUTCOME" defaultValue={def.COVID_OUTCOME} />

        </div>
    </AccordionDetails>
</Accordion>
        
        
            {/*
            ==============================
            FINE codice generato da python
            ==============================
            */}
</React.Fragment>
: 
    <div className="blackColor">
        <br />
        <b>NOTA: Il paziente non ha concesso il consenso per il trattamento dei dati medici</b>
    </div>
}
            </form>
            </Container>
        : null }
        </div>
        <Footer />
    </div>
  )
}
