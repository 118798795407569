//import React from 'react';
//import Typography from '@material-ui/core/Typography';
//import Container from '@material-ui/core/Container';
//import Link from '@material-ui/core/Link';
//import useStyles from "./components/useStyles";

/*
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {'Copyright © '}
      <Link color="inherit" href="https://www.site-italia.org" target="_blank" rel="noopener" >
        https://www.site-italia.org
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}
*/

export const Footer = () => {
  return null
  /*
  const classes = useStyles();
  return (
   <div>
    <div className="UnderFooter" />
    <div className={classes.footer}>
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          <Copyright />
        </Container>
      </footer>
    </div>
   </div>
  );
  */
}

