import React, { useState } from "react"
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getLang, getLocale } from "../i18n";
import { get_cookie, delete_cookie } from "../Utils";
import { useForm } from "react-hook-form";
//import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
//import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, { check_response } from "../MyAxios";
import { MostSubmitButton, MostCheckbox, MostTextField, } from "../components/MostComponents";
import useStyles from "../components/useStyles";

export function SignIn(props) {
const history = useHistory();
const { t, i18n } = useTranslation();
const { register, handleSubmit } = useForm();
const classes = useStyles();
const [disabledButs, setDisabledButs] = useState(false)
const { setAlert1, setContent } = useGlobalHook('alertStore');
const { setUserInfo } = useGlobalHook('userStore');
function appAlert(text) {
  setContent(text);
  setAlert1(true);
}

const onSubmit = vals => {
    // cosi' logghiamo password
    //console.log("onSubmit: " + JSON.stringify(vals));
    //alert("onSubmit: " + JSON.stringify(vals));

    const lang = getLang(i18n.language)
    const locale = getLocale(i18n.language)
    let data= {
        username: vals.username,
        password: vals.password,
        lang: lang,
        locale: locale,
    }
    setDisabledButs(true)
    MyAxios.post("login", data)
    .then((response) => {
        const res = check_response(response)
        if (res.success) {
            props.resetIdleTimeout()
            setUserInfo(res.user)
            if(res.user.passwordExpired)
                history.push('/changepasswd');
            else {
                const pathnameOnUnauthAccess = get_cookie("pathnameOnUnauthAccess")
                if(pathnameOnUnauthAccess !== "") {
                    delete_cookie("pathnameOnUnauthAccess","/")
                    history.push(pathnameOnUnauthAccess)
                }
                else
                    history.push('/uhome')
            }
        } else {
            console.log("Error: login: "+res.error);
            appAlert(t("Accesso fallito")+": "+res.error);
            setDisabledButs(false)
        }
    })
    .catch(function (error) {
        // handle error
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
    })
}

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {/*
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <img src={logo} className="xxxxApp-logo" alt="logo" />
        */}
        <Typography component="h1" variant="h5">
          DB-INTHEM
        </Typography>
        <p>{t("Accesso Utenti")}</p>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form} >
            <MostTextField name="username" required={true} label={t("Email")} autoComplete="current-username" type="text" autoFocus={true} register={register} />
            <MostTextField name="password" required={true} label="Password" autoComplete="current-password"  type="password" register={register} />
            <MostCheckbox register={register} name="remember" default={false} label={t("SignIn.Remember me")} />
          <MostSubmitButton disabled={disabledButs} className="makeStyles-submit-4" label={t('SignIn.Sign In')}/>
          <Grid container>
            <Grid item xs>
              <Link to="/forgot_password" variant="body2">
                {t('SignIn.Forgot password?')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
