import React, { useCallback, useState, } from "react";
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, {check_response} from "../MyAxios";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MostDatePicker, options_boolean, MostAutocomplete, MostTextField, } from "../components/MostComponents";

const FiltroFormConditionDialog = (props) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const options_bool = options_boolean(t)
  const {
    control: control2,
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
  } = useForm()

  const s_options_operatore = { }
  s_options_operatore.string = [
    { label:"=",value:"="},
    { label:"<>",value:"<>"},
    { label:"<",value:"<"},
    { label:"<=",value:"<="},
    { label:">",value:">"},
    { label:">=",value:">="},
    { label:"like",value:"like"},
    { label:"not like",value:"not like"},
    { label:"is null",value:"is null"},
    { label:"is not null",value:"is not null"},
  ]
  s_options_operatore.number = [
    { label:"=",value:"="},
    { label:"<>",value:"<>"},
    { label:"<",value:"<"},
    { label:"<=",value:"<="},
    { label:">",value:">"},
    { label:">=",value:">="},
    { label:"is null",value:"is null"},
    { label:"is not null",value:"is not null"},
  ]
  s_options_operatore.date = s_options_operatore.number
  s_options_operatore.list = [
    { label:"=",value:"="},
    { label:"<>",value:"<>"},
    { label:"in",value:"in"},
    { label:"not in",value:"not in"},
    { label:"is null",value:"is null"},
    { label:"is not null",value:"is not null"},
  ]
  s_options_operatore.boolean = [
    { label:"=",value:"="},
    { label:"<>",value:"<>"},
    { label:"is null",value:"is null"},
    { label:"is not null",value:"is not null"},
  ]

  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
  }, [setContent,setAlert1])

  const onChangeCampo = (e,el) => {
    console.log("onChangeCampo",el)
    if(!el) {
        props.setConditionfieldtype(null)
        return
    }
    setLoading(true)
    props.setValore_date(null)
    const jdata = {
        campo: el.value,
    }
    MyAxios.post("/query_field_info", jdata)
    .then((response) => {
      const data = check_response(response);
      if(!data.success) {
        console.error(data.error)
        appAlert(data.error)
        props.setConditionfieldtype(null)
        setLoading(false)
        return
      }
      let type
      if(["float","integer"].includes(data.type))
        type = "number"
      else
        type = data.type
      let op = s_options_operatore[type]
      if (!op)
        op = []
      props.setOperatore(null)
      props.setOptions_operatore(op)
      if(type === 'list')
        props.setOptions_valore(data.options)
      props.setConditionfieldtype(type)
      setLoading(false)
    })
    .catch(function (error) {
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        props.setConditionfieldtype(null)
        setLoading(false)
    })
  }

  console.log("MFiltroFormConditionDialog",props.conditionfieldtype,props.operatore,props.options_valore)
  return (
      <Dialog fullWidth={true} maxWidth="sm" open={props.openDialog} onClose={props.handleCloseDialog} aria-labelledby="form-dialog-title">
      <form key="form2" onSubmit={handleSubmit2(props.handleSubmitDialog)} noValidate>
        <DialogTitle>Nuova condizione</DialogTitle>
        <DialogContent>
          <MostAutocomplete name="campo" control={control2} required={true} rules={{ required: true }}  errors={errors2} options={props.optionsDialog === "personal" ? props.options_personal : props.options_medical} label={t("Campo")+" *"} onChange={onChangeCampo} />
          { loading ?
              <React.Fragment>
                <MostTextField disabled value="" />
                <MostTextField disabled value="" />
              </React.Fragment>
          :
              <React.Fragment>
              <MostAutocomplete name="operatore" control={control2} required={true} rules={{ required: true }}  errors={errors2} options={props.options_operatore} label={t("Operatore")+" *"} onChange={(e,el) => props.setOperatore(el)} />
              { props.conditionfieldtype === "string" ?
                <MostTextField register={register2} name="valore_string" label={t("Valore")} />
              : props.conditionfieldtype === "number" ?
                <MostTextField register={register2} name="valore_number" label={t("Valore")} inputProps={{type:'number'}} />
              : props.conditionfieldtype === "list" ?
                <React.Fragment>
                    <div className={props.operatore && ["in","not in"].includes(props.operatore.value) ? "visible": "hidden"}>
                        <MostAutocomplete multiple name="valore_list_multi" control={control2} options={props.options_valore} label={t("Valore")} />
                    </div>
                    <div className={!(props.operatore && ["in","not in"].includes(props.operatore.value)) ? "visible": "hidden"}>
                        <MostAutocomplete name="valore_list" control={control2} options={props.options_valore} label={t("Valore")} />
                    </div>
                </React.Fragment>
              : props.conditionfieldtype === "date" ?
                <MostDatePicker label={t("Valore")} value={props.valore_date} onChange={(date) => props.setValore_date(date)} disableFuture={false} openTo="date" />
              : props.conditionfieldtype === "boolean" ?
                <MostAutocomplete name="valore_boolean" control={control2} options={options_bool} label={t("Valore")} />
              : 
                <MostTextField disabled value="" />
              }
              <div className="red"> {props.errorsDialog} </div>
              </React.Fragment>
        }
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCloseDialog} variant="outlined" color="secondary">
            {t("Annulla")}
          </Button>
          <Button type="submit" variant="contained" color="primary">
            {t("Aggiungi")}
          </Button>
        </DialogActions>
      </form>
      </Dialog>
    )
}

export const MFiltroFormConditionDialog = React.memo(FiltroFormConditionDialog);
