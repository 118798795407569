import React, { useState, useCallback, useEffect, } from "react";
import { Header } from '../Header';
import { Footer } from '../Footer';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized, } from '../UtilsInthem'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';
import MyAxios, {check_response} from "../MyAxios";
import { options_si_no, MostRadioGroup, MostButton, Loading, } from "../components/MostComponents";
import { useHistory } from "react-router-dom";

export const PStudio = (props) => {
  const history = useHistory();
  const { t, } = useTranslation();
  const { userInfo } = useGlobalHook('userStore');
  let initialTab = 0
  const [tabValue, setTabValue] = useState(initialTab)
  const [tab, setTab] = useState("tab"+initialTab)
  const [row, setRow] = useState(null)
  const [consenso, setConsenso] = useState(null)
  const [disabledButs, setDisabledButs] = useState(false)
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
  }, [setContent,setAlert1])
  const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
  function appAlert2(text,closeHandler) {
      setContent2(text)
      setOnClose2(() => x => {
        closeHandler()
      });
      setAlert2(true)
  }

  const studioId = props.match.params.studioId

  const changeTab = (tabNumber,fromPreSubmit) => {
    setTabValue(tabNumber)
    setTab("tab"+tabNumber)
  }
  const handleTabChange = (e,tabNumber) => {
    changeTab(tabNumber)
  }

  const toTab1 = () => {
    changeTab(1)
  }

  useEffect(() => {
    const jdata = {
        action: 'LIST',
    }
    MyAxios.post("/clinical_study", jdata)
    .then((response) => {
      const data = check_response(response);
      if(!data.success) {
        console.error(data.error)
        appAlert(data.error)
        return
      }
      for (let i in data.rows) {
        if(data.rows[i].id === studioId) {
          setRow(data.rows[i])
          break
        }
      }
    })
    .catch(function (error) {
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    })
  }, [appAlert,studioId,userInfo])

  const consensoOnChange = (e) => {
    setConsenso(e.target.value)
  }

  const submit = () => {
    if(consenso === null) {
        appAlert("Inserisci la risposta")
        return
    }
    if(needConsentsUpdate && consenso === "1") {
        appAlert("Non è possibile partecipare senza fornire i consensi richiesti")
        return
    }
    setDisabledButs(true)
    const jdata = {
        "StudyCode": studioId,
        "status": consenso === "1" ? "ConsensoSI" : "ConsensoNO"
    }
    MyAxios.post("/patient/JoinStudy", jdata)
    .then((response) => {
        setDisabledButs(false)
        const res = check_response(response)
        if (res.success) {
            appAlert2(t("Grazie. La tua risposta è stata registrata"),() => {
                history.push("/myconsent")
            })
        } else {
            console.error("Error: "+res.error);
            appAlert(res.error);
        }
    })
    .catch(function (error) {
        // handle error
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
    })
  }

  const redirect = authorized("patient",userInfo)
  if(redirect)
    return redirect

  if(!row)
    return <Loading />

  let needConsentsUpdate = false
  if(!userInfo.c_db_m
  || (!userInfo.c_extraeu && row.clinical_study_type_detail.extraeu)
  || (!userInfo.c_f && row.clinical_study_type_detail.f)
  || (!userInfo.c_p_db && row.clinical_study_type_detail.p_db))
    needConsentsUpdate = true

  const defaultValue = null

  return (
  <div>
  <Header title={t("Studio")+" "+studioId} backMode="/myconsent" />
  <div className="content-container contentWithToolbar">
  <Container component="main" maxWidth="md">
    <AppBar position="absolute">
        <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Informazioni" id="tab-0" />
            <Tab label="Consenso allo studio" id="tab-1" />
        </Tabs>
    </AppBar>
    { tab === "tab0" ?
        <div className="blackColor">
        <h2> </h2>
        <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs={4}> Codice </Grid> <Grid item xs={8}> {row.id} </Grid>
            <Grid item xs={4}> Titolo </Grid> <Grid item xs={8}> {row.title} </Grid>
            <Grid item xs={4}> Descrizione </Grid> <Grid item xs={8}> {row.description} </Grid>
            { row.video_url ?
                <React.Fragment>
                <Grid item xs={4}> Video presentazione</Grid> <Grid item xs={8}> <a href={row.video_url} target="_blank" rel="noreferrer">{row.video_url} xxx</a> </Grid>
                </React.Fragment>
            : null }
            { row.clinical_study_type_detail.extraeu || row.clinical_study_type_detail.f || row.clinical_study_type_detail.p_db ?
                <React.Fragment>
                <Grid item xs={4}> Autorizzazioni richieste </Grid> 
                <Grid item xs={8}>
                    <ul className="ulInGrid">
                    <li> trasferimento dati Medici dal tuo Centro di riferimento al DB-Inthem </li>
                    <li> utilizzo dei dati per fare ricerche sulla tua patologia </li>
                    { row.clinical_study_type_detail.extraeu ?
                        <li> trasferimento dati verso Enti Extra UE </li>
                    : null }
                    { row.clinical_study_type_detail.p_db ?
                        <li> trasferimento dati verso DataBase Pubblici </li>
                    : null }
                    { row.clinical_study_type_detail.f ?
                        <li> trasferimento dati verso Case Farmaceutiche </li>
                    : null }
                    </ul>
                </Grid>
                </React.Fragment>
            : null }
            { needConsentsUpdate ?
                <React.Fragment>
                <Grid item xs={12} className="important">
                    <p>
                    I consensi che ci hai dato precedentemente
                    non ti permettono al momento di partecipare
                    a questo studio.
                    </p>
                    <p>
                    Per partecipare dovresti autorizzare:
                    <ul className="ulInGrid">
                    { !userInfo.c_db_m ?
                        <li> trasferimento dati Medici dal tuo Centro di riferimento al DB-Inthem (punto 2 dei consensi)</li>
                    : null }
                    { !userInfo.c_s ?
                        <li> utilizzo dei dati per fare ricerche sulla tua patologia (punto 3 dei consensi)</li>
                    : null }
                    { !userInfo.c_extraeu && row.clinical_study_type_detail.extraeu ?
                        <li> trasferimento dati verso Enti Extra UE (punto 5 dei consensi)</li>
                    : null }
                    { !userInfo.c_p_db && row.clinical_study_type_detail.p_db ?
                        <li> trasferimento dati verso DataBase Pubblici (punto 6 dei consensi)</li>
                    : null }
                    { !userInfo.c_f && row.clinical_study_type_detail.f ?
                        <li> trasferimento dati verso Case Farmaceutiche (punto 7 dei consensi)</li>
                    : null }
                    </ul>
                    </p>
                </Grid>
                <Grid item xs={6}>
                    <MostButton className="b_loginok" onClick={() => history.push("/consenso")} label={t("Modifica i tuoi consensi")} id="consenso"/>
                </Grid>
                <Grid item xs={6}>
                    <MostButton className="b_loginok" onClick={toTab1} label={t("Decidi di non partecipare")} id="nopart"/>
                </Grid>
                </React.Fragment>
            :
                <React.Fragment>
                <Grid item xs={12} className="important">
                    I consensi che ci hai dato precedentemente
                    ti permettono di partecipare a questo studio
                </Grid>
                <Grid item xs={12}>
                    <MostButton className="b_loginok" onClick={toTab1} label={t("Decidi se partecipare allo studio")} id="decidi"/>
                </Grid>
                </React.Fragment>
            }
        </Grid>
        </div>
    :
        <div>
            <h2> Partecipazione allo studio {row.title} </h2>
            <div className="blackColor">
            <Grid container spacing={1} alignItems="flex-start">
            { 0 || needConsentsUpdate ?
                <Grid item xs={12} className="important">
                    NOTA: I consensi che ci hai dato precedentemente
                    non ti permettono di partecipare a questo studio
                </Grid>
            : null }
                <Grid item xs={12}>
                <b>Acconsenti a partecipare allo studio?</b>
                <MostRadioGroup
                    name={"consenso"}
                    options={options_si_no(t)}
                    defaultValue={defaultValue}
                    value={consenso}
                    onChange={consensoOnChange}
                  />
                </Grid>
                <Grid item xs={12}>
                    <MostButton disabled={disabledButs} className="b_loginok" label={t("Conferma")} onClick={submit} id="conferma"/>
                </Grid>
            </Grid>
            </div>
        </div>
    }

  </Container>
  </div>
  <Footer />
  </div>
)
}

