import React, { useState, useEffect, useCallback, } from "react";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useTranslation } from 'react-i18next';
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized } from '../UtilsInthem'
//import MyAxios from "../MyAxios";
import Grid from "@material-ui/core/Grid";
import { useForm, } from "react-hook-form";
import { MostRadioGroup, MostSubmitButton, MostButton2, Loading, MostAutocomplete, } from "../components/MostComponents";
import MyAxios, {check_response} from "../MyAxios";
import { useHistory } from "react-router-dom";

export const Sf36 = () => {
    const { userInfo } = useGlobalHook('userStore');
    const { t, } = useTranslation();
    //const [disabledButs, setDisabledButs] = useState(false)
    const disabledButs = false
    const history = useHistory();
    const { control, handleSubmit, errors, } = useForm();
    const [modello, setModello] = useState(null)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
        setContent(text);
        setAlert1(true);
    }, [setContent,setAlert1])

    useEffect(() => {
        const jdata = {
        }
        MyAxios.post("/patient/GetSF36", jdata)
        .then((response) => {
            const data = check_response(response);
            if(!data.success) {
                console.error(data.error)
                appAlert(data.error)
                return
            }
            setModello(data.sf36.points)
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,])

    const onSubmit = (vals) => {
        console.log(vals)
        const ret = []
        for(let i in modello) {
            if(modello[i].tipo === "radio") {
                console.log('radio')
                ret.push(parseInt(vals['point_'+i]))
            } else {
                console.log('multi')
                const p = []
                for(let j in modello[i].domande) {
                    p.push(vals['point_'+i+'_'+j].value)
                }
                ret.push(p)
            }
        }
        console.log(ret);
        MyAxios.post("/patient/NewSF36", ret)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            console.error(data.error)
            appAlert(data.error)
            return
          }
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
        appAlert(JSON.stringify(ret))
    }

    const redirect = authorized("patient",userInfo)
    if(redirect)
        return redirect

    if(!modello)
        return <Loading />

    // https://www.pluralsight.com/guides/how-to-implement-a-component-%22loop%22-with-react
    return (
  <div>
    <Header title="Sf36" />
    <Container component="main" maxWidth="md">
        <div className="blackColor">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ol className="modulo">
        {modello.map((item,itemIndex)=>{
            return (
                <li key={"li"+itemIndex}>
                    <div dangerouslySetInnerHTML={{__html: "<b>"+item.testo+"</b>"}} />
                    <div><br /><i>({item.help})</i></div><br />
                    {item.tipo === "radio"?
                          <MostRadioGroup
                            control={control}
                            rules={{ required: true }}
                            name={"point_"+itemIndex}
                            options={item.risposte}
                            errors={errors}
                          />
                    :
                        <Grid container spacing={1} alignItems="flex-start" className="blackColor">
                        {item.domande.map((domanda,domandaIndex)=>{
                            return (
                              <React.Fragment key={"RF"+domandaIndex}>
                                <Grid item xs={6} className="margint12">
                                    {domanda}
                                </Grid>
                                <Grid item xs={6}>
                                    <MostAutocomplete name={"point_"+itemIndex+"_"+domandaIndex} control={control} options={item.risposte} required={true} rules={{ required: true }} defaultValue={null} errors={errors} />
                                </Grid>
                              </React.Fragment>
                            )
                        })}
                        </Grid>
                    }
                </li>
            )
        })}
        </ol>
        <Grid container spacing={1} alignItems="flex-start" className="blackColor">
            <Grid item xs={8}>
                <MostSubmitButton disabled={disabledButs} label={t("Conferma")} id="Conferma" />
            </Grid>
            <Grid item xs={4}>
                <MostButton2 fullWidth disabled={disabledButs} onClick={() => history.push("/myconsent")} label={t("Indietro")} className="margin2416" id="Back" />

            </Grid>
        </Grid>
        </form>
        </div>
    </Container>
    <Footer />
  </div>
    );
};
