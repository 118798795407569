import React, { useState } from 'react';
import { Header } from '../Header';
import { Link } from "react-router-dom";
import { Footer } from '../Footer';
import { useForm } from "react-hook-form";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useTranslation } from "react-i18next";
import Container from '@material-ui/core/Container';
import MyAxios, { check_response } from "../MyAxios";
import { MostSubmitButton, MostTextField, } from "../components/MostComponents";
import useStyles from "../components/useStyles";

export function ForgottenPasswd() {
const { t } = useTranslation();
const { register, handleSubmit } = useForm();
const classes = useStyles();
const [disabledButs, setDisabledButs] = useState(false)
const { setAlert1, setContent } = useGlobalHook('alertStore');
function appAlert(text) {
  setContent(text);
  setAlert1(true);
}

const onSubmit = vals => {
    let data= {
        username: vals.username
    }
    setDisabledButs(true)
    MyAxios.post("forgottenpassword", data)
    .then((response) => {
        const res = check_response(response)
        if (res.success) {
            appAlert(t("E' stata inviata una nuova password al tuo indirizzo di posta elettronica"))
        } else {
            console.error("Error: "+res.error);
            appAlert(res.error);
            setDisabledButs(false)
        }
    })
    .catch(function (error) {
        // handle error
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
    })
}

  return (
  <div>
    <Header empty={true} />
    <Container component="main" maxWidth="xs">
      <h1>{t("Password Dimenticata")}</h1>
      <div className={classes.paper}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form} >
          <MostTextField name="username" required={true} label={t("Email")} register={register} />
          <MostSubmitButton disabled={disabledButs} className="makeStyles-submit-4" label={t('Richiedi una nuova password')}/>
          <Link to="/login" variant="body2">
            {t("Accedi")}
          </Link>
        </form>
      </div>
    </Container>
    <Footer />
  </div>
  );
}
