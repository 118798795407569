import React, { useState, } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from 'react-i18next';
import Popper from "@material-ui/core/Popper";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import { MostButton, } from "../components/MostComponents";
import { now, } from "../Utils";

export const EcrfPazienteDiv = (props) => {
    const [listPatientKey, setListPatientKey] = useState("1")
    //const { login1 } = useGlobalHook('loginStore');
    const { t, } = useTranslation();

    const cambioPaziente = (e,el) => {
        props.apriPaziente(el)
        // per resettare autocomplete 
        setListPatientKey(now(4))
    }

    function cognomeNome (r) {
        let cognomenome = ''
        if(r.SURNAME)
            cognomenome = r.SURNAME
        if(r.NAME)
            cognomenome += " " + r.NAME
        return cognomenome
    }

    const PatientDetail = (props) => {
        return <div className="patientDetailDiv">{t("Paziente")}: <span className="patientCode">{props.paziente.CENTER_ID}</span> <span className="patientInfo">{cognomeNome(props.paziente)}</span></div>
    }

  const getOptionLabel = (option) => option && option.label ? option.label : ""
  const getOptionSelected=(option, value) => {
        if (!value || value.value === "")
          return true
        if (value.value === option.value)
          return true
      }


  // google: mui autocomplete popper style
  // https://smartdevpreneur.com/customizing-the-material-ui-autocomplete-component/
  // https://codesandbox.io/s/material-ui-autosuggest-epuj4?file=/demo.tsx:281-632
  const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      zIndex: 2000,
      "& .MuiAutocomplete-listbox": {
        //border: "2px solid grey",
        //minHeight: 400,
        //color: "green",
        fontSize: 12,
        //"& li:nth-child(even)": { backgroundColor: "#CCC" },
        //"& li:nth-child(odd)": { backgroundColor: "#FFF" }
      }
    },
    inputLabel: {
        fontSize: 12,
        fontStyle: "italic",
    }
  })
);

  const classes = useStyles();
  const CustomPopper = function (props) {
    return <Popper {...props} className={classes.root} placement="bottom" />;
  }
  //const label = t("inserire_codice_paziente")
  // incredibilmente questo campo viene compilato da chrome con lo username se viene mostrato il dialog di login, bisogna disabilitarlo... boh
  let pazienteDisabled = false
  /*
  if(login1)
    pazienteDisabled = true
  */

    if(false)
        return <div> work in progress </div>

    return (
      <div className="pazienteDiv">
        <Autocomplete
          name="paziente"
          disabled={pazienteDisabled}
          key={listPatientKey}
          options={props.options_patient}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          style={{ width: 300 }} 
          className="pazienteAutocomplete"
          defaultValue={null}
          onChange={cambioPaziente}
          renderInput={(params) => (
            <TextField {...params} id="pull_down_pazienti" margin="dense" variant="outlined" label={t("inserire_codice_paziente")} InputLabelProps={{className: classes.inputLabel}} />
          )}
          renderOption={(option) => {
            return <div><span className="listPatientCode">{`${option.row.CENTER_ID}`}</span> <span className="listPatientInfo">{cognomeNome(option.row)}</span></div>
          }}
          PopperComponent={CustomPopper}
        />
        {props.paziente != null ?
            <React.Fragment>
                <div className="closeDiv">
                    <MostButton
                        label={t("Chiudi cartella")}
                        className="chiudiCartella font10"
                        onClick={props.chiudiPaziente} /> 
                </div>
                <PatientDetail paziente={props.paziente.row} />
            </React.Fragment>
        : null }
        <div className="nuovoPazienteDiv">
            <MostButton
                id="nuovo_paziente"
                label={t("Nuovo paziente")}
                className="chiudiCartella font10"
                variant="contained" color="primary"
                onClick={props.nuovoPaziente} /> 
        </div>
      </div>
    )
}
