import React, { useState} from 'react';
import { Link } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import useStyles from "./components/useStyles";
import HelpDialog from "./components/HelpDialog";
import Version from "./components/Version";
import LanguageIcon from '@material-ui/icons/Language';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { hasRole, isPatient } from './UtilsInthem'

export function Header(props) {
  const { userInfo } = useGlobalHook('userStore');
  const { t, i18n } = useTranslation();
  const [langDialog, setLangDialog] = useState(false);

  function linguaWin() {
    setLangDialog(true);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const classes = useStyles();

  let emptyMode = false
  if(props.empty || (props.ChangePasswdMode && userInfo.passwordExpired)) {
    emptyMode = true
  }

  //console.log("userInfo",userInfo);
  return (
    <div className="Header">
      <AppBar position="fixed" color="inherit" className="Header">
        <Toolbar>
        { props.backMode ?  (
          <Tooltip title={t("Indietro")}>
            <Link to={props.backMode}>
                <IconButton
                    className={classes.menuButton}
                    id="back"
                    edge="start"
                    color="inherit"
                >
                    <ArrowBackIcon />
                </IconButton>
            </Link>
          </Tooltip>
        ) : emptyMode ?  (
          null
        ) : (
          <Tooltip title={t("menu")}>
            <IconButton 
              className={classes.menuButton}
              id="hamburger"
              edge="start"
              aria-label="menu"
              aria-controls="user-menu"
              aria-haspopup="true"
              onClick={handleClick}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
        )}
        { emptyMode ?
          <Tooltip title={t("Indietro")}>
            <Link to="/" className="nodecoration">DB-INTHEM</Link>
          </Tooltip>
        : isPatient(userInfo) ?
            null
        :
          <Tooltip title={t("Pagina principale")}>
            <Link to="/uhome" className="nodecoration">DB-INTHEM</Link>
          </Tooltip>
        }
          <div className={classes.grow+" tcenter"}>
            { props.title ? 
                <h1>{props.title+" "}
                    { props.subtitle ? 
                        <span className="piccolo">{props.subtitle}</span>
                    : null }
                </h1>
            : null }
          </div>
          <Version />
          <HelpDialog />
          { emptyMode ? 
              <Tooltip title={t("Lingua")}>
                <IconButton
                    onClick={linguaWin}
                    color="inherit"
                >
                    <LanguageIcon />
                </IconButton>
              </Tooltip>
          : 
              <div className={classes.sectionUser}>
                <Tooltip title={userInfo.name+" "+userInfo.surname}>
                  <IconButton
                    edge="end"
                    id="user_pulldown"
                    aria-label={t("account of current user")}
                    aria-controls="user-menu"
                    aria-haspopup="true"
                    onClick={handleClick1}
                    color="inherit"
                  >
                    <AccountCircleIcon />
                  </IconButton>
                </Tooltip>
              </div>
          }
        </Toolbar>
      </AppBar>
      <Toolbar />
<Menu
  id="left-menu"
  style={{ marginTop: '50px' }}
  anchorEl={anchorEl}
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleClose}
>
  <MenuItem component={Link} to={isPatient(userInfo) ? "/myconsent" : "/uhome"} onClick={handleClose}>{t("Pagina principale")}</MenuItem>
  { isPatient(userInfo) ?
    [
    <MenuItem key="consenso" component={Link} to='/consenso' onClick={handleClose}>{t("I tuoi consensi")}</MenuItem>,
    ]
  : null
  }
  { hasRole("GlobalAdmin",userInfo) ? 
    [
    <MenuItem key="globalconfig" component={Link} to='/globalconfig' onClick={handleClose}>{t("Configurazione globale")}</MenuItem>
    ,
    <MenuItem key="log" component={Link} to='/log' onClick={handleClose}>{t("Log")}</MenuItem>
    ]
  : null 
  }
  { hasRole("UserAdmin",userInfo) ? 
    [
    <MenuItem key="centri" component={Link} to='/centri' onClick={handleClose}>{t("Gestione centri")}</MenuItem>
    ,
    <MenuItem key="utenti" component={Link} to='/utenti' onClick={handleClose}>{t("Gestione utenti")}</MenuItem>
    ]
  : null 
  }
</Menu>
<Menu
  id="user-menu"
  style={{ marginTop: '50px' }}
  anchorEl={anchorEl1}
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  keepMounted
  open={Boolean(anchorEl1)}
  onClose={handleClose1}
>
  <MenuItem key="username" disabled>{userInfo.name + " " + userInfo.surname}</MenuItem>
  {isPatient(userInfo) ? 
    <MenuItem key="pdati" component={Link} to='/pdati' onClick={handleClose1}>{t("I tuoi dati")}</MenuItem>
  :
    <MenuItem disabled key="identityM" component={Link} to='/identityM' onClick={handleClose1}>{t("I tuoi dati")}</MenuItem>
  }
  <MenuItem id="changepasswd" key="changepasswd" component={Link} to='/changepasswd' onClick={handleClose1}>{t("Cambio password")}</MenuItem>
  <MenuItem id="logout" key="logout" component={Link} to='/logout' onClick={handleClose1}>{t("Logout")}</MenuItem>
</Menu>
<Dialog
  open={langDialog}
  onClose={() => setLangDialog(false)}
  aria-describedby="lang-alert-dialog-description"
>
  <DialogContent>
    <DialogContentText id="lang-alert-dialog-description">
      {t("Scegli la lingua")}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    {/* configurare supportedLngs in i18n.js */}
    <Button onClick={() => {
        setLangDialog(false);
        i18n.changeLanguage('it');
    }} color="primary" autoFocus>
      <img src="https://flagcdn.com/h24/it.png" height="24" width="48" alt="Italiano" title="Italiano" />
    </Button>
    <Button onClick={() => {
        setLangDialog(false);
        i18n.changeLanguage('en');
    }} color="primary" autoFocus>
      <img src="https://flagcdn.com/h24/gb.png" height="24" width="48" alt="English" title="English" />
    </Button>
  </DialogActions>
</Dialog>
    </div>
  );
}
