
import { now, } from './Utils'
import { getLang } from './i18n'
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { app_be_url } from "./MyAxios";

export const hasRole = (role,userInfo) => {
    for (let i in userInfo.roles)
        if(userInfo.roles[i].role === role)
            return userInfo.roles[i]
    //console.log("not hasRole "+role)
    return null
}

export const getCenterAdmin = (userInfo) => {
    for (let i in userInfo.roles)
        if(userInfo.roles[i].role === "CenterAdmin")
            return userInfo.roles[i].center
    return ""
}

export const isCenterAdmin = (center,userInfo) => {
    for (let i in userInfo.roles)
        if(userInfo.roles[i].role === "CenterAdmin" && userInfo.roles[i].center === center)
            return true
    return false
}

export const isPatient = (userInfo) => {
    if (userInfo.mode === "patient")
        return true
    return false
}

export const authorized = (mode,userInfo) => {
    // patient
    if (isPatient(userInfo)) {
        if(mode === "patient") {
            return null
        }
        console.error("authorized: isPatient and mode "+mode)
        return (<Redirect to='/myconsent' />)
    }
    // user
    const redirect = (<Redirect to='/uhome' />)
    if(mode === "patient") {
        console.error("authorized: not isPatient and mode patient")
        return redirect
    }
    if(mode === "UserAdmin") {
        if (!hasRole("UserAdmin",userInfo)) {
            console.error("not hasRole UserAdmin")
            return redirect
        }
        return  null;
    }
    if(mode === "GlobalAdmin") {
        if (!hasRole("GlobalAdmin",userInfo)) {
            console.error("not hasRole GlobalAdmin")
            return redirect
        }
        return  null;
    }
    if(mode === "CenterAdmin") {
        if (!hasRole("CenterAdmin",userInfo)) {
            console.error("not hasRole CenterAdmin")
            return redirect
        }
        return  null;
    }
    // mode === "user"
    return null
}

export const options_formatofile = [
        {value: "CSV", label: "CSV"},
        {value: "JSON", label: "JSON"},
        {value: "XLSX", label: "XLSX"},
        {value: "XML", label: "XML"},
    ]

export const options_formatofile_download = [
        {value: "CSV", label: "CSV"},
        {value: "JSON", label: "JSON"},
        {value: "XLSX", label: "XLSX"},
        {value: "XML", label: "XML"},
        {value: "video", label: "visualizzazione a video"},
    ]

export const options_tabelleservizio = [
        {value: "chelating_drug", label: "chelating_drug", missingId: 1, columns: [
            { field: 'code', headerName: 'Codice', },
            { field: 'name', headerName: 'Nome', },
            { field: 'active_principle', headerName: 'Principio attivo', width: 200 },
            { field: 'approved', headerName: 'Approvato', width: 200 },
            { field: 'mode', headerName: 'Modalità', width: 200 },
        ]},
        {value: "geotree", label: "geotree", columns: [
            { field: 'id', headerName: 'Codice', width: 200 },
            { field: 'description', headerName: 'Descrizione', width: 200 },
            { field: 'level', headerName: 'Livello', },
            { field: 'leaf', headerName: 'Foglia', },
            { field: 'code', headerName: 'Codice breve', width: 200 },
        ]},
        {value: "icd9", label: "icd9", missingId: 1, columns: [
            { field: 'code', headerName: 'Codice', width: 120 },
            { field: 'description_it', headerName: 'Italiano', width: 300 },
            { field: 'description_en', headerName: 'Inglese', width: 300 },
            { field: 'description_fr', headerName: 'Francese', width: 120 },
            { field: 'description_pt', headerName: 'Portoghese', width: 120 },
            { field: 'webthal_suggested', headerName: 'Webthal', width: 120 },
            { field: 'category', headerName: 'Categoria', width: 200 },
        ]},
        {value: "specific_diagnosis", label: "specific_diagnosis", missingId: 1, columns: [
            { field: 'code', headerName: 'Codice', },
            { field: 'cat', headerName: 'Categoria', },
            { field: 'icd', headerName: 'ICD', },
            { field: 'description_icd', headerName: 'Descrizione ICD', width: 200 },
            { field: 'db', headerName: 'DB', },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_blood_group", label: "svc_blood_group", columns: [
            { field: 'id', headerName: 'Codice', width: 200 },
        ]},
            // campo id non presente -> missingId: 1 + uso di dataGridMakeIdUnique()
        {value: "svc_clinical_study", label: "svc_clinical_study", missingId: 1, columns:[
            { field: 'study_key', headerName: 'study_key' },
            { field: 'column_name', headerName: 'column_name', width: 200 },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_clinical_study_patient_status", label: "svc_clinical_study_patient_status", columns:[
            { field: 'id', headerName: 'Codice', width: 200 },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_clinical_study_status", label: "svc_clinical_study_status", columns:[
            { field: 'id', headerName: 'Codice' },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_clinical_study_type", label: "svc_clinical_study_type", columns:[
            { field: 'id', headerName: 'Codice' },
            { field: 'name', headerName: 'Nome', width: 200 },
            { field: 'extraeu', headerName: 'Extra UE', width: 200 },
            { field: 'p_db', headerName: 'DB' },
            { field: 'f', headerName: 'Case farmaceutiche', width: 200 },
        ]},
        {value: "svc_covid_detection", label: "svc_covid_detection", columns:[
            { field: 'id', headerName: 'Codice' },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_covid_hospitalization", label: "svc_covid_hospitalization", columns:[
            { field: 'id', headerName: 'Codice' },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_covid_outcome", label: "svc_covid_outcome", columns:[
            { field: 'id', headerName: 'Codice' },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_covid_source", label: "svc_covid_source", columns:[
            { field: 'id', headerName: 'Codice' },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_covid_symptom", label: "svc_covid_symptom", columns:[
            { field: 'id', headerName: 'Codice', width: 200 },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_drug_administering", label: "svc_drug_administering", columns:[
            { field: 'id', headerName: 'Codice', width: 200 },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_ecrf", label: "svc_ecrf", columns:[
            { field: 'id', headerName: 'Codice', width: 200 },
            { field: 'description', headerName: 'Descrizione', width: 200 },
        ]},
        {value: "svc_fenotype", label: "svc_fenotype", columns:[
            { field: 'id', headerName: 'Codice', width: 200 },
        ]},
        {value: "svc_genotype", label: "svc_genotype", columns:[
            { field: 'id', headerName: 'Codice', width: 200 },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_ict_modality", label: "svc_ict_modality", columns:[
            { field: 'id', headerName: 'Codice', width: 200 },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_immunization", label: "svc_immunization", columns:[
            { field: 'id', headerName: 'Codice', width: 200 },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_msg_type", label: "svc_msg_type", columns:[
            { field: 'id', headerName: 'Codice', width: 200 },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_nation", label: "svc_nation", columns:[
            { field: 'id', headerName: 'Codice' },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_patient_status", label: "svc_patient_status", columns:[
            { field: 'id', headerName: 'Codice', width: 200 },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
            // chiave id + nation -> idIsNotKey: 1 + uso di dataGridMakeIdUnique()
        {value: "svc_region", label: "svc_region", idIsNotKey: 1, columns:[
            { field: 'REAL_ID', headerName: 'Codice' },
            { field: 'nation', headerName: 'Nazione', width: 200 },
            { field: 'name', headerName: 'Nome', width: 200 },
            { field: 'areaid', headerName: 'Area' },
        ]},
        {value: "svc_sex", label: "svc_sex", columns:[
            { field: 'id', headerName: 'Codice', width: 200 },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_therapy_type", label: "svc_therapy_type", columns:[
            { field: 'id', headerName: 'Codice', width: 200 },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_tt", label: "svc_tt", columns:[
            { field: 'id', headerName: 'Codice', width: 200 },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
        {value: "svc_tt_cause", label: "svc_tt_cause", columns:[
            { field: 'id', headerName: 'Codice', width: 200 },
            { field: 'trans_it', headerName: 'Italiano', width: 200 },
            { field: 'trans_en', headerName: 'Inglese', width: 200 },
            { field: 'trans_fr', headerName: 'Francese', width: 200 },
            { field: 'trans_pt', headerName: 'Portoghese', width: 200 },
        ]},
    ]

export const options_azioni = [
        {value: "APPEND", label: "Aggiungi record"},
        {value: "CHANGE", label: "Modifica i record"},
        {value: "REPLACE", label: "Azzera tabella e inserisci record"},
    ]

export const tableLangColumn = (colname,i18nLanguage) => {
  const lang = getLang(i18nLanguage)
  let column = colname+"_it"
  if (lang === "en")
    column = colname+"_en"
  else if (lang === "fr")
    column = colname+"_fr"
  else if (lang === "pt")
    column = colname+"_pt"
  return column
}

export const svcTableLangColumn = (i18nLanguage) => {
  return tableLangColumn("trans",i18nLanguage)
}

export const getPdf = (doc,i18n) => {
    const url = app_be_url+"stampa_paziente/db-inthem."+doc+"."+now(3)+".pdf?doc="+doc+"&lang="+getLang(i18n.language)
    const winname = "inthem_paziente_"+doc
    window.open(url,winname)
}

export const Role = (props) => {
    const { t } = useTranslation()
    const str = roleString(props.mode,t)
    return (
        <span className="piccolo">{str}</span>
    )
}

export const roleString = (mode,t) => {
    const role = mode.substring(0,2).toUpperCase()
    return "("+t("ruolo")+" "+role+")"
}

