import React, { useEffect, useState, useCallback, } from "react";
import { useHistory } from "react-router-dom";
import { Header } from '../Header';
import Container from "@material-ui/core/Container";
import { Footer } from '../Footer';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { options_formatofile_download, authorized } from '../UtilsInthem'
import { ymd } from '../Utils'
import MyAxios, {check_and_download,check_response} from "../MyAxios";
import { useForm } from "react-hook-form";
import { DataTable, dmyhms_date_formatter, TooltipLongText } from '../components/DataTable';
import Grid from "@material-ui/core/Grid";
import { MostTextField, MostDatePicker, getOptionListElement, getOptionList, Loading, MostAutocomplete, MostButton2, MostSubmitButton } from "../components/MostComponents";

const columns = [
    { field: 'timestamp', headerName: 'Data', width: 160, valueFormatter: dmyhms_date_formatter },
    { field: 'username', headerName: 'Utente', width: 200 },
    { field: 'command', headerName: 'Comando', width: 200 },
    { field: 'action', headerName: 'Action', width: 120 },
    { field: 'from_host', headerName: 'Host', width: 120 },
    { field: 'duration_milli', headerName: 'ms', },
    { field: 'detail', headerName: 'Dettaglio', minWidth: 200, flex: 1, renderCell: (params: GridCellParams) => (
            <TooltipLongText text={params.value ? JSON.stringify(params.value) : ""} />
      )},
]
const options_limit = [
    {label: "10", value: "10"},
    {label: "100", value: "100"},
    {label: "1000", value: "1000"},
]
const def_options_limit = options_limit[0]
const def_options_formatofile_download = options_formatofile_download[4]

export const Log = () => {
    const [loading, setLoading] = useState(true)
    const [options_command, setOptions_command] = useState([])
    const [options_action, setOptions_action] = useState([])
    const [def_options_command, setDef_options_command] = useState([])
    const [showdatagrid, setShowdatagrid] = useState(true)
    const [options_username, setOptions_username] = useState([])
    const [rows, setRows] = useState([])
    const [data_dal, setData_dal] = useState(null)
    const [data_al, setData_al] = useState(null)
    const { userInfo } = useGlobalHook('userStore');
    const { register, control, handleSubmit, errors, } = useForm();
    const { t } = useTranslation();
    const history = useHistory();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    useEffect(() => {
        const jdata =  {
          command: "login",
          inline: true,
          limit: 10, 
          sort_col: "timestamp", 
          sort_direction: "DESC", 
        }
        const url = "/admin/log_query"
        MyAxios.post(url, jdata)
        .then((response) => {
            const data = check_response(response);
            if(!data.success) {
                setLoading(false)
                console.error(data.error)
                appAlert(data.error)
                return
            }
            const oc = getOptionList(data.commands)
            setOptions_command( oc )
            setOptions_action( getOptionList(data.actions) )
            setDef_options_command( getOptionListElement(oc,"login") )
            setOptions_username( getOptionList(data.users) )
            setRows(data.rows)
            setLoading(false)
        })
        .catch(function (error) {
            setLoading(false)
            console.error(error);
            appAlert(error)
        })
    }, [appAlert])

    function changeData_dal(date) {
        setData_dal(date)
    }
    function changeData_al(date) {
        setData_al(date)
    }

    const onSubmit = (vals) => {
        console.log("onSubmit vals",vals)
        if(isNaN(data_dal) || isNaN(data_al)) {
            appAlert(t("Data non valida"))
            return
        }
        if(vals.username && vals.patientId !== "") {
            appAlert(t("Ricerca contemporanea di utenti e pazienti non consentita"))
            return
        }
        setShowdatagrid(false)
        let username = null
        if (vals.username)
            username = vals.username.value
        else if (vals.patientId !== "")
            username = "patient "+vals.patientId
        let format = vals.format.value
        if (vals.format.value === "video")
            format = 'JSON'
        const jdata =  {
          inline: false,
          format: format,
          command: vals.command ? vals.command.value : null,
          action2search: vals.action ? vals.action.value : null,
          limit: vals.limit ? parseInt(vals.limit.value) : null,
          username: username,
          date_range_min: data_dal ? ymd(data_dal) : null,
          date_range_max: data_al ? ymd(data_al) : null,
          sort_col: "timestamp", 
          sort_direction: "DESC", 
        }
        const url = "/admin/log_query"
        MyAxios.post(url, jdata, {
            responseType: format !== 'JSON' ? 'arraybuffer' : '',
        })
        .then((response) => {
            const fileName = 'Log.'+format.toLowerCase()
            const res = check_and_download(response,format,vals.format.value,fileName)
            if(res.success === false) {
                console.error("Error: "+res.error)
                appAlert(res.error)
                return
            }
            if (vals.format.value === "video") {
                setRows(res.rows)
                setShowdatagrid(true)
            }
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error)
        })
    }

    const redirect = authorized("GlobalAdmin",userInfo)
    if(redirect)
        return redirect

    if(loading)
        return <Loading />

    return (
    <div className="app-container">
        <Header title={t("Log")} />
        {/* datatable: no flex (facciamo div che comprende tutto) */}
        <div className="content-container">
          <div>
          <Container component="main" maxWidth="md">
            <div className="margintop30">
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Grid container spacing={1} alignItems="center" className="blackColor">
                    <Grid item xs={6}>
                        <MostAutocomplete control={control} name="command" options={options_command} label={t("Comando")} defaultValue={def_options_command} />
                    </Grid>
                    <Grid item xs={6}>
                        <MostAutocomplete control={control} name="action" options={options_action} label="Action" />
                    </Grid>
                    <Grid item xs={6}>
                        <MostAutocomplete control={control} name="username" options={options_username} label={t("Nome utente")} />
                    </Grid>
                    <Grid item xs={6}>
                        <MostTextField name="patientId" label={t("Paziente")+" (db-id)"} register={register} inputProps={{type:'number'}} />
                    </Grid>
                    <Grid item xs={6}>
                        <MostDatePicker label={t("Dal")} value={data_dal} onChange={changeData_dal} disableFuture={true} openTo="date" />
                    </Grid>
                    <Grid item xs={6}>
                        <MostDatePicker label={t("Al")} value={data_al} onChange={changeData_al} disableFuture={true} openTo="date" />
                    </Grid>
                    <Grid item xs={6}>
                        <MostAutocomplete control={control} name="limit" options={options_limit} label={t("Numero massimo risultati")} defaultValue={def_options_limit} />
                    </Grid>
                    <Grid item xs={6}>
                        <MostAutocomplete control={control} name="format" options={options_formatofile_download} label={t("Formato file")+" *"} required={true} rules={{ required: true }}  errors={errors} defaultValue={def_options_formatofile_download} />
                    </Grid>
                    <Grid item xs={8}>
                        <MostSubmitButton className="b_loginok" label={t("Recupera")} />
                    </Grid>
                    <Grid item xs={4}>
                        <MostButton2 fullWidth className="b_loginok" onClick={() => history.push('/uhome') } label={t("Indietro")} />
                    </Grid>
                </Grid>
                </form>
            </div>
          </Container>
          <br />
          </div>
          <div>
            { showdatagrid ?
                <DataTable columns={columns} rows={rows} height={600} density={"compact"} />
            : null }
          </div>
        </div>
        <Footer />
    </div>
    )
}
