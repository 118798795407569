import React, { useState, useEffect, useCallback } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { Header } from '../Header';
import Container from "@material-ui/core/Container";
import { Footer } from '../Footer';
import { useTranslation } from 'react-i18next';
import { DataTable } from '../components/DataTable';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { getCenterAdmin, authorized, options_formatofile, roleString, } from '../UtilsInthem'
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import { options_si_no, MostDatePicker, MostButton2, getOptionList, getOptionListElement, Loading, MostButton, MostSubmitButton, MostAutocomplete, MostTextField, } from "../components/MostComponents";
import MyAxios, {check_response, check_and_download} from "../MyAxios";
import { StudioGestionePazienti } from "./StudioGestionePazienti";
import Box from '@material-ui/core/Box';
import { dataGridMakeIdUnique, ymd, } from '../Utils'
import { getLang, } from '../i18n'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const Export = (props) => {
    const { control, handleSubmit, errors, } = useForm();
    const [result, setResult] = useState()
    const [showdatagrid, setShowdatagrid] = useState(false)
    const [disabledButs, setDisabledButs] = useState(false)
    const [rows, setRows] = useState([])
    const { t, } = useTranslation();
    const history = useHistory();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    const columns = [
        {field: "DB_ID", headerName: "DB_ID" }
    ]
    const fl = props.row.inthem_query_code_detail.field_list
    for(let i in fl.PATIENT) {
        const r = fl.PATIENT
        columns.push({field: r[i], headerName: r[i], width: 200 })
    }
    for(let i in fl.MEDICAL_DATA) {
        const r = fl.MEDICAL_DATA
        columns.push({field: r[i], headerName: r[i], width: 200 })
    }

    const onSubmitDownload = (vals) => {
        console.log("onSubmitDownload vals",vals)
        setResult("")
        setShowdatagrid(false)
        let format = vals.format.value
        if (vals.format.value === "video")
            format = 'JSON'
        const jdata = {
            action: 'RUN',
            format: format,
            id: props.row.id,
            as_role: props.mode==="ca_studio" ? "CenterAdmin" : "DataManager",
        }
        MyAxios.post("/clinical_study", jdata, {
            responseType: format !== 'JSON' ? 'arraybuffer' : '',
        }).then((response) => {
            setDisabledButs(false)
            const fileName = 'studio_'+props.row.id+'.'+format.toLowerCase()
            const res = check_and_download(response,format,vals.format.value,fileName,1)
            if(res.success === false) {
                console.error("Error: "+res.error)
                appAlert(res.error)
                return
            }
            if (vals.format.value === "video") {
                // resettare da visualizzazione precedente (altrimenti si ottiene un mix di colonne tra vecchia e nuova tabella visualizzata
                setRows([])
                setShowdatagrid(true)
                setRows(dataGridMakeIdUnique(res.rows,1))
            }
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
        })
    }

    return (
        <div className="margintop30">
        <Container component="main" maxWidth="md">
            <form onSubmit={handleSubmit(onSubmitDownload)} noValidate>
                    <Grid container spacing={1} alignItems="center" className="blackColor">
                        <Grid item xs={12}>
                            <MostAutocomplete control={control} name="format" options={options_formatofile} label={t("Formato file")+" *"} required={true} rules={{ required: true }}  defaultValue={null} errors={errors} />
                        </Grid>
                        <Grid item xs={8}>
                            <MostSubmitButton disabled={disabledButs} className="b_loginok" label={t("Esporta")} />
                        </Grid>
                        <Grid item xs={4}>
                            <MostButton2 fullWidth disabled={disabledButs} className="b_loginok" onClick={() => history.push(props.backUrl)} label={t("Indietro")} />
                        </Grid>
                    </Grid>
                    </form>
        </Container>
        <div className="blackColor margin20all">
            <div dangerouslySetInnerHTML={{__html: result}} />
        </div>
        { showdatagrid ?
            <DataTable columns={columns} rows={rows} height={400} density={"compact"} />
        : null }
        </div>
)}

const Dettaglio = (props) => {
    const history = useHistory();
    const { userInfo } = useGlobalHook('userStore');
    const { t, i18n } = useTranslation();
    const [disabledButs, setDisabledButs] = useState(false)
    const [center_defValue, setCenter_defValue] = useState(null)
    const [options_center, setOptions_center] = useState([])
    const [status_defValue, setStatus_defValue] = useState(null)
    const [options_status, setOptions_status] = useState([])
    const [type_defValue, setType_defValue] = useState(null)
    const [options_type, setOptions_type] = useState([])
    const [query_defValue, setQuery_defValue] = useState(null)
    const [options_query, setOptions_query] = useState([])
    const [sf36_defValue, setSf36_defValue] = useState(null)
    const [options_sf36, setOptions_sf36] = useState([])
    const { control, register, handleSubmit, errors, } = useForm();
    const [ceca, setCeca] = useState(null)
    const handleCecaChange = (e,el) => {
        setCeca(el)
    }
    const [formReady, setFormReady] = useState(0)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    const options_approval = options_si_no(t)
    let approval_defValue = null
    if (props.row && props.row.ethics_committee_approval !== null) {
        approval_defValue = getOptionListElement(options_approval, props.row.ethics_committee_approval ? "1" : "0")
    }

    let disabledFields = true
    if (props.mode === "ga_studio")
        disabledFields = false

    let defaultDate = null
    if (props.row && props.row.end_enrollment_date)
        defaultDate = new Date(props.row.end_enrollment_date)
    const [end_enrollment_date, setEnd_enrollment_date] = React.useState(defaultDate)
    const handleDateChange = (date) => {
            setEnd_enrollment_date(date)
    }

    let centri_aderenti = ""
    let centri_non_aderenti = ""
    if (props.row) {
        const ec = props.row.enrolled_centers
        for (let i in ec) {
            if (ec[i].ethics_committee_approval === null)
                continue
            if (ec[i].ethics_committee_approval)
                centri_aderenti += ec[i].center_id+" "
            else
                centri_non_aderenti += ec[i].center_id+" "
        }
    }
    if (centri_aderenti !== "")
        centri_aderenti = t("Centri aderenti")+":<br/>"+centri_aderenti
    if (centri_non_aderenti !== "")
        centri_non_aderenti = t("Centri non aderenti")+":<br/>"+centri_non_aderenti

    let axiosRequests = 0

    axiosRequests++
    useEffect(() => {
        MyAxios.get("/list/center")
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            console.error(data.error)
            appAlert(data.error)
            return
          }
          const ol = getOptionList(data.ret,'id','name')
          setOptions_center(ol)
          // calcoliamo anche default per le select
          if(props.row)
            setCenter_defValue( getOptionListElement(ol,props.row.main_center) )
          setFormReady((prev) => prev+1)
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.row,i18n.language])

    axiosRequests++
    useEffect(() => {
        const formData = new FormData()
        formData.append('table_name', 'svc_clinical_study_status')
        formData.append('format', 'JSON')
        formData.append('inline', true)
        formData.append('orderby', 'id')
        // xxx
        formData.append('lang', getLang(i18n.language))
        console.log("/admin/GetConfTable", [...formData.entries()])
        MyAxios.post("/admin/GetConfTable", formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
          const data = check_response(response);
          if(!data.success) {
            console.error(data.error)
            appAlert(data.error)
            return
          }
          const ol = getOptionList(data.rows,'id','trans')
          setOptions_status(ol)
          // calcoliamo anche default per le select
          if(props.row)
            setStatus_defValue( getOptionListElement(ol,props.row.status) )
          else
            setStatus_defValue( getOptionListElement(ol,'EthicsCommitteeWaiting') )
          setFormReady((prev) => prev+1)
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.row,i18n.language])

    axiosRequests++
    useEffect(() => {
        const formData = new FormData()
        formData.append('table_name', 'svc_clinical_study_type')
        formData.append('format', 'JSON')
        formData.append('inline', true)
        formData.append('orderby', 'id')
        // xxx
        formData.append('lang', getLang(i18n.language))
        console.log("/admin/GetConfTable", [...formData.entries()])
        MyAxios.post("/admin/GetConfTable", formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
          const data = check_response(response);
          if(!data.success) {
            console.error(data.error)
            appAlert(data.error)
            return
          }
          const ot = getOptionList(data.rows,'id','id+name')
          setOptions_type(ot)
          // calcoliamo anche default per le select
          if(props.row) {
            setType_defValue( getOptionListElement(ot,props.row.clinical_study_type) )
          }
          setFormReady((prev) => prev+1)
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.row,i18n.language])

    axiosRequests++
    useEffect(() => {
        const jdata = {
            action: 'LIST'
        }
        MyAxios.post("/inthem_query", jdata)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            console.error(data.error)
            appAlert(data.error)
            return
          }
          const oq = getOptionList(data.rows,'id','name+description')
          setOptions_query(oq)
          // calcoliamo anche default per le select
          if(props.row) {
            setQuery_defValue( getOptionListElement(oq,props.row.inthem_query_code) )
          }
          setFormReady((prev) => prev+1)
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.row])

    axiosRequests++
    useEffect(() => {
        const jdata = {
            action: 'LIST',
            // xxx
            lang: getLang(i18n.language),
        }
        MyAxios.post("/sf36", jdata)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            console.error(data.error)
            appAlert(data.error)
            return
          }
          const oq = getOptionList(data.rows,'id','id+description')
          setOptions_sf36(oq)
          // calcoliamo anche default per le select
          if(props.row) {
            setSf36_defValue( getOptionListElement(oq,props.row.sf36_code) )
          }
          setFormReady((prev) => prev+1)
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.row,i18n.language])

    const onSubmit = (vals) => {
        console.log("onSubmit", vals)
        if(isNaN(end_enrollment_date)) {
            appAlert(t("Data non valida"))
            return
        }
        let jdata
        if(props.mode === "ca_studio") {
            if(ceca === null) {
                appAlert(t("Campo obbligatorio"));
                return
            }
            const centro = getCenterAdmin(userInfo)
            let action
            let center
            if (centro === props.row.main_center) {
                action = 'CHANGE'
            } else {
                action = 'ETHIC_APPROVAL'
                center = centro
            }
            jdata = {
                // xxx
                lang: getLang(i18n.language),
                id: props.row.id,
                action: action,
                center: center,
                ethics_committee_approval: ceca.value,
            }
        } else {
            let end_enrollment_date_string = null
            if (end_enrollment_date)
                end_enrollment_date_string = ymd(end_enrollment_date)
            let action
            if (props.studioId === "__new__")
                action = 'ADD'
            else
                action = 'CHANGE'
            jdata = {
                // xxx
                lang: getLang(i18n.language),
                action: action,
                id: vals.id,
                title: vals.title,
                description: vals.description,
                end_enrollment_date: end_enrollment_date_string,
                main_center: vals.main_center.value,
                status: vals.status.value,
                ethics_committee_approval: vals.ethics_committee_approval === null ? null : vals.ethics_committee_approval.value,
                clinical_study_type: vals.clinical_study_type.value,
                inthem_query_code: vals.inthem_query_code.value,
                sf36_code: vals.sf36_code.value,
                video_url: vals.video_url,
            }
        }
        setDisabledButs(true)
        //console.log(jdata)
        MyAxios.post("/clinical_study", jdata)
        .then((response) => {
            const res = check_response(response)
            if (res.success) {
                history.push(props.backUrl);
            } else {
                console.error("Error: "+res.error);
                appAlert(res.error);
                setDisabledButs(false)
            }
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
        })
    }

    if (formReady < axiosRequests) {
        return (
            <Container component="main" maxWidth="md">
            <Loading />
            </Container>
        )
    }

    return (
    <Container component="main" maxWidth="md">
      {props.inserimento_comitato?
        <div>
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <MostAutocomplete value={ceca} onChange={handleCecaChange}name="center_ethics_committee_approval" options={options_approval} label={t("Centro")+" "+props.centro+": "+t("Studio approvato dal comitato etico?")} defaultValue={null} />
            </Grid>
            <Grid item xs={8}>
                <MostButton onClick={onSubmit} disabled={disabledButs} label={t("Inserisci")} />
            </Grid>
            <Grid item xs={4}>
                <MostButton2 fullWidth disabled={disabledButs} className="margin2416" onClick={() => history.push(props.backUrl)} label={t("Indietro")} />
            </Grid>
        </Grid>
        <br />
        <br />
        Informazioni sullo studio:
        </div>
      : null}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
                <MostTextField name="id" disabled={disabledFields} required={true} label={t("Codice")} InputProps={props.row ? { readOnly: true } : null} register={register({ required: true })} defaultValue={props.row ? props.row.id : null} errors={errors} inputProps={{ maxLength: 80 }}  />
            </Grid>
            <Grid item xs={12}>
              <MostTextField name="title" disabled={disabledFields} required={true} label={t("Titolo")} register={register({ required: true })} defaultValue={props.row ? props.row.title : null} errors={errors} inputProps={{ maxLength: 255 }} />
            </Grid>
            <Grid item xs={12}>
              <MostTextField name="description" disabled={disabledFields} multiline={true} rows={5} required={true} label={t("Descrizione")} register={register({ required: true })} defaultValue={props.row ? props.row.description : null} errors={errors} inputProps={{ maxLength: 255 }} />
            </Grid>
            <Grid item xs={12}>
              <MostDatePicker label={t("Data di fine arruolamento")} disabled={disabledFields} value={end_enrollment_date} onChange={handleDateChange} disableFuture={false} openTo="date" />
            </Grid>
            <Grid item xs={12}>
              <MostAutocomplete control={control} name="main_center" disabled={disabledFields} options={options_center} label={t("Centro coordinatore")+" *"} defaultValue={center_defValue} required={true} rules={{ required: true }} errors={errors} />
            </Grid>
            <Grid item xs={12}>
              <MostAutocomplete disabled control={control} name="status" options={options_status} label={t("Stato")} defaultValue={status_defValue} />
            </Grid>
            <Grid item xs={12}>
              <MostAutocomplete disabled={disabledFields} control={control} name="ethics_committee_approval" options={options_approval} label={t("Approvato dal comitato etico?")} defaultValue={approval_defValue} />
            </Grid>
            <Grid item xs={12}>
              <MostAutocomplete control={control} name="clinical_study_type" disabled={disabledFields} options={options_type} label={t("Tipo")+" *"} defaultValue={type_defValue} required={true} rules={{ required: true }} errors={errors} />
            </Grid>
            <Grid item xs={12}>
              <MostAutocomplete control={control} name="inthem_query_code" disabled={disabledFields} options={options_query} label={t("Query")+" *"} defaultValue={query_defValue} required={true} rules={{ required: true }} errors={errors} />
            </Grid>
            <Grid item xs={12}>
              <MostAutocomplete control={control} name="sf36_code" disabled={disabledFields} options={options_sf36} label={t("SF36")+" *"} defaultValue={sf36_defValue} required={true} rules={{ required: true }} errors={errors} />
            </Grid>
            <Grid item xs={12}>
              <MostTextField name="video_url" disabled={disabledFields} register={register} label={t("URL video di presentazione")} defaultValue={props.row ? props.row.video_url : null} inputProps={{ maxLength: 255 }}  />
            </Grid>
            { props.studioId !== "__new__" ?
                <React.Fragment>
                    {centri_aderenti !== "" ?
                        <Grid item xs={12}>
                            <Box border={1} borderRadius={4} className="box" >
                                <div className="boxgray divInBox" dangerouslySetInnerHTML={{__html: centri_aderenti}} />
                            </Box>
                        </Grid>
                    : null }
                    {centri_non_aderenti !== "" ?
                        <Grid item xs={12}>
                            <Box border={1} borderRadius={4} className="box" >
                                <div className="boxgray divInBox" dangerouslySetInnerHTML={{__html: centri_non_aderenti}} />
                            </Box>
                        </Grid>
                    : null }
                </React.Fragment>
            : null }
            <Grid item xs={8}>
                <MostSubmitButton disabled={disabledButs ? true : (disabledFields ? true : false)} label={props.studioId === "__new__" ? t("Inserisci") : t("Modifica")} />
            </Grid>
            <Grid item xs={4}>
                <MostButton2 fullWidth disabled={disabledButs} className="margin2416" onClick={() => history.push(props.backUrl)} label={t("Indietro")} />
            </Grid>
        </Grid>
      </form>
    </Container>
    )
}

export const Studio = (props) => {
    console.log("Studio",props.row)
    const { t, } = useTranslation();
    const { userInfo } = useGlobalHook('userStore');
    const [formatAC, setFormatAC] = React.useState(null)
    const [showdatagrid, setShowdatagrid] = useState(false)
    const [result, setResult] = useState("")
    let initialTab = 0
    const [tabValue, setTabValue] = useState(initialTab)
    const [tab, setTab] = useState("tab"+initialTab)

    let backUrl
    if(props.mode === "ga_studio")
        backUrl = '/ga_studiclinici'
    else if(props.mode === "dm_studio")
        backUrl = '/dm_studiclinici'
    else
        backUrl = '/ca_studiclinici'

    const changeTab = (tabNumber,fromPreSubmit) => {
        setTabValue(tabNumber)
        setTab("tab"+tabNumber)
        window.scrollTo(0, 0)
        setFormatAC(null)
        setShowdatagrid(false)
        setResult("")
    }
    const handleTabChange = (e,tabNumber) => {
        changeTab(tabNumber)
    }

    const redirect = authorized(
        props.mode.startsWith("ga") ?  "GlobalAdmin" : ( props.mode.startsWith("dm") ? "DataManager" : "CenterAdmin" ),
        userInfo)
    if(redirect)
        return redirect

    let title
    if (props.studioId === "__new__") {
        title = t("Nuovo studio")
    } else {
        title = t("Gestione studio")+" "+props.row.title
    }

    let inserimento_comitato = false
    let controllo_pazienti = false
    let esportazione = false
    let centro = ""
    if (props.mode==="ca_studio") {
        centro = getCenterAdmin(userInfo)
        if (props.row.ops === "Inserimento_valutazione_comitato_etico")
            inserimento_comitato = true
        if (props.row.ops === "Controllo_pazienti")
            controllo_pazienti = true
    } else if (props.mode==="dm_studio") {
        if (props.row.ops === "Esportazione")
            esportazione = true
    }

    return (
  <div className="app-container">
  <Header backMode={backUrl} title={title} subtitle={roleString(props.mode,t)}/>
  <div className={"content-container "+(controllo_pazienti||esportazione? "contentWithToolbar":"")}>
  <Container component="main" maxWidth="md">
    { props.studioId !== "__new__" && props.mode === "ga_studio" ?
        <Toolbar className="rowreverse">
            <Button disabled variant="contained" color="primary" className="marginr10" > Disabilita </Button>
            <Button disabled variant="contained" color="primary" className="marginr10" > Cancella </Button>
        </Toolbar>
    : null }
  </Container>
  {controllo_pazienti || esportazione ?
    <div>
        <Container component="main" maxWidth="md">
            <AppBar position="absolute">
                { controllo_pazienti ?
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label="Dettagli studio" id="tab-0" />
                        <Tab label="Gestione pazienti" id="tab-1" />
                        <Tab label="Esportazione" id="tab-2" />
                    </Tabs>
                :
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label="Dettagli studio" id="tab-0" />
                        <Tab label="Esportazione" id="tab-1" />
                    </Tabs>
                }
            </AppBar>
        </Container>
        { tab === "tab0" ?
            <div>
            <h2> </h2>
            <Dettaglio studioId={props.studioId} centro={centro} inserimento_comitato={inserimento_comitato} backUrl={backUrl} row={props.row} mode={props.mode} />
            </div>
        : null }
        { tab === "tab1" && controllo_pazienti ?
            <StudioGestionePazienti tab={tab} centro={centro} studioId={props.studioId} backUrl={backUrl} formatAC={formatAC} setFormatAC={setFormatAC} controllo_pazienti={controllo_pazienti} showdatagrid={showdatagrid} setShowdatagrid={setShowdatagrid} result={result} setResult={setResult} mode={props.mode} />
        : null }
        { tab === "tab2" || (tab === "tab1" && esportazione) ?
            <Export mode={props.mode} backUrl={backUrl} studioId={props.studioId} row={props.row} />
        : null }
    </div>
  :
    <Dettaglio studioId={props.studioId} centro={centro} inserimento_comitato={inserimento_comitato} backUrl={backUrl} row={props.row} mode={props.mode} />
  }
  </div>
  <Footer />
  </div>
  )
}
