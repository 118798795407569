import React from 'react';
import MyAxios from "./MyAxios";
import { Header } from './Header';
import { Footer } from './Footer';
import { SignIn } from './auth/SignIn';
import { SignInPatient } from './auth/SignInPatient';
import { Redirect } from "react-router-dom";
import { useGlobalHook } from '@devhammed/use-global-hook'

export const Login = (props) => (
  <div>
    <Header empty={true} />
    { props.mode === "user" ?
        <SignIn resetIdleTimeout={props.resetIdleTimeout} />
    :
        <SignInPatient resetIdleTimeout={props.resetIdleTimeout} />
    }
    <Footer />
  </div>
);

export function Logout() {
  console.log("Logout");
  const { setUserInfo } = useGlobalHook('userStore');
  //localStorage.removeItem("token");
  React.useEffect(() => {
    setUserInfo({});
    MyAxios.get('/userlogout');
  }, [setUserInfo]);
  //console.log("Logout" + localStorage.getItem("token"));
  // user o patient?
  return (<Redirect to='/' />);
};
