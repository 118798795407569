import React, { useState, useEffect, useCallback, } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { scrollToElement } from '../Utils'
import { useForm, } from "react-hook-form";
import { anyFormErrors, MostRadioGroup, MostButton, MostSubmitButton, Loading, } from "../components/MostComponents";
import MyAxios, {check_response} from "../MyAxios";

 export const ConsensoPre = (props) => {
    const [premodello, setPremodello] = useState(null)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
        setContent(text);
        setAlert1(true);
    }, [setContent,setAlert1])
    const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
    function appAlert2(text,closeHandler) {
      setContent2(text)
      setOnClose2(() => x => {
        closeHandler()
      });
      setAlert2(true)
    }

    const { control, handleSubmit, errors, } = useForm();
    const { t } = useTranslation();

    // scroll su primo errore della form
    useEffect(() => {
       let errorsArray = Object.keys(errors)
       if (errorsArray.length) {
         let el = document.getElementById(errorsArray[0])
         if (el) {
            el.scrollIntoView()
         }
       }
    }, [errors])

    useEffect(() => {
        const jdata = {
            action: 'GET',
            // xxxx lang
            lang: 'it',
            id: 'pre_consent1',
        }
        MyAxios.post("/consent", jdata)
        .then((response) => {
            const res = check_response(response)
            if (res.success) {
                setPremodello(res.rows[0].points)
            } else {
                console.error("Error: "+res.error);
                appAlert(res.error);
            }
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert, ])

    const onSubmit = (vals) => {
        console.log(vals)
        for (let i in vals) {
            if (vals[i] === "0") {
                appAlert2(t("Non è possibile procedere se non si è risposto Sì a tutte le domande"),() => {
                    scrollToElement("startingDiv")
                })
                return
            }
        }
        props.changeTab(2,1)
    }

    const ancoraDomande = () => {
        props.changeTab(0)
    }

    if(!premodello)
        return <Loading />

    return (
    <div className="blackColor">
        <div id="startingDiv"/>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ol className="modulo">
        {premodello.map((item,itemIndex)=>{
            if (item.tipo === "testo") {
                return (
                    <li key={"li"+itemIndex}> <b>{item.testo}</b> </li>
                )
            }
            const defaultValue = null
            return (
                <li id={"point_"+itemIndex} key={"li"+itemIndex}>
                    <div dangerouslySetInnerHTML={{__html: "<b>"+item.testo+"</b>"}} />
                    {item.tipo === "radio"?
                        <MostRadioGroup
                            control={control}
                            rules={{ required: true }}
                            name={"point_"+itemIndex}
                            options={item.risposte}
                            errors={errors}
                            defaultValue={defaultValue}
                          />
                    : null }
                </li>
            )
        })}
        </ol>
        <Grid container spacing={1} alignItems="flex-start" className="blackColor">
            <Grid item xs={12}>
                {anyFormErrors(errors) ? 
                    <span className="badValue">
                        {t("Non hai risposto a tutte le domande")}
                    </span>
                : null}
            </Grid>
            <Grid item xs={6}>
                <MostButton label={t("Ho ancora domande")} onClick={ancoraDomande} id="domande" />
            </Grid>
            <Grid item xs={6}>
                <MostSubmitButton label={t("Posso procedere alla compilazione")} id="Conferma" />
            </Grid>
        </Grid>
        </form>
    </div> 
    )
}

