import React from "react";
import { useTable } from "react-table";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

//https://react-table.tanstack.com/docs/examples/data-driven-classes-and-styles
// Create a default prop getter
const defaultPropGetter = () => ({})

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-head": {
      fontWeight: 700,
      backgroundColor: "#f0f0f0",
    }
  },
  container: {
    maxHeight: 500,
  },
})

const StyledTableRow = withStyles((theme) => ({
  root: {
    // righe alternate
    // o scurire background di head sopra a #eaeaea
    // o colorare (azzurro) queste
    // '&:nth-of-type(even)': { backgroundColor: '#fafafa', },
    // '&:nth-of-type(even)': { backgroundColor: '#f9f9ff', },
  },
}))(TableRow)


export function MostTable({ 
  columns,
  data,
  className,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  const classes = useStyles()
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data,
  });

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div className={className}>
    <TableContainer component={Paper} className={classes.container}>
    <Table stickyHeader className={classes.table} size="small" {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell
                // Return an array of prop objects and react-table will merge them appropriately
                {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                  getColumnProps(column),
                  getHeaderProps(column),
                ])}
              >
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            // Merge user row props in
            <StyledTableRow {...row.getRowProps(getRowProps(row))}>
              {row.cells.map(cell => {
                return (
                  <TableCell component="th" scope="row"
                    // Return an array of prop objects and react-table will merge them appropriately
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      getCellProps(cell),
                    ])}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                )
              })}
            </StyledTableRow>
          )
        })}
      </TableBody>
    </Table>
    </TableContainer>
    </div>
  )
}

