import React, { useState, useCallback, } from "react";
import { Header } from '../Header';
import { Footer } from '../Footer';
import Grid from "@material-ui/core/Grid";
import { useTranslation } from 'react-i18next';
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { scrollToElement } from '../Utils'
import { authorized } from '../UtilsInthem'
import { ConsensoPre } from './ConsensoPre'
import { ConsensoForm } from './ConsensoForm'
//import MyAxios from "../MyAxios";
import { MostButton, } from "../components/MostComponents";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const Consenso = () => {
    const { userInfo } = useGlobalHook('userStore');
    const { t } = useTranslation();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
        setContent(text);
        setAlert1(true);
    }, [setContent,setAlert1])

    let initialTab = 0
    if (userInfo.c_db !== null && userInfo.c_db_p !== null) {
        initialTab = 2
    }
    const [tabValue, setTabValue] = useState(initialTab)
    const [tab, setTab] = useState("tab"+initialTab)

    const changeTab = (tabNumber,fromPreSubmit) => {
        if(tabNumber === 2) {
            if(userInfo.c_db_p === null && fromPreSubmit !== 1) {
                appAlert("Prima rispondi alle domande preliminari")
                tabNumber = 1
            }
        }
        setTabValue(tabNumber)
        setTab("tab"+tabNumber)
        scrollToElement("startingDiv")
    }

    const handleTabChange = (e,tabNumber) => {
        changeTab(tabNumber)
    }

    const Informazioni = () => {
        const { t } = useTranslation();
        // xxxxx inizio
        const url_video = "https://xxxx/xxxx"
        const url_informazioni = "https://xxxx/xxxx"
        const telefono = "xxx"
        // xxxxx fine
        const url_login = window.location.protocol+"//"+window.location.host+"/loginp"

        const goOn = () => {
            changeTab(1)
        }

        return (
        <div>
            <div id="startingDiv"/>
            <h2> Consenso per la raccolta dei dati </h2>
            <div className="blackColor">
    <p>I tuoi dati sono conservati e utilizzati ai fini sotto descritti</p>
    <p>Hai il diritto di decidere se revocare, mantenere o modificare le opzioni che fino alla maggiore età sono state espresse dai tuoi genitori/tutori legali.</p>
    <p>Ho letto le informazioni per l’utilizzo del sito e visto <a href={url_video} target="_blank" rel="noreferrer">il video informativo di introduzione al database</a>.</p>
    <p>Tramite il consenso dinamico avrò accesso ad una pagina web personale cui accedere tramite username e password. In quest’area riservata avrò la possibilità di fornire ulteriore consenso per progetti di ricerca inerenti all’area delle Emoglobinopatie.</p>
    <p>Resta chiaro che per ora i miei dati, raccolti dietro autorizzazione fornita con questo consenso, saranno conservati ed utilizzati in seguito solo per progetti a cui io darò consenso in un momento successivo tramite la mia area riservata.</p>
    <p>Posso chiedere informazioni sui miei dati al titolare della privacy:</p>
    <p>
    <i>Fondazione per la Ricerca sull’Anemia <br />
    ed Emoglobinopatie in Italia - For Anemia <br />
    Via Garibaldi 7 C3 <br />
    16124 Genova</i>
    </p>
    <p>Posso avere ulteriori informazioni alla pagina Web <a href={url_informazioni} target="_blank" rel="noreferrer">{url_informazioni}</a> riguardo al funzionamento del database e posso chiamare il numero 0039 {telefono}</p>
    <p>Le scelte che seguono possono essere cambiate in ogni momento attraverso la mia area personale: {url_login}</p>
            </div>
            <Grid container spacing={1} alignItems="flex-start" className="blackColor">
                <Grid item xs={12}>
                    <MostButton label={t("Posso procedere alla compilazione")} onClick={goOn} id="Conferma" />
                </Grid>
            </Grid>
        </div>
        )
    }

    const redirect = authorized("patient",userInfo)
    if(redirect)
        return redirect

    return (
  <div className="app-container">
    <Header title={t("I tuoi consensi")} />
    <div className={"content-container "+(tab === "tab2" ?  "contentWithTabAndToolbar" : "contentWithToolbar")} >
        <Container component="main" maxWidth="md">
            <AppBar position="absolute">
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Informazioni" id="tab-0" />
                    <Tab label="Domande preliminari" id="tab-1" />
                    <Tab label="Consensi" id="tab-2" />
                </Tabs>
            </AppBar>
            <div className="margintop30">
                { tab === "tab0" ?
                    <Informazioni />
                : (tab === "tab1" ?
                    <ConsensoPre changeTab={changeTab} />
                :
                    <ConsensoForm userInfo={userInfo} />
                )}
            </div>
        </Container>
    </div>
    <Footer />
  </div>
    )

};
