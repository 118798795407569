import React from "react";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useTranslation } from 'react-i18next';
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized, getPdf } from '../UtilsInthem'
import { getLang } from '../i18n'
//import MyAxios from "../MyAxios";
import Grid from "@material-ui/core/Grid";
import { MostButton2, MostButton, } from "../components/MostComponents";
import { useHistory } from "react-router-dom";

export const MyConsent = () => {
    const { userInfo } = useGlobalHook('userStore');
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const viewSf36example = () => {
        getPdf("sf36example",i18n)
    }

    const getTitle = (obj) => {
        const lang = getLang(i18n.language)
        if(obj['trans_'+lang])
            return (obj['trans_'+lang])
        if(obj['trans_en'])
            return (obj['trans_en'])
        return (obj['trans_it'])
    }

    const redirect = authorized("patient",userInfo)
    if(redirect)
        return redirect

    return (
  <div>
    <Header title={t("MyConsent")} />
    <Container component="main" maxWidth="md" className="MyConsentContainer">
        <Grid container spacing={1} alignItems="flex-start" className="blackColor">
        {0 || userInfo.c_db === null ?
            <Grid item xs={12} className="red big">
                Il paziente non ha dato l'autorizzazione
                all'iscrizione al sistema
            </Grid>
        : null}
        {0 || userInfo.c_db === null || userInfo.c_db_p === null ?
            <React.Fragment>
            <Grid item xs={12} className="important big">
                <p className="MyConsentWelcome">
                    {t("Benvenuto")} {userInfo.name} {userInfo.surname}
                </p>
                Per potere attivare il tuo ambiente DB-INTHEM è necessario
                che tu ci fornisca i tuoi consensi. <br />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={8}>
                <MostButton className="b_loginok" onClick={() => history.push("/consenso")} label={t("inserisci i tuoi consensi")} id="consenso"/>
            </Grid>
            <Grid item xs={12}>
                <div className="MyConsentSpace" />
                Successivamente potrai utilizzare il tuo ambiente:
            </Grid>
            </React.Fragment>
        : userInfo.studi_attesa.length?
            userInfo.studi_attesa.map((studio,studioIndex)=>(
                <React.Fragment key={"S"+studioIndex}>
                    <Grid item xs={12} className="important big">
                    Sei stato invitato a partecipare a un nuovo studio: <br />
                    <big>Studio {getTitle(studio.clinical_study_title)}</big> <br />
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={8}>
                        <MostButton className="b_loginok" label={t("Informati e decidi se partecipare")} onClick={() => history.push("/pstudio/"+studio.clinical_study_id)} id={"s"+studioIndex}/>
                        <div className="MyConsentSpace" />
                    </Grid>
                </React.Fragment>
            ))
        : null }
        <Grid item xs={4}>{t("I tuoi dati")}</Grid>
        <Grid item xs={8}>
            <MostButton2 fullWidth className="b_loginok" onClick={() => history.push("/pdati")} label={t("Visualizza")} id="pdati"/>
        </Grid>
        <Grid item xs={4}>{t("I tuoi consensi")}</Grid>
        <Grid item xs={8}>
            <MostButton2 fullWidth className="b_loginok" onClick={() => history.push("/consenso")} label={t("Visualizza")} id="consenso"/>
        </Grid>
        {/*
        <Grid item xs={4}>{t("Prova Pdf Form")}</Grid>
        <Grid item xs={8}>
            <MostButton2 fullWidth className="b_loginok" onClick={provapdf} label={t("Scarica")} />
        </Grid>
        */}
        <Grid item xs={4}>{t("Nuovo questionario")}</Grid>
        <Grid item xs={8}>
            <MostButton2 fullWidth className="b_loginok" onClick={() => history.push("/sf36")} label={t("Compila")} id="sf36" />
        </Grid>
        <Grid item xs={4}>{t("I tuoi questionari")}</Grid>
        <Grid item xs={8}>
            <MostButton2 disabled fullWidth className="b_loginok" onClick={viewSf36example} label={t("Visualizza esempio")} id="viewSf36example" />
        </Grid>
        <Grid item xs={4}>{t("Le newsletter")}</Grid>
        <Grid item xs={8}>
            <MostButton2 fullWidth disabled className="b_loginok" onClick={() => history.push("/myconsent")} label={t("Visualizza")} />
        </Grid>
        <Grid item xs={4}>{t("Gli studi clinici")}</Grid>
        <Grid item xs={8}>
            <MostButton2 fullWidth className="b_loginok" onClick={() => history.push("/pstudi")} label={t("Visualizza")} id="pstudi" />
        </Grid>
        </Grid>

{/*
vedi 2.3.10 capitolato MyConsent
*/}
{/*
vedi 2.3.11 capitolato Invio Promemoria
*/}
<div>
MyConsent da cui possono svolgere le seguenti attività:
    • Consultare/scaricare una scheda PDF con i propri consensi generali.
    • Completare/modificare i propri consensi generali (per questa funzione può ricevere link diretto di invito). Come caso estremo (dopo richiesta di conferma) eliminare definitivamente la propria presenza in DB-INTHEM in uno dei seguenti modi:
        a) Sospendere le comunicazioni, ma lasciare in uso i dati completi ricevuti fino a quel momento.
        b) Rimuovere i dati identificativi, ma lasciare in uso i dati restanti in forma pseudo anonimizzata.
        c) Rimuovere tutti i dati (salvo una riga anonima che dice che il paziente "id_nel_centro" del centro "codice_centro" è nello stato "oblio", per evitare di ricevere nuovamente il paziente). In quest'ultimo caso, prima della rimozione, l'applicazione dovrà inviare mail automatiche ai CenterAdmin del centro da cui proviene il Paziente e ai GlobalAdmin con gli Studi Clinici in cui è coinvolto per i provvedimenti del caso.
    • Compilare un nuovo questionario SF-36 (per questa funzione può ricevere link diretto di invito). In allegato il modulo cartaceo usato fino ad ora.
    • Consultare in sola lettura i questionari SF-36 compilati in passato nel sistema (in forma di PDF), ordinati per data. Cancellarli se desidera.
    • Consultare l'elenco delle NewsLetter e scaricarne il relativo PDF (per lo scaricamento dell'ultimo file può ricevere link diretto di invito). Una opportuna evidenziazione indicherà quelle non lette.
    • Consultare l'elenco degli Studi Clinici in cui è coinvolto. Una opportuna evidenziazione indicherà quelle/quelli con messaggi non letti.
    • Per ciascuno aprire un dettaglio in cui può:
        ◦ visualizzare comunicazione di apertura e visionare l'eventuale messaggio video associato e, se non ancora espresso, fornire consenso specifico (può ricevere link diretto di invito);
        ◦ visualizzare analogamente le eventuali comunicazioni successive (può ricevere link diretto di invito).
Gli utenti che accedono da un link diretto, al termine attività (ad es. dopo la compilazione del questionario SF-36) vengono trasferiti sulla pagina home MyConsent.
    Vincolo 17. L'interfaccia utente verso i Pazienti (MyConsent) deve essere "responsive" ossia adattarsi automaticamente a schermi di varie dimensioni (PC, tablet, smartphone…) ottimizzando la disposizione degli elementi nella pagina, nascondendo gli elementi superflui, trasformando i menù in elementi a comparsa, scalando i testi in modo che tutto sia ben leggibile e accessibile con minime necessità di scroll anche su uno smartphone con schermo di soli 4 pollici. E' ammesso lo scroll solo in verticale e con estensione della pagina virtuale limitata a massimo 4 volte circa lo schermo fisico).
    Vincolo 18. Le maschere di compilazione dei consensi devono consentire di rispondere alle stesse domande presenti nel modulo pdf in Allegato 1. I testi delle domande e delle risposte ed eventuali ulteriori domande devono essere configurabili dal GlobalAdmin.
    Vincolo 19. Le maschere di compilazione del questionario SF-36 devono consentire di rispondere alle medesime domande presenti nel modulo pdf in Allegato 2 (opportunamente riorganizzate e razionalizzate). Numero di domande, testo delle domande, possibili risposte, valori associati alle risposte devono essere configurabili dal GlobalAdmin.
</div>
    </Container>
    <Footer />
  </div>
    );
};
