import React, { useEffect, useCallback, useState } from "react";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Centro } from './Centro';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { DataTable } from '../components/DataTable';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized } from '../UtilsInthem'
import SettingsIcon from '@material-ui/icons/Settings';
import MyAxios, {check_response} from "../MyAxios";
import { Loading, getOptionList } from "../components/MostComponents";

export const Centri = (props) => {
    const history = useHistory()
    const { userInfo } = useGlobalHook('userStore');
    const [rows, setRows] = useState([])
    const [row, setRow] = useState(null)
    const [options_geotreeid, setOptions_geotreeid] = useState([])
    const { t } = useTranslation();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    // props.mode==="centro"
    const centerId = props.match.params.centerId
    //console.log("Centri centerId",centerId)

    const columns = [

      { field: 'bottoni', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
          const onClickSettings = () => {
            const id = params.id
            history.push("/centro/"+id)
          }
          return (
            <div>
            <Tooltip title={t("Gestione centro")}><IconButton className="gold" onClick={onClickSettings}><SettingsIcon /></IconButton></Tooltip>
            </div>
          )
        }
      },
      { field: 'id', headerName: 'Codice', width: 130 },
      { field: 'name', headerName: 'Nome', width: 130 },
      { field: 'address', headerName: 'Indirizzo', width: 200 },
      { field: 'city', headerName: 'Città', width: 130 },
      { field: 'geotreeid', headerName: 'Zona', width: 160 },
      { field: 'ecrf', headerName: 'e-CRF', width: 100 },
    ];

    useEffect(() => {
        const formData = new FormData()
        formData.append('role', 'UserAdmin')
        console.log("/admin/get_user_role", [...formData.entries()])
        MyAxios.post("/admin/get_user_role", formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
          const data = check_response(response);
          if(!data.success) {
            console.error(data.error)
            appAlert(data.error)
            return
          }
          setRows(data.user_role.centers)
          if(props.mode==="centro") {
            const ogt = getOptionList(data.user_role.leaves,'id','id')
            setOptions_geotreeid(ogt)
          }
          if(centerId) {
            for (let i in data.user_role.centers) {
                if(data.user_role.centers[i].id === centerId) {
                    setRow(data.user_role.centers[i])
                    break
                }
            }
          }
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,centerId,props.mode])

    const nuovo = (el) => {
        history.push("/centro/__new__")
    }

    const redirect = authorized("UserAdmin",userInfo)
    if(redirect)
        return redirect

    if(props.mode==="centro") {
        if((centerId !== "__new__" && !row) || !options_geotreeid.length)
            return <Loading />
        return (
            <Centro options_geotreeid={options_geotreeid} row={row} centerId={centerId} />
        )
    }

    return (
        <div className="app-container">
        <Header title={t("Gestione centri")} />
        <div className="content-container">
        <DataTable columns={columns} rows={rows} height={600} density={"compact"} toolbar={true} tbButton={t("Nuovo centro")} tbButtonOnClick={nuovo} disableSelectionOnClick={true} />
        <hr />
        <Container component="main" maxWidth="md">
<div className="blackColor asinistra">
<p>
Gli UserAdmin devono poter visualizzare gli elenchi di Centri (e relativi e-CRF): potranno crearne nuovi, sospenderli, riattivarli e cancellarli (solo logicamente) e modificarne i permessi. Ogni Centro sarà connotato da un Codice, Nome, Indirizzo, Area foglia del GeoTree, Software di e-CRF e altre informazioni utili e preferenze proprie del centro.
Gli UserAdmin avranno una visibilità ridotta alle sole aree di propria competenza considerando il GeoTree e i Centri e Utenti correlati alla propria area o alle aree sottostanti.
I Centri saranno legati alle aree foglia del GeoTree.
</p>
</div>
        </Container>
        </div>
        <Footer />
        </div>
    )
}
