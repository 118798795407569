import React from "react";
import { Header } from '../Header';
import Container from "@material-ui/core/Container";
import { Footer } from '../Footer';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { options_tabelleservizio, authorized } from '../UtilsInthem'
import Grid from "@material-ui/core/Grid";
import { MostButton, } from "../components/MostComponents";
import { useHistory } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';

export const GlobalConfig = () => {
    const { userInfo } = useGlobalHook('userStore');
    const { t } = useTranslation();
    const history = useHistory();
    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
      },
    }))(Tooltip);

    const getTabelleServizio = () => {
        let ret = "<ul>"
        for (var i in options_tabelleservizio) {
            ret += "<li>"+options_tabelleservizio[i].label+"</li>"
        }
        ret += "</ul>"
        return ret
    }

    const redirect = authorized("GlobalAdmin",userInfo)
    if(redirect)
        return redirect

    return (
  <div className="app-container">
    <Header title={t("Configurazione globale")} />
    <div className="content-container margintop30">
    <Container component="main" maxWidth="md">
        <Grid container spacing={1} alignItems="flex-start" className="blackColor">
            <Grid item xs={4}>Parametri </Grid>
            <Grid item xs={8}>
                <MostButton disabled className="b_loginok" onClick={() => history.push("/globalconfig")} label={t("Configurazione parametri")} />
            </Grid>
            <Grid item xs={4}>
                Tabelle di servizio
                <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div className="tooltipDiv" dangerouslySetInnerHTML={{__html: getTabelleServizio()}} />
                      </React.Fragment>
                    }
                  >
                     <InfoIcon className="vertMiddle margin20" />
                </HtmlTooltip>
            </Grid>
            <Grid item xs={8}>
                <MostButton className="b_loginok" onClick={() => history.push("/tabelleservizio")} label={t("Recupera")+" / "+t("Carica")} />
            </Grid>
            <Grid item xs={4}>
            SF36
            </Grid>
            <Grid item xs={4}>
                <MostButton className="b_loginok" onClick={() => history.push("/creasf36")} label={t("Nuovo")} />
            </Grid>
            <Grid item xs={4}>
            </Grid>
        </Grid>
        <div className="blackColor asinistra">
            <h2>tabella configuration</h2>
            <ul>
            <li>password_day_duration</li>
            <li>password_min_strength</li>
            <li>otp_minute_duration</li>
            </ul>
            <h2>altro...</h2>
            <p>i timeout, le durate di scadenza e i parametri di complessità password, eventuali valori di default, ...
            </p>
            <p> Devono inoltre poter consultare, scaricare e se necessario aggiornare, ricaricandoli sotto forma file Excel, tutte le tabelle di tipizzazione (es. codici ICD, Medicinali chelanti…), anche quelle per cui è molto improbabile la modifica (Tipi di gruppi sanguigni, relativi Genotipi, Fenotipi e Alleli, Tipi di immunizzazioni, Stati degli Studi Clinici, Modalità di assunzione dei medicinali, ecc…). Per alcune tabelle saranno possibili solo aggiunte e modifiche dei testi descrittivi. Solo alcune tabelle i cui codici sono legati a comportamenti del software (es. le possibili risposte ad alcune domande dei questionari) potrebbero avere il vincolo di non aggiungere e non cancellare o modificare nessun codice, lasciando solo libertà di modifica delle descrizioni associate (e traduzioni per le varie lingue, ove siano supportate più lingue).
            </p>
            <p>
Fra le altre tabelle di configurazione deve essere possibile gestire un albero gerarchico a più livelli di scomposizione del territorio in Aree. La struttura verrà indicata nel seguito come GeoTree.
            </p>
            <p>
            Attraverso un caricamento/scaricamento di file Excel o un editing tabellare il GlobalAdmin deve poter gestire (in sola aggiunta, per i codici, anche in correzione per tutte le altre colonne) due tabelle di riferimento particolarmente importanti e valide per tutti i centri: la tabella delle Diagnosi Specifiche di anemia e la tabella dei Farmaci Chelanti.
            </p>
            <p>
            La tabella delle possibili Diagnosi Specifiche di anemia e altre malattie correlate è necessaria poiché la classificazione internazionale ICD (International Classification of Diseases), attualmente giunta alla versione 2007 denominata ICD9-CM1, è troppo poco dettagliata nella sezione delle anemie. La Figura 4 esemplifica la tabella.
            Code
            Description
            Cat
            ICD
            DescriptionICD
            DB
            </p>
            <p>
            La tabella dei possibili farmaci Chelanti del ferro enumera i tre farmaci attualmente approvati, più eventuali farmaci sperimentali e in futuro eventuali nuovi farmaci.
            Code
            Name
            ActivePrinciple
            Approved
            Mode
            </p>
        </div>
    </Container>
    </div>
    <Footer />
  </div>
    );
};
