import React, { useState, } from "react";
import uniqid from "uniqid";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Header } from '../Header';
import Container from "@material-ui/core/Container";
import { Footer } from '../Footer';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized } from '../UtilsInthem'
import { MostButton2, } from "../components/MostComponents";

// https://stackoverflow.com/questions/63282781/react-hooks-dynamically-adding-and-deleting-component-delete-problem
// https://codesandbox.io/s/practical-snowflake-575qs?file=/contexts/exam.js

const Question = (props) => {
    const deleteMe = () => {
        props.removeQuestion(props.id)
    }
    return (
        <div>
            <br />
            DOMANDA codice {props.id} {" "}
            <MostButton2 onClick={deleteMe} label={"Rimuovi"} />
            <br />
        </div>
    )
}

export const CreaSf36 = () => {
    const { userInfo } = useGlobalHook('userStore');
    const { t } = useTranslation()
    const [questions, setQuestions] = useState([])

    const removeQuestion = (id) => {
        setQuestions((questions) => questions.filter((q) => q.id !== id))
    }
    const addQ = () => {
        const id = uniqid()
        setQuestions((questions) => [
          ...questions,
          {
            id: id
          }
        ])
    }

    const redirect = authorized("GlobalAdmin",userInfo)
    if(redirect)
        return redirect

    return (
  <div className="app-container">
    <Header title={t("Nuovo Sf36")} />
    <div className="content-container">
    <Container component="main" maxWidth="md">

        <button onClick={addQ} className="addqcs">
            <AddCircleIcon />
        </button>
        {questions.map((q) => {
            return <Question key={q.id} id={q.id} removeQuestion={removeQuestion} />;
        })}
    </Container>
    </div>
    <Footer />
  </div>
    );
};
