import React, { useEffect, useCallback, useState } from "react";
import Container from "@material-ui/core/Container";
import { Header } from '../Header';
import { Footer } from '../Footer';
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { options_formatofile_download, options_formatofile, authorized } from '../UtilsInthem'
import { addIdToRows, prettyJson, } from '../Utils'
import MyAxios, { check_and_download, check_response } from "../MyAxios";
import { MostAutocomplete, MostButton2, MostButton, MostSubmitButton } from "../components/MostComponents";
import { FileUpload } from "../components/FileUpload";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { DataTable } from '../components/DataTable';

export const PazientiDati = () => {
    //console.log("PazientiDati")
    const { userInfo } = useGlobalHook('userStore');
    const { control, handleSubmit, errors, } = useForm();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const [center, setCenter] = useState()
    const [mode, setMode] = useState()
    const [rows, setRows] = useState([])
    const [showdatagrid, setShowdatagrid] = useState(false)
    const [result, setResult] = useState()
    const [uploadInfo, setUploadInfo] = useState(null);
    const [uploadTarget, setUploadTarget] = useState(null);
    const [disabledButs, setDisabledButs] = useState(false)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])
    const spinnerCss = css`
        display: block;
        margin: 0 auto;
    `;

    const columns = [
      //{ field: 'CENTER_CODE', headerName: 'Centro' },
      { field: 'CENTER_ID', headerName: 'Codice', width: 200 },
      { field: 'C_DB_M', headerName: 'M' },
      { field: 'C_DB_P', headerName: 'P' },
    ]

    useEffect(() => {
        for (let i in userInfo.roles)
            if (userInfo.roles[i].role === "CenterAdmin") {
                setCenter(userInfo.roles[i].center)
                break
            }
    }, [userInfo.roles])

    const onSubmitDownload = (vals) => {
        console.log("onSubmitDownload vals",vals)
        setShowdatagrid(false)
        let format = vals.format.value
        if (vals.format.value === "video")
            format = 'JSON'
        const formData = new FormData()
        formData.append('centerCode', center)
        formData.append('format', format)
        const url = "/getConsents"
        console.log(url, [...formData.entries()])
        MyAxios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data;" },
            responseType: format !== 'JSON' ? 'arraybuffer' : '',
        }).then((response) => {
            setDisabledButs(false)
            const fileName = 'consents.'+format.toLowerCase()
            const res = check_and_download(response,format,vals.format.value,fileName,1)
            if(res.success === false) {
                console.error("Error: "+res.error)
                appAlert(res.error)
                return
            }
            if (vals.format.value === "video") {
                setRows(addIdToRows(res.rows))
                setShowdatagrid(true)
            }
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
        })
    }

    const onSubmitUpload = (vals) => {
        if (!uploadInfo) {
            appAlert("File non scelto")
            return
        }
        console.log("uploadInfo",uploadInfo,"vals",vals,"mode",mode)
        let api, filefield
        if(mode === "CreatePatients") {
            api = "/createPatients"
            filefield = "PatientsFile"
        } else if(mode === "putPersonalData") {
            api = "/putPersonalData"
            filefield = "PersonalDataFile"
        } else if(mode === "putMedicalData") {
            api = "/putMedicalData"
            filefield = "MedicalDataFile"
        } else {
            appAlert("unknown mode "+mode)
            return
        }
        setDisabledButs(true)
        setLoading(true)
        const formData = new FormData()
        formData.append('centerCode', center)
        formData.append('format', vals.format.value)
        formData.append(filefield, uploadInfo[0])
        console.log(api, [...formData.entries()])
        MyAxios.post(api, formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
            setDisabledButs(false)
            setLoading(false)
            const res = check_response(response)
            if (res.success) {
                console.log(res)
                appAlert("Caricamento terminato<br><br>Record importati: "+res.report.ok+"<br>Record non importati: "+res.report.ko);
                setResult("Record importati: "+res.report.ok+"<br>Record non importati: "+res.report.ko+"<br /><br />Dettaglio importazione:<br /><pre>"+prettyJson(res.report.detail)+"</pre>")
            } else {
                console.error("Error: "+res.error);
                appAlert(res.error);
            }
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
            setLoading(false)
        })
        .then(function () {
            // se cambia file uploadato, fallisce nuovo upload
            // con errore non significativo (Network Error)
            // mentre l'errore vero ERR_UPLOAD_FILE_CHANGED
            // viene restituito dopo in modo non catchabile
            // Resettiamo file dopo ogni upload
            if(uploadTarget)
              uploadTarget.value = null
            setUploadInfo(null)
        })
    }

    const showMode = (mode) => {
        setMode(mode)
        setUploadInfo(null)
        setResult("")
        setShowdatagrid(false)
    }

    const back = () => {
        showMode(null)
    }

    const redirect = authorized("CenterAdmin",userInfo)
    if(redirect)
        return redirect

    let title
    let onSubmit = onSubmitUpload
    let buttonLabel = t("Carica")
    if(mode === "CreatePatients") {
        title = t("Caricamento nuovi pazienti")
    } else if(mode === "putPersonalData") {
        title = t("Caricamento dati personali dei pazienti")
    } else if(mode === "putMedicalData") {
        title = t("Caricamento dati medici dei pazienti")
    } else if(mode === "getConsents") {
        title = t("Recupero consensi pazienti")
        onSubmit = onSubmitDownload
        buttonLabel = t("Recupera")
    }

    //console.log(rows)

    return (
        <div className="app-container">
        <Header title={t("Centro")+" "+center+": "+t("Sincronizzazione pazienti e dati")} />
        <div className="content-container">
        <Container component="main" maxWidth="md">
            {!mode ?
                <div>
                <MostButton id="createPatients" className="b_loginok" onClick={() => {
                        showMode("CreatePatients")
                    }} label={t("Carica nuovi pazienti")} />
                <MostButton id="getConsents" className="b_loginok" onClick={() => {
                        showMode("getConsents")
                    }} label={t("Recupera consensi pazienti")} />
                <MostButton id="putPersonalData" className="b_loginok" onClick={() => {
                        showMode("putPersonalData")
                    }} label={t("Carica dati personali dei pazienti")} />
                <MostButton id="putMedicalData" className="b_loginok" onClick={() => {
                        showMode("putMedicalData")
                    }} label={t("Carica dati medici dei pazienti")} />
                </div>
            :
                <div> 
                    <h2> {title} </h2>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container spacing={1} alignItems="center" className="blackColor">
                      { mode !== "getConsents" ?
                        <Grid item xs={12}>
                            File da caricare: 
                            <div className="fileupload">
                                <FileUpload
                                    setUploadInfo={setUploadInfo}
                                    setUploadTarget={setUploadTarget}
                                />
                            </div>
                        </Grid>
                      : null }
                        <Grid item xs={12}>
                            <MostAutocomplete control={control} name="format" options={mode === "getConsents" ? options_formatofile_download : options_formatofile} label={t("Formato file")+" *"} required={true} rules={{ required: true }}  defaultValue={null} errors={errors} />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={8}>
                            <MostSubmitButton id="submit-button" disabled={disabledButs} className="b_loginok" label={buttonLabel} />
                        </Grid>
                        <Grid item xs={4}>
                            <MostButton2 fullWidth disabled={disabledButs} className="b_loginok" onClick={back} label={t("Indietro")} />
                        </Grid>
                        <Grid item xs={8} className="tcenter">
                        { loading ? (
                            <b>{t("Operazione in corso")}</b>
                        ) : null }
                        <PropagateLoader color={window.__most.palette.primary.darkColor} css={spinnerCss} loading={loading} />
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </Grid>
                    </form>
                </div>
            }
        </Container>
        <div className="blackColor margin20all">
            <div dangerouslySetInnerHTML={{__html: result}} />
        </div>
        { showdatagrid ?
            <DataTable columns={columns} rows={rows} height={600} density={"compact"} />
        : null }
        </div>
        <Footer />
        </div>
    )
}
