import React, { useState, useCallback, useEffect, } from "react";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import { Loading, MostDatePicker, getOptionList, getOptionListElement, MostButton2, MostAutocomplete, MostSubmitButton, MostButton, MostTextField, } from "../components/MostComponents";
import MyAxios, {check_response} from "../MyAxios";
import { ymd, scrollToElement, } from '../Utils'
import { getLang } from '../i18n'
import { svcTableLangColumn, authorized, getPdf } from '../UtilsInthem'

export const PDati = (props) => {
  const history = useHistory();
  const { userInfo } = useGlobalHook('userStore');
  const [disabledButs, setDisabledButs] = useState(false)
  const { control, register, handleSubmit, errors, setValue } = useForm();
  const { t, i18n } = useTranslation();
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const [formReady, setFormReady] = useState(false)
  const [ro, setRo] = useState(true)
  const [mobile, setMobile] = useState("")
  const [mobile_prefix, setMobile_prefix] = useState("")
  const [options_nation, setOptions_nation] = useState([])
  const [regions, setRegions] = useState([])
  const [default_address_area, setDefault_address_area] = useState(null)
  const [default_lob_region, setDefault_lob_region] = useState(null)
  const [options_region_residenza, setOptions_region_residenza] = useState([])
  const [options_region_nascita, setOptions_region_nascita] = useState([])
  const { setConfirm1, setCContent, setOnConfirm } = useGlobalHook('confirmStore');
  function appConfirm(text,okHandler) {
      setCContent(text);
      setOnConfirm(() => x => {
        okHandler();
      });
      setConfirm1(true);
  }
  const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
  }, [setContent,setAlert1])
  const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
  function appAlert2(text,closeHandler) {
      setContent2(text)
      setOnClose2(() => x => {
        closeHandler()
      });
      setAlert2(true)
  }

  let defaultDob = null
  if (userInfo.dob)
    defaultDob = new Date(userInfo.dob)
  const [selectedDob, setSelectedDob] = React.useState(defaultDob)
  const handleDobChange = (date) => {
        setSelectedDob(date)
  }

  const buildRegionOptions = useCallback((nation) => {
    const ret = []
    for(let i in regions) {
        if(regions[i].nation === nation)
            ret.push({value:regions[i].id, label:regions[i].name})
    }
    return ret
  }, [regions])

  const [addressNation, setAddressNation] = React.useState(null)
  const handleAddressNationChange = (e,el) => {
    //console.log("handleAddressNationChange")
    setAddressNation(el)
    let options = []
    if (el)
        options = buildRegionOptions(el.value)
    setOptions_region_residenza(options)
    setValue('address_area',null)
  }

  const [lobNation, setLobNation] = React.useState(null)
  const handleLobNationChange = (e,el) => {
    //console.log("handleLobNationChange")
    setLobNation(el)
    let options = []
    if (el)
        options = buildRegionOptions(el.value)
    setOptions_region_nascita(options)
    setValue('lob_region',null)
  }

  const options_login_mode = [
    {value: "Email", label: t("Indirizzo e-mail")},
    {value: "Mobile", label: t("Numero di cellulare")},
  ]
  const defValue_login_mode = getOptionListElement(options_login_mode,userInfo.login_mode)

  // carichiamo le regioni e poi le nazioni
  useEffect(() => {
    //console.log("loading regions")
    const formData = new FormData()
    formData.append('table_name', 'svc_region')
    formData.append('format', 'JSON')
    formData.append('inline', true)
    const url = "/admin/GetConfTable"
    console.log(url, [...formData.entries()])
    MyAxios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data;" }
    }).then((response) => {
      const data = check_response(response);
      if(!data.success) {
        console.error(data.error)
        appAlert(data.error)
        return
      }
      setRegions(data.rows)
    })
    .catch(function (error) {
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    })
  }, [appAlert])

  useEffect(() => {
    if(regions.length === 0)
        return
    //console.log("loading nations")
    const formData = new FormData()
    formData.append('table_name', 'svc_nation')
    formData.append('format', 'JSON')
    formData.append('inline', true)
    const url = "/admin/GetConfTable"
    console.log(url, [...formData.entries()])
    MyAxios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data;" }
    }).then((response) => {
      const data = check_response(response);
      if(!data.success) {
        console.error(data.error)
        appAlert(data.error)
        return
      }
      const column = svcTableLangColumn(getLang(i18n.language))
      const on = getOptionList(data.rows,'id',column)
      setOptions_nation(on)

      // abbiamo regioni e nazioni, calcoliamo default delle select
      if (userInfo.address_nation) {
        setAddressNation( getOptionListElement(on,userInfo.address_nation) )
        const options = buildRegionOptions(userInfo.address_nation)
        setOptions_region_residenza(options)
        if (userInfo.address_area) {
            setDefault_address_area( getOptionListElement(options,userInfo.address_area) )
        }
      }
      if (userInfo.lob_nation) {
        setLobNation( getOptionListElement(on,userInfo.lob_nation) )
        const options = buildRegionOptions(userInfo.lob_nation)
        setOptions_region_nascita(options)
        if (userInfo.lob_region) {
            setDefault_lob_region ( getOptionListElement(options,userInfo.lob_region) )
        }
      }
      if (userInfo.mobile_prefix)
        setMobile_prefix(userInfo.mobile_prefix)
      if (userInfo.mobile)
        setMobile(userInfo.mobile)
      setFormReady(true)
    })
    .catch(function (error) {
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    })
  }, [appAlert,regions,userInfo.lob_nation,userInfo.address_nation,i18n.language,buildRegionOptions,userInfo.lob_region,userInfo.address_area,userInfo.mobile_prefix,userInfo.mobile])

  const mobile_prefixOnChange = (e) => {
    setMobile_prefix(e.target.value)
  }

  const mobileOnChange = (e) => {
    setMobile(e.target.value)
  }

  const back = () => {
    setRo(true)
  }

  const onSubmit = (vals) => {
    const mobile_prefixVal = mobile_prefix.trim()
    const mobileVal = mobile.trim()
    const newm = mobile_prefixVal+mobileVal
    const oldm = (userInfo.mobile_prefix ? userInfo.mobile_prefix : "")+(userInfo.mobile ? userInfo.mobile : "")
    // controlliamo numero solo se cambiato?
    const check_always = true
    if (newm !== "" && (check_always || newm !== oldm)) {
        // controlliamo il formato del numero di cellurare via BE
        setDisabledButs(true)
        const formData = new FormData()
        formData.append('number', newm)
        const url = "/verify_phone_number"
        console.log(url, [...formData.entries()])
        MyAxios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
            setDisabledButs(false)
            const res = check_response(response)
            if (res.success) {
                const new_mobile_prefix = res.country_code
                const new_mobile = res.national_number
                if (new_mobile_prefix !== mobile_prefixVal || new_mobile !== mobileVal) {
                    setMobile(new_mobile)
                    setMobile_prefix(new_mobile_prefix)
                    appAlert(t("Verifica che il numero di cellulare sia corretto e premi nuovamente 'Modifica'"))
                    return
                }
                onSubmit1(vals)
            } else {
                console.error("Error: "+res.error);
                appAlert(t("Numero di cellulare non corretto")+":<br>"+res.error);
            }
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
        })
    } else
        onSubmit1(vals)
  }

  const onSubmit1 = (vals) => {
    const login_mode = vals.login_mode.value
    console.log("login_mode", login_mode)
    const email = vals.email.trim().toLowerCase()
    if(login_mode === "Email" && email === "") {
        appAlert(t("Campo email obbligatorio"))
        return
    }
    const mobileVal = mobile.trim()
    if(login_mode === "Mobile" && mobileVal === "") {
        appAlert(t("Campo cellulare obbligatorio"))
        return
    }
    let authChanged = false
    let msg = null
    if(login_mode !== userInfo.login_mode) {
        authChanged = true
        msg = t("Stai cambiando la modalità di accesso all'applicazione")
    } else if(login_mode === "Email" && email !== userInfo.email) {
        authChanged = true
        msg = t("Stai cambiando l'indirizzo email con cui accedi all'applicazione")
    } else if(login_mode === "Mobile" && mobileVal !== userInfo.mobile) {
        authChanged = true
        msg = t("Stai cambiando il numero di cellulare con cui accedi all'applicazione")
    }
    if (authChanged) {
        msg += "<br>"+t("Dal prossimo accesso dovrai utilizzare")+" "
        if(login_mode === "Email")
            msg += t("l'indirizzo email")+" <b>"+email+"</b>"
        else
            msg += t("il numero di cellulare")+" <b>"+mobileVal+"</b>"

        msg += "<br>"+t("Confermi la modifica?")
        appConfirm(msg,() => {
            doSubmit(vals,email)
        })
    } else
        doSubmit(vals,email)
  }

  const doSubmit = (vals,email) => {
    console.log("doSubmit", vals)
    if(isNaN(selectedDob)) {
        appAlert(t("Data non valida"))
        return
    }
    const login_mode = vals.login_mode.value
    console.log("login_mode", login_mode)
    console.log("addressNation", addressNation)
    console.log("lobNation", lobNation)
    let dob = null
    if (selectedDob)
        dob = ymd(selectedDob)
    console.log("dob", dob)

    setDisabledButs(true)

    const jdata = {
        name: vals.name,
        surname: vals.surname,
        login_mode: login_mode,
        legal_id: vals.legal_id,
        telephone: vals.telephone,
        mobile_prefix: mobile_prefix.trim(),
        mobile: mobile.trim(),
        email: email,
        address_nation: addressNation ? addressNation.value : null,
        address_town: vals.address_town,
        address_street: vals.address_street,
        address_zip: vals.address_zip,
        address_area: vals.address_area ? vals.address_area.value : null,
        dob: dob,
        lob_nation: lobNation ? lobNation.value : null,
        lob_region: vals.lob_region ? vals.lob_region.value : null,
    }
    //console.log("jdata",jdata)
    MyAxios.post("/patient/UpdatePersonalData", jdata)
    .then((response) => {
        setDisabledButs(false)
        const res = check_response(response)
        if (res.success) {
            appAlert2(t("Modifiche registrate"),() => {
                setRo(true)
                scrollToElement("startingDiv")
            })
        } else {
            console.error("Error: "+res.error);
            appAlert(res.error);
        }
    })
    .catch(function (error) {
        // handle error
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
    })
  }

  const modifica = () => {
    scrollToElement("startingDiv")
    setRo(false)
  }

  const stampa = () => {
    getPdf("scheda_paziente",i18n)
  }

  const redirect = authorized("patient",userInfo)
  if(redirect)
    return redirect

  // dobbiamo aspettare di avere tutti i dati (default values)
  if(!formReady)
    return <Loading />

  return (
  <div className="app-container">
  <Header backMode="/myconsent" title={t("I tuoi dati")} />
  <div className="content-container contentWithToolbar">
      <form onSubmit={handleSubmit(onSubmit)} disabled noValidate>
      <div className="formToolbar">
            { ro ?
              <React.Fragment>
                    <MostButton fullWidth={false} onClick={modifica} label={t("Modifica")} id="Modifica" /> 
                    <MostButton fullWidth={false} onClick={stampa} label={t("Stampa")} id="Stampa" />
                    <MostButton2 fullWidth={false} disabled={disabledButs} onClick={() => history.push("/myconsent")} label={t("Indietro")} className="margin2416" id="Back" />
              </React.Fragment>
            :
              <React.Fragment>
                    <MostSubmitButton fullWidth={false} disabled={disabledButs} label={t("Modifica")} id="Modify" />
                    <MostButton2 fullWidth={false} disabled={disabledButs} onClick={back} label={t("Indietro")} className="margin2416" id="Back" />

              </React.Fragment>
            }
      </div>
      <Container component="main" maxWidth="md">
        <div id="startingDiv"/>
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={6}>
              <MostTextField name="surname" disabled required={true} label={t("Cognome")} register={register({ required: true })} defaultValue={userInfo.surname} errors={errors} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MostTextField name="name" disabled required={true} label={t("Nome")} register={register({ required: true })} defaultValue={userInfo.name} errors={errors} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MostAutocomplete control={control} disabled={ro} name="login_mode" options={options_login_mode} label={t("Campo di autenticazione")+" *"} required={true} rules={{ required: true }}  defaultValue={defValue_login_mode} errors={errors} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MostTextField name="email" disabled={ro} register={register} label={t("E-mail")} defaultValue={userInfo.email} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={3}>
                    <MostTextField name="mobile_prefix" disabled={ro} register={register} label={t("Prefisso")} value={mobile_prefix} onChange={mobile_prefixOnChange} />
                </Grid>
                <Grid item xs={9}>
                    <MostTextField name="mobile" disabled={ro} register={register} label={t("Cellulare")} value={mobile} onChange={mobileOnChange} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MostTextField name="telephone" disabled={ro} register={register} label={t("Telefono")} defaultValue={userInfo.telephone} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MostTextField name="legal_id" disabled={ro} register={register} label={t("Codice fiscale")} defaultValue={userInfo.legal_id} />
            </Grid>
            <Grid item xs={12} sm={12}>
                <FormLabel className="gold">Residenza/Domicilio</FormLabel>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MostTextField name="address_street" disabled={ro} register={register} label={t("Indirizzo")} defaultValue={userInfo.address_street} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MostTextField name="address_zip" disabled={ro} register={register} label={t("CAP")} defaultValue={userInfo.address_zip} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MostTextField name="address_town" disabled={ro} register={register} label={t("Città")} defaultValue={userInfo.address_town} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MostAutocomplete name="address_nation" disabled={ro} options={options_nation} label={t("Nazione")} value={addressNation} onChange={handleAddressNationChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MostAutocomplete disabled={ro?true:(addressNation?false:true)} control={control} name="address_area" options={options_region_residenza} label={t("Provincia/Stato")} defaultValue={default_address_area} />
            </Grid>
            <Grid item xs={12} sm={12}>
                <FormLabel className="gold">Nascita</FormLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MostDatePicker disabled label={t("Data di nascita")} value={selectedDob} onChange={handleDobChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MostAutocomplete name="lob_nation" disabled={ro} options={options_nation} label={t("Nazione di nascita")} value={lobNation} onChange={handleLobNationChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MostAutocomplete disabled={ro?true:(lobNation?false:true)} control={control} name="lob_region" options={options_region_nascita} label={t("Provincia/Stato di nascita")} defaultValue={default_lob_region} />
            </Grid>
        </Grid>
      </Container>
      </form>
  </div>
  <Footer />
  </div>
)
}

