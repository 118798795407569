import React, { useState, useEffect, useCallback, } from "react";
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { scrollToElement } from '../Utils'
import { getPdf } from '../UtilsInthem'
import { useForm, } from "react-hook-form";
import { MostRadioGroup, MostButton, MostSubmitButton, MostButton2, Loading, } from "../components/MostComponents";
import MyAxios, {check_response} from "../MyAxios";
import { useHistory } from "react-router-dom";

export const ConsensoForm = (props) => {
    const { control, handleSubmit, errors, } = useForm()
    const [disabledButs, setDisabledButs] = useState(false)
    const [ritiro, setRitiro] = useState(false)
    const [modello, setModello] = useState(null)
    const [ro, setRo] = useState(true)
    const [consensi, setConsensi] = useState(null)
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
        setContent(text);
        setAlert1(true);
    }, [setContent,setAlert1])
    const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
    function appAlert2(text,closeHandler) {
      setContent2(text)
      setOnClose2(() => x => {
        closeHandler()
      });
      setAlert2(true)
    }
    const { setConfirm1, setCContent, setOnConfirm } = useGlobalHook('confirmStore');
    function appConfirm(text,okHandler) {
      setCContent(text);
      setOnConfirm(() => x => {
        okHandler();
      });
      setConfirm1(true);
    }

    // scroll su primo errore della form
    useEffect(() => {
       let errorsArray = Object.keys(errors)
       if (errorsArray.length) {
         let el = document.getElementById(errorsArray[0])
         if (el) {
            el.scrollIntoView()
         }
       }
    }, [errors])

    useEffect(() => {
        const jdata = {
        }
        MyAxios.post("/patient/GetConsentPoints", jdata)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            console.error(data.error)
            appAlert(data.error)
            return
          }
          setModello(data.consent.points)
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,])

    useEffect(() => {
        // consensi non inizializzati, inutile chiederli
        if (props.userInfo.c_db_p === null) {
            // modalita' modifica
            setRo(false)
            return
        }
        const jdata = {
        }
        MyAxios.post("/patient/GetConsents", jdata)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            console.error(data.error)
            appAlert(data.error)
            return
          }
          setConsensi(data.consents)
        })
        .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.userInfo.c_db_p])

    const onSubmit = (vals) => {
        console.log("onSubmit",vals)
        if(ritiro) {
            appConfirm("Sei davvero sicuro di volere ritirare il tuo consenso?",() => {
                const jdata = {
                    C_DB_NO_NOTIFICATION: vals.ritiro
                }
                MyAxios.post("/patient/UpdateConsents", jdata)
                .then((response) => {
                    setDisabledButs(false)
                    const res = check_response(response)
                    if (res.success) {
                        appAlert2(t("xxx consenso ritirato xxx"),() => {
                            window.location.replace('/logout');
                        })
                    } else {
                        console.error("Error: "+res.error);
                        appAlert(res.error);
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                    appAlert(error.message?error.message:JSON.stringify(error));
                    setDisabledButs(false)
                })
            })
            return
        }
        const jdata = {}
        let yesCounter = 0
        for(let i in modello) {
            if(modello[i].tipo === "radio") {
                //console.log('radio')
                const iv = parseInt(vals['point_'+i])
                // caso strano un campo di cui non sappiamo nulla e in cui abbiamo messo una risposta sola
                if (modello[i].risposte.length <= 2) {
                    // boolean, 0 false, 1 true
                    if (iv) {
                        jdata[modello[i].nome_campo] = true
                        yesCounter++
                    } else
                        jdata[modello[i].nome_campo] = false
                } else {
                    // campo con piu' valori
                    jdata[modello[i].nome_campo] = iv
                }
            } else {
                // titolo: skip
            }
        }
        // xxx campo c_db ?? (da rivedere quando faremo cancellazione)
        // xxx se non e' inizializzato? (puo' succedere?)
        // xxx lo settiamo a true se abbiamo dato almeno un consenso
        if (yesCounter)
            jdata['c_db'] = true
        MyAxios.post("/patient/UpdateConsents", jdata)
        .then((response) => {
            setDisabledButs(false)
            const res = check_response(response)
            if (res.success) {
                appAlert2(t("Modifiche registrate"),() => {
                    setRo(true)
                    scrollToElement("startingDiv")
                })
            } else {
                console.error("Error: "+res.error);
                appAlert(res.error);
            }
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
        })
    }

    const modifica = () => {
        scrollToElement("startingDiv")
        setRo(false)
    }

    const stampa = () => {
        getPdf("consensi",i18n)
    }

    if(!modello || (props.userInfo.c_db_p !== null && !consensi))
        return <Loading />

    return (
        <div className="blackColor">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div id="startingDiv"/>
          <div className="formToolbarInTab">
            { ritiro ?
                 <React.Fragment>
                    <MostSubmitButton disabled={disabledButs} fullWidth={false} label={t("Conferma")} id="Conferma" />
                    <MostButton2 fullWidth={false}  disabled={disabledButs} onClick={() => setRitiro(false)} label={t("Indietro")} className="margin2416" id="Back" />
                 </React.Fragment>
            : ro ?
                 <React.Fragment>
                    <MostButton fullWidth={false} onClick={modifica} label={t("Modifica")} id="Modifica" />
                    <MostButton fullWidth={false} onClick={stampa} label={t("Stampa")} id="Stampa" />
                    <MostButton2 fullWidth={false}  disabled={disabledButs} onClick={() => history.push("/myconsent")} label={t("Indietro")} className="margin2416" id="Back" />
                 </React.Fragment>
            :
                 <React.Fragment>
                    <MostSubmitButton fullWidth={false} disabled={disabledButs} label={t("Conferma")} id="Conferma" />
                    <MostButton2 fullWidth={false}  disabled={disabledButs} onClick={() => setRo(true)} label={t("Indietro")} className="margin2416 marginr4" id="Back" />
                    <MostButton2 fullWidth={false}  disabled={disabledButs} onClick={() => setRitiro(true)} label={t("Ritiro")} className="margin2416" id="Ritiro" />
                 </React.Fragment>
            }
          </div>
          { ritiro ?
              <div> 
                <h2> Ritiro del consenso </h2>
                Consapevole di poter cambiare il mio consenso in qualsiasi momento anche per specifici aspetti, decido in piena consapevolezza di
                <br />
                <MostRadioGroup
                    control={control}
                    rules={{ required: true }}
                    name="ritiro"
                    options={[
                    {
                        label:"Non voler essere ricontattato in futuro. Potete comunque continuare ad usare i miei dati e campioni per ricerca già inseriti in DB-INTHEM",
                        value:"v1"
                    },
                    {
                        label:"Non voglio essere più ricontattato e voglio che i miei dati e campioni siano de-identificati, ma potete continuare ad usarli per ricerca",
                        value:"v2"
                    },
                    {
                        label:"Non voglio essere ricontattato, cancellate tutti i miei dati, distruggete i miei eventuali campioni",
                        value:"v3"
                    },
                    ]}
                    errors={errors}
                    defaultValue={null}
                  />
                <h2>Siete davvero sicuri?</h2>
                <h2>Avete domande?</h2> Chiamate questo numero xxxxxxxxx
              </div>
          :
              <ol className="modulo">
              {modello.map((item,itemIndex)=>{
                if (item.tipo === "titolo") {
                    return (
                        <h2 key={"h2"+itemIndex}> {item.testo} </h2>
                    )
                }
                let defaultValue = null
                // se abbiamo ricevuto valori precedenti
                if(props.userInfo.c_db_p !== null) {
                    // default (risposta salvata) se c'e'
                    if(consensi[item.nome_campo] !== null && consensi[item.nome_campo] !== undefined) {
                        // nota: comunque i valori dei radio sono stringhe
                        if(item.risposte.length === 2) {
                            // boolean, 0 false, 1 true
                            if (consensi[item.nome_campo])
                                defaultValue = "1"
                            else
                                defaultValue = "0"
                        } else {
                            defaultValue = ""+consensi[item.nome_campo]
                        }
                    }
                }
                //console.log("defaultValue",item.nome_campo,defaultValue)
                return (
                    <li id={"point_"+itemIndex} key={"li"+itemIndex}>
                        <div dangerouslySetInnerHTML={{__html: "<b>"+item.testo+"</b>"}} />
                        {item.tipo === "radio"?
                            <MostRadioGroup
                                control={control}
                                disabled={ro ? true : false}
                                rules={{ required: true }}
                                name={"point_"+itemIndex}
                                options={item.risposte}
                                errors={errors}
                                defaultValue={defaultValue}
                              />
                        : null }
                    </li>
                )
              })}
              </ol>
          }
        </form>
        </div>
    )
  }
